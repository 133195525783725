<template>
  <div class="vip-container">
    <div class="title">
      开通VIP查看
      <span>完整</span>
      数据
    </div>
    <span class="btn" @click="vip">立即开通</span>
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <span style="font-size: 30px; color: rgb(233, 47, 48)">
        可联系老师开通VIP！
      </span>
      <img src="./er-code.png" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
export default {
  name: "VipPage",
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    vip() {
      if (!this.$store.getters.isLogin) {
        this.$message.warning("请先登录！");
        eventBus.$emit("showLoginModal");
        return;
      }
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.vip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 351px;
  height: 229px;
  text-align: center;
  margin: 20px auto;
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    line-height: 28px;
    font-style: normal;
    text-transform: none;
    span {
      color: rgba(233, 48, 45, 1);
    }
  }
  .btn {
    display: inline-block;
    width: 147px;
    height: 48px;
    margin-top: 20px;
    line-height: 48px;
    background: linear-gradient(90deg, #febc64 0%, #f94035 100%);
    box-shadow: 0px 3px 1px 0px rgba(253, 168, 92, 0.3832);
    border-radius: 28px 28px 28px 28px;
    color: #fff;
    cursor: pointer;
  }
}
</style>
