<template>
  <div class="block_2 flex-col">
    <div class="group_27 flex-row">
      <div class="group_7 flex-col"></div>
      <span class="text_48">包含专业</span>
      <span class="text_49"
        >（{{ majorForm ? majorForm.majors.length : 0 }}）</span
      >
    </div>
    <template v-for="(item, index) in majorForm.majors">
      <span class="text_50" :key="index + '_1'" @click="goDetail(item)">{{
        item.majorName
      }}</span>
      <div class="group_28 flex-row justify-between" :key="index + '_2'">
        <div class="text-wrapper_6 flex-col">
          <span class="text_51">国标代码：{{ item.majorCode }}</span>
        </div>
        <div class="text-wrapper_7 flex-col">
          <span class="text_52">学制：{{ item.years }}年</span>
        </div>
        <div class="text-wrapper_8 flex-col">
          <span class="text_53">男女比例：{{ item.genderRatio }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import major from "@/apis/major";
import options from "@/utils/options";
export default {
  mixins: [options],
  props: {
    majorType: {
      type: String,
      default: "",
    },
    batch: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      majorForm: {
        majors: [],
      },
    };
  },
  computed: {
    level() {
      const levelMap = {
        本科: 1,
        专科: 2,
        职高: 3,
      };
      return levelMap[this.batch];
    },
  },
  watch: {
    majorType: {
      handler: function () {
        this.queryMajor();
      },
      immediate: true,
    },
  },
  methods: {
    goDetail(item) {
      // const routeData = this.$router.resolve({
      //   path: `/chazhuanyedetail/${item.id}`,
      // });
      // window.open(routeData.href, "_blank");
    },
    queryMajor() {
      if (!this.majorType) {
        return;
      }
      major.queryMajorByType(this.majorType, this.level).then((res) => {
        this.majorForm = res.message;
      });
    },
  },
  mounted() {
    this.queryMajor();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhuanye/assets/zhuanyetype.scss";
.menu {
  text-align: left;
}
::v-deep .el-submenu__icon-arrow {
  visibility: hidden;
}
</style>
