import { v4 as uuid } from 'uuid';
/*
 * <p><b>
 * description: 获取IP
 * </b></p>
 * date_time: 2022/11/3
 */
export function getIp()
{
  var urlPath=window.document.location.href;
  let urlSplit=urlPath.split(new RegExp(`[/]+`));
  let ipSplit=urlSplit[1].split(":");
  return ipSplit[0];
}

/*
 * <p><b>
 * description: 获取IP和端口
 * </b></p>
 * date_time: 2022/11/3
 */
export function getIpAndPort()
{
  var urlPath=window.document.location.href;
  let urlSplit=urlPath.split(new RegExp(`[/]+`));
  return urlSplit[1];
}

/*
 * <p><b>
 * description: 获取协议和IP和端口号
 * </b></p>
 * date_time: 2022/11/3
 */
export function getProtocolAndIpAndPort()
{
  var urlPath=window.document.location.href;
  let urlSplit=urlPath.split(new RegExp(`[/]+`));
  return urlSplit[0]+"//"+urlSplit[1];
}

/*
 * <p><b>
 * description: 获取协议和IP和端口号，根据是否进行自动匹配
 * </b></p>
 * date_time: 2022/11/3
 */
export function getProtocolAndIpAndPort4AutoReg()
{
  if(window.commonParam.autoReg)
  {
    var urlPath=window.document.location.href;
    let urlSplit=urlPath.split(new RegExp(`[/]+`));
    return urlSplit[0]+"//"+urlSplit[1];
  }
  else
  {
    return window.commonParam.addressAndPort;
  }
}
// export function  getLocalIP() {
//   console.log("获取本地IP");
//   // 创建一个RTCPeerConnection对象
//   const pc = new RTCPeerConnection({
//     iceServers: []
//   });

//   // 监听icecandidate事件
//   pc.onicecandidate = event => {
//     if (event.candidate) {
//       const ip = event.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3})/)[1];
//       if (ip) {
//         pc.close(); // 获取到IP后关闭连接
//         return ip;
//       }
//     }
//   };

//   console.log("获取本地IP");
//   // 创建一个offer
//   pc.createOffer().then(offer => {
//     return pc.setLocalDescription(offer);
//     pc.setLocalDescription(offer).then(() => {
//       // 触发icecandidate事件
//     }).catch(error => {
//       console.error('Error setting local description', error);
//     });
//   }).catch(error => {
//     console.error('Error creating offer', error);
//   });
// }

export function getDeviceUUID() {
  let deviceUUID=localStorage.getItem('deviceUUID')
  if(deviceUUID==null)
  {
    deviceUUID=uuid()
    localStorage.setItem('deviceUUID',deviceUUID)
  }
  return deviceUUID
}
