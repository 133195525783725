/*
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-10-10 15:37:29
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-12-26 23:24:04
 * @FilePath: \ok-college-entrance-examination-choices\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Loading } from "element-ui";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/element.css";
import "@/assets/scss/mainFrame.scss";
import "./permission";
import * as echarts from "echarts";
//rem等比适配配置文件
import "./utils/rem.js";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import "@/assets/svg/loader";
import VipPage from "@/components/vipPage";
import HeaderNav from "@/components/HeaderNav";
import ZwTable from "@/components/ZwTable";

Vue.component("SvgIcon", SvgIcon);
Vue.component("VipPage", VipPage);
Vue.component("HeaderNav", HeaderNav);
Vue.component("ZwTable", ZwTable);
Vue.use(ElementUI);
Vue.prototype._ = _;
Vue.prototype.$moment = moment;
Vue.prototype.$Loading = Loading;
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.$store = store;
Vue.config.productionTip = false;

BigInt.prototype.toJSON = function () {
  return this;
};

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value).format("YYYY-MM-DD HH:mm:ss");
  }
});

store
  .dispatch("initData")
  .then(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((e) => {
    console.error("Initialization failed:", e);
  });
