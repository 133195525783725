<template>
  <div class="page flex-col _major">
    <div class="box_1 flex-col">
      <div class="content1">
        <div class="box_8 flex-col">
          <div class="group_1 flex-row">
            <div
              @click="batch = '本科'"
              class="text-wrapper_2"
              :class="batch == '本科' ? 'active' : ''"
            >
              <span>本科专业</span>
            </div>
            <div
              @click="batch = '专科'"
              class="text-wrapper_2"
              :class="batch == '专科' ? 'active' : ''"
            >
              <span>专科专业</span>
            </div>
            <div
              @click="batch = '职高'"
              class="text-wrapper_2"
              :class="batch == '职高' ? 'active' : ''"
            >
              <span>高职本科</span>
            </div>
          </div>
          <div class="group_2 flex-col"></div>
          <section :class="{ Fixed: isFixed }">
            <div class="group_3 flex-row">
              <span class="text_21">专业门类：</span>
              <el-checkbox-group
                class="selectList"
                v-model="filterForm.majorCategory"
                size="mini"
              >
                <el-checkbox-button
                  key="全部"
                  label="全部"
                  @change="filterFormChange('majorCategory', '全部')"
                  >全部</el-checkbox-button
                >
                <el-checkbox-button
                  @change="filterFormChange('majorCategory', item)"
                  v-for="item in majorListClone.map(
                    (major) => major.majorCategory
                  )"
                  :key="item"
                  :label="item"
                  ><a :title="item">{{ item }}</a></el-checkbox-button
                >
              </el-checkbox-group>
            </div>
            <div class="group_4 flex-row">
              <el-input
                class="search_input"
                prefix-icon="el-icon-search"
                style="width: 30rem"
                type="text"
                placeholder="输入专业名称"
                v-model="filterForm.keywords"
                @keydown.enter.native="searchMajor()"
              >
                <div
                  slot="suffix"
                  class="highlight-rate"
                  v-if="HighlightList.length > 0"
                >
                  <span style="user-select: none"
                    >{{ curHightlight }} / {{ HighlightList.length }}</span
                  >
                  <span style="user-select: none"> | </span>
                  <i class="el-icon-arrow-up" @click="prev"></i>
                  <i class="el-icon-arrow-down" @click="next"></i>
                </div>
              </el-input>
              <div class="text-wrapper_18 flex-col" @click="searchMajor">
                搜索
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- <section
          :style="{
            paddingTop: isFixed ? (batch === '本科' ? '130px' : '200px') : '',
          }"
        ></section> -->
      <div class="text-wrapper_19">
        <span class="text_36">共</span>
        <span class="text_37">
          {{
            majorList
              ? majorList.reduce(
                  (total, Inline1) =>
                    Inline1.majorTypes.reduce(
                      (total1, MajorTypeVO) =>
                        MajorTypeVO.majors.length + total1,
                      0
                    ) + total,
                  0
                )
              : 0
          }}
        </span>
        <span class="text_38">条</span>
      </div>
      <template v-for="(item, index) in majorList">
        <div class="box_9 flex-row" :key="index + '_1'">
          <div class="group_5 flex-col"></div>
          <span class="text_39">
            <span v-html="item.majorCategory"></span>
            ({{ item.majorCategoryCode }})</span
          >
          <span class="text_40">
            {{ item.majorTypes.length }}个专业类，{{
              item.majorTypes.reduce(
                (total1, MajorTypeVO) => MajorTypeVO.majors.length + total1,
                0
              )
            }}个
          </span>
        </div>
        <div
          class="box_10 flex-col"
          v-for="(majorTypeVO, index1) in item.majorTypes"
          :key="index + ' ' + index1"
        >
          <div
            class="text-wrapper_20 flex-col"
            @click="getDetail(majorTypeVO.typeCode)"
          >
            <span class="text_41">
              <span v-html="majorTypeVO.type"></span>
              ({{ majorTypeVO.typeCode }})</span
            >
          </div>
          <div class="box_11 flex-row" style="gap: 32px; flex-wrap: wrap">
            <div
              class="text-wrapper_21 flex-col"
              v-for="(major, index2) in majorTypeVO.majors"
              :key="index + ' ' + index1 + ' ' + index2"
              @click="getDetail(major.majorCode)"
            >
              <span class="text_42" v-html="major.majorName"></span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import major from "@/apis/major";
import options from "@/utils/options";
import mixin from "@/views/student/chazhuanye/mixin";
export default {
  mixins: [options, mixin],
  components: {},
  data() {
    return {
      name: "ProfessionalLibrary",
      batch: "本科",
      originList: [],
      majorList: [],
      majorListClone: [],
      filterForm: {
        majorCategory: ["全部"],
        keywords: "",
      },
      isFixed: false,
      majorCode: null,
    };
  },
  mounted() {
    window.addEventListener("wheel", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll);
  },
  watch: {
    batch() {
      this.filterForm = { majorCategory: ["全部"], keywords: "" };
      this.HighlightList = [];
      this.curHightlight = 1;
      this.queryMajor().then(() => {
        this.majorListClone = this.majorList;
      });
    },
  },
  methods: {
    getDetail(code) {
      this.majorCode = code;
      this.$emit("getDetail", code, this.batch);
    },
    handleScroll(e) {
      this.isFixed = e.deltaY > 0;
    },
    async queryMajor() {
      let data = {
        category:
          this.filterForm.majorCategory[0] === "全部"
            ? null
            : this.filterForm.majorCategory[0],
        level: this.options.majorLevels[this.batch],
      };
      return major.queryMajor(data).then((res) => {
        this.majorList = res.message;
      });
    },
    filterFormChange(name, value) {
      this.filterForm[name] = [value];
      this.$nextTick(() => {
        this.queryMajor();
      });
    },
    transHighlight(str) {
      const regex = new RegExp(this.filterForm.keywords, "gi");
      if (regex.test(str)) {
        this.HighlightList.push({
          // index: this.index,
          str,
        });
        // this.index++;
      }
      return str.replace(
        regex,
        (match) => `<span class="highlight">${match}</span>`
      );
    },
    searchMajor() {
      this.isFixed = true;
      let data = {
        category:
          this.filterForm.majorCategory[0] === "全部"
            ? null
            : this.filterForm.majorCategory[0],
        level: this.options.majorLevels[this.batch],
        query: this.filterForm.keywords,
      };
      const ajaxFn = data.query ? major.searchMajor : major.queryMajor;
      ajaxFn(data).then((res) => {
        this.HighlightList = [];
        this.curHightlight = 1;
        this.originList = res.message;
        if (data.query) {
          res.message.forEach((item) => {
            item.majorCategory = this.transHighlight(item.majorCategory);
            item.majorTypes.forEach((type) => {
              type.type = this.transHighlight(type.type);
              type.majors.forEach((major) => {
                major.majorName = this.transHighlight(major.majorName);
              });
            });
          });
          this.majorList = res.message;
          this.$nextTick(() => {
            this.scrollToHighlight();
          });
        } else {
          this.majorList = res.message;
        }
      });
    },
  },
  created() {
    let data = {
      category:
        this.filterForm.majorCategory[0] === "全部"
          ? null
          : this.filterForm.majorCategory[0],
      level: this.options.majorLevels[this.batch],
    };
    major.queryMajor(data).then((res) => {
      this.majorList = res.message;
      this.originList = res.message;
      this.majorListClone = res.message;
    });
  },
};
</script>
<style>
._major .highlight {
  background-color: rgb(233, 48, 45);
  color: #fff;
}
.highlight-position {
  background-color: #ffe928 !important;
  color: #fff !important;
}
.highlight-rate {
  display: inline-block;
  height: 100%;
  margin-top: 10px;
  font-size: 18px;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhuanye/assets/index.scss";
::v-deep .el-checkbox-button {
  .el-checkbox-button__inner {
    padding: 0 14px !important;
    height: 30px;
    line-height: 30px;
    margin-left: 8px;
  }
  &.is-checked {
    .el-checkbox-button__inner {
      background-color: rgba(255, 244, 243, 1) !important;
    }
  }
}
// .Fixed {
//   position: fixed;
//   top: 156px;
//   width: 1200px;
//   background-color: #fff;
// }
</style>
