<template>
  <div class="page flex-col" style="padding-top: 20px">
    <div class="box_1 flex-col">
      <div class="block_5 flex-col">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="https://lanhu-oss.lanhuapp.com/SketchPng4d3c4a4d6cab9edb97ef02e88f70875c7dc857c60cd5428c235c02e042f98e13"
        />
        <div class="group_33 flex-row" style="margin-top: 39px">
          <span class="text_20">院校省份</span>
          <el-checkbox-group
            class="selectList"
            v-model="filterForm.province"
            size="mini"
            @change="filterFormChange"
          >
            <el-checkbox-button key="全部" label="全部"
              >全部</el-checkbox-button
            >
            <el-checkbox-button
              v-for="item in options.provinces"
              :key="item"
              :label="item"
              >{{ item }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="https://lanhu-oss.lanhuapp.com/SketchPngd7ad814251778f878d97de07a73cd09d315b656a538d4550ef7ab2836cdec1ea"
        />
        <div class="group_33 flex-row">
          <span class="text_20">院校类型</span>
          <el-checkbox-group
            class="selectList"
            v-model="filterForm.type"
            @change="filterFormChange"
            size="mini"
          >
            <el-checkbox-button key="全部" label="全部"
              >全部</el-checkbox-button
            >
            <el-checkbox-button
              v-for="it in options.schoolTypes"
              :key="it"
              :label="it"
              >{{ it }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="https://lanhu-oss.lanhuapp.com/SketchPngd7ad814251778f878d97de07a73cd09d315b656a538d4550ef7ab2836cdec1ea"
        />
        <div class="group_33 flex-row">
          <span class="text_20">院校特色</span>
          <el-checkbox-group
            class="selectList"
            v-model="filterForm.feature"
            @change="filterFormChange"
            size="mini"
          >
            <el-checkbox-button key="全部" label="全部"
              >全部</el-checkbox-button
            >
            <el-checkbox-button
              v-for="it in options.schoolFeatures"
              :key="it"
              :label="it"
              >{{ it }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="https://lanhu-oss.lanhuapp.com/SketchPngd7ad814251778f878d97de07a73cd09d315b656a538d4550ef7ab2836cdec1ea"
        />
        <div class="group_33 flex-row">
          <span class="text_20">院校性质</span>
          <el-checkbox-group
            class="selectList"
            v-model="filterForm.institution"
            @change="filterFormChange"
            size="mini"
          >
            <el-checkbox-button key="全部" label="全部"
              >全部</el-checkbox-button
            >
            <el-checkbox-button
              v-for="it in options.schoolInstitutions"
              :key="it"
              :label="it"
              >{{ it }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
        <div class="section_8 flex-row">
          <el-input
            prefix-icon="el-icon-search"
            style="border: none; width: 321px"
            type="text"
            placeholder="输入院校名称"
            v-model="filterForm.school"
            clearable
          ></el-input>
          <div class="text-wrapper_70 flex-col" @click="search">搜索</div>
        </div>
      </div>
      <div class="text-wrapper_71">
        <span class="text_91">共{{ total }}条</span>
      </div>
      <div class="block_6 flex-col">
        <div v-for="(item, index) in schoolList" :key="index + ''">
          <div
            :key="index + ''"
            class="box_22 flex-row"
            style="gap: 8px"
            @click="goDetail(item.id)"
          >
            <div class="group_6 flex-col">
              <img :src="item.schoolCrestUrl" width="100%" height="100%" />
            </div>
            <div class="group_39 flex-col justify-between">
              <span class="text_94">{{ item.schoolName }}</span>
              <div class="group_40 flex-row" style="gap: 8px">
                <div
                  class="text-wrapper_72 flex-col"
                  v-for="(feature, index) in item.schoolFeatures"
                  :key="index + ''"
                >
                  {{ feature }}
                </div>
              </div>
              <span class="text_99"
                >{{ item.provinceName }}/{{ item.cityName }}/{{
                  item.schoolType.join("/")
                }}/{{ item.schoolInstitution }}/{{ item.belongs }}</span
              >
            </div>
          </div>
          <span
            style="
              background-color: #f0f0f0;
              height: 1px;
              width: 1152px;
              display: block;
              margin-left: 24px;
            "
          ></span>
        </div>
      </div>
      <div class="group_46 flex-row">
        <el-pagination
          @size-change="searchSchool"
          @current-change="searchSchool"
          :current-page.sync="currentPage"
          :page-size.sync="pageSize"
          layout="prev, pager, jumper,next"
          :total="total"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import options from "@/utils/options";
import school from "@/apis/school";
// import province from "@/apis/province";
export default {
  mixins: [options],
  data() {
    return {
      constants: {},
      filterForm: {
        province: ["全部"],
        type: ["全部"],
        feature: ["全部"],
        institution: ["全部"],
        school: "",
      },
      drawer: false,
      schoolList: [],
      total: 2,
      currentPage: 1,
      pageSize: 6,
    };
  },
  methods: {
    goDetail(id) {
      this.$emit("getDetail", id);
    },
    search() {
      this.currentPage = 1;
      this.searchSchool();
    },
    searchSchool() {
      let data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        query: this.filterForm.school,
        provinces: this.filterForm.province.includes("全部")
          ? []
          : [...this.filterForm.province],
        types: this.filterForm.type.includes("全部")
          ? []
          : [...this.filterForm.type],
        schoolInstitutions: this.filterForm.institution.includes("全部")
          ? []
          : [...this.filterForm.institution],
        features: this.filterForm.feature.includes("全部")
          ? []
          : [...this.filterForm.feature],
      };
      if (this.filterForm.school && this.filterForm.school.length > 0) {
        school.searchScoolByKeywords(data).then((res) => {
          this.schoolList = res.message.records;
          this.total = res.message.total;
        });
      } else {
        school.queryScool(data).then((res) => {
          this.schoolList = res.message.records;
          this.total = res.message.total;
        });
      }
    },
    filterFormChange() {
      if (
        this.filterForm.province.lastIndexOf("全部") ==
        this.filterForm.province.length - 1
      ) {
        this.filterForm.province = ["全部"];
      }
      if (
        this.filterForm.type.lastIndexOf("全部") ==
        this.filterForm.type.length - 1
      ) {
        this.filterForm.type = ["全部"];
      }
      if (
        this.filterForm.feature.lastIndexOf("全部") ==
        this.filterForm.feature.length - 1
      ) {
        this.filterForm.feature = ["全部"];
      }
      if (
        this.filterForm.institution.lastIndexOf("全部") ==
        this.filterForm.institution.length - 1
      ) {
        this.filterForm.institution = ["全部"];
      }
      if (
        this.filterForm.province.includes("全部") &&
        this.filterForm.province.length > 1
      ) {
        this.filterForm.province.splice(
          this.filterForm.province.indexOf("全部"),
          1
        );
      }
      if (
        this.filterForm.type.includes("全部") &&
        this.filterForm.type.length > 1
      ) {
        this.filterForm.type.splice(this.filterForm.type.indexOf("全部"), 1);
      }
      if (
        this.filterForm.feature.includes("全部") &&
        this.filterForm.feature.length > 1
      ) {
        this.filterForm.feature.splice(
          this.filterForm.feature.indexOf("全部"),
          1
        );
      }
      if (
        this.filterForm.institution.includes("全部") &&
        this.filterForm.institution.length > 1
      ) {
        this.filterForm.institution.splice(
          this.filterForm.institution.indexOf("全部"),
          1
        );
      }
      this.$nextTick(() => {
        this.searchSchool();
      });
    },
  },
  watch: {
    options(newV, oldV) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.searchSchool();
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/chadaxue/assets/index.scss";
@import "@/assets/scss/common.scss";
::v-deep .el-checkbox-button {
  .el-checkbox-button__inner {
    padding: 0 14px !important;
    height: 30px;
    line-height: 30px;
    margin-left: 8px;
    font-size: 14px !important;
  }
  &.is-checked {
    .el-checkbox-button__inner {
      // background-color: rgba(255, 244, 243, 1) !important;
    }
  }
}
.page {
  width: 100%;
  background-color: rgba(242, 242, 242);
  padding-top: 15px;
  .box_1 {
    width: 1200px;
    margin: 0 auto;
    .group_46[data-v-fb102eb4] {
      margin: 20px;
    }
    .text-wrapper_71 {
      margin-bottom: 15px;
    }
  }
}
</style>
