<template>
  <div style="width: 100%">
    <section class="header-container">
      <div class="logo-group">
        <img src="@/assets/png/SSO/6.png" alt="" @click="handleLogo" />
        <!-- <span class="title"> 载望升学</span> -->
        <!-- <el-button size="mini" @click="handleToolbox" v-if="userDisplayName">工具箱</el-button> -->
        <el-popover placement="bottom" trigger="hover" v-model="visible">
          <ul class="menu">
            <li v-for="item in menuList" :key="item.title">
              <i :class="item.icon"></i> {{ item.text }}
              <div>
                <div
                  class="menu-item"
                  v-for="subItem in item.children"
                  :key="subItem.text"
                  @click="handleMenuClick(subItem)"
                >
                  {{ subItem.text }}
                </div>
              </div>
            </li>
          </ul>
          <el-button slot="reference" size="mini" v-if="userDisplayName"
            >工具箱</el-button
          >
        </el-popover>
      </div>
      <div class="notice" v-if="notice && $route.path !== '/about'">
        <svg-icon iconClass="laba" /> {{ notice }}
      </div>
      <ul class="menu-group">
        <!-- <el-tooltip content="帮助" placement="bottom" effect="light">
          <el-icon class="el-icon-question" style="font-size: 25px"></el-icon>
        </el-tooltip> -->
        <el-dropdown>
          <div>帮助 <el-icon class="el-icon-question"></el-icon></div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleAbout"
              >关于我们</el-dropdown-item
            >
            <el-dropdown-item>联系方式</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown v-if="userDisplayName">
          <div>
            <span class="avatar">{{ userDisplayName[0] }}</span>
            <span class="phone">{{ phoneNumber }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="handleLogout"
              icon="el-icon-switch-button"
              >退出账号</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </ul>
    </section>
    <el-dialog
      append-to-body
      center
      width="50%"
      :visible.sync="toolboxDialogVisible"
      title="工具箱"
      top="5vh"
    >
      <div>
        <el-container>
          <!-- 工具箱菜单 -->
          <el-header width="200px" style="background-color: #fff">
            <el-menu
              mode="horizontal"
              background-color="#fff"
              @select="handleSelect"
              default-active="RawBook"
            >
              <el-submenu index="2">
                <template slot="title"
                  ><i class="el-icon-tickets"></i>信息库</template
                >
                <el-menu-item index="CollegeLibrary">院校库</el-menu-item>
                <!-- <el-menu-item index="ProfessionalLibrary">专业库</el-menu-item> -->
                <!-- <el-menu-item index="CareerLibrary">职业库</el-menu-item> -->
              </el-submenu>
              <el-submenu index="4">
                <template slot="title"
                  ><i class="el-icon-medal"></i>榜单</template
                >
                <el-menu-item index="UniversityRankings">大学排名</el-menu-item>
                <!-- <el-menu-item index="4-2">学科排名</el-menu-item>
                <el-menu-item index="4-3">双一流学科</el-menu-item> -->
              </el-submenu>
              <el-submenu index="5">
                <template slot="title"
                  ><i class="el-icon-more"></i>更多</template
                >
                <!-- <el-menu-item index="ScoreLine">批次表</el-menu-item> -->
                <!-- <el-menu-item index="RankLine">一分一段</el-menu-item> -->
                <el-menu-item index="RawBook">原始书籍</el-menu-item>
              </el-submenu>
            </el-menu>
          </el-header>
        </el-container>
      </div>
    </el-dialog>
    <!-- <ToolsPage :visible.sync="visible" :tools="tools" /> -->

    <ToolBox
      ref="drawerMenu"
      :visible.sync="drawerVisible"
      :student="student"
      :curMenu="curMenu"
    />
  </div>
</template>

<script>
import logout from "@/apis/logout";
import options from "@/utils/options";
// import ToolsPage from "../components/toolsPage.vue";
import ToolBox from "@/components/ToolBox";
import WS from "@/utils/websocket";

export default {
  mixins: [options],
  components: {
    // ToolsPage,
    // BookDetail,
    ToolBox,
  },
  data() {
    return {
      userDisplayName: localStorage.getItem("userDisplayName"),
      phoneNumber: localStorage.getItem("phoneNumber"),
      toolboxDialogVisible: false,
      tools: {
        name: "",
        title: "",
      },
      menu: [
        {
          text: "招生计划",
        },
      ],
      visible: false,
      drawerVisible: false,
      student: {},
      curMenu: "",
      notice: "",
    };
  },

  computed: {
    menuList() {
      return [
        {
          text: "信息库",
          icon: "el-icon-tickets",
          children: [
            { text: "院校库", val: "school" },
            { text: "专业库", val: "major" },
            { text: "职业库", val: "profession" },
          ],
        },
        {
          text: "榜单",
          icon: "el-icon-medal",
          children: [{ text: "大学排名", val: "rank" }],
        },
        {
          text: "更多",
          icon: "el-icon-menu",
          children: [
            { text: "批次线", val: "ScoreLine" },
            { text: "一分一段", val: "RankLine" },
            { text: "书籍", val: "book" },
          ],
        },
      ];
    },
    toolsMap() {
      return {
        CollegeLibrary: "院校库",
        ProfessionalLibrary: "专业库",
        CareerLibrary: "职业库",
        UniversityRankings: "大学排名",
        ScoreLine: "批次线",
        RankLine: "一分一段",
      };
    },
  },
  methods: {
    handleMenuClick(item) {
      this.curMenu = item.val;
      this.drawerVisible = true;
      this.visible = false;
    },
    handleSelect(name) {
      if (name === "RawBook") {
        return;
      }
      this.visible = true;
      this.tools = {
        name,
        title: this.toolsMap[name],
      };
    },
    handleAbout() {
      this.$router.push("/about");
    },
    handleLogout() {
      this.$confirm("确认退出账号吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          logout.logout(localStorage.getItem("phoneNumber")).then(() => {
            this.$store.dispatch("logout");
          });
        })
        .catch(() => {
          return;
        });
    },
    handleLogo() {
      this.$router.push("/home");
    },
    handleToolbox() {
      this.toolboxDialogVisible = true;
    },
  },
  created() {
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const host =
      process.env.NODE_ENV === "development"
        ? "47.98.240.140"
        : window.location.host;
    const port = protocol === "wss:" ? "443" : "8080";
    const uuid = this.$store.state.deviceUUID;
    this.$ws = new WS(
      `${protocol}//${host}${
        window.location.host.includes("www.zaiwangzy.cn") ? "" : `:${port}`
      }/ws-pc-b/${uuid}`
    );
    // 初始化WebSocket
    this.$ws.connect(() =>
      this.$ws.send({
        type: "heartbeat",
      })
    );
    // 监听公告
    this.$ws.onAnnouncement = (data) => {
      this.notice = data.message ? `${data.date} ${data.message}` : "";
    };
  },
};
</script>
<style lang="scss" scoped>
.menu {
  list-style: none;
  display: flex;
  color: rgb(29, 33, 41);
  font-weight: 400;
  li {
    position: relative;
    padding: 0 30px;
    font-size: 18px;
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100px;
      background: #e0e0e0;
    }
    .menu-item {
      font-size: 14px;
      margin-top: 15px;
      cursor: pointer;
      &:hover {
        color: var(--main-thema-color);
      }
    }
  }
}
.empty {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  padding: 0 20px;
  .menu-group {
    display: flex;
    align-items: center;
    .avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      background-color: var(--main-thema-color);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    .phone {
      margin-left: 5px;
    }
  }
}
.logo-group {
  // position: fixed;
  // left: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.userInfoConer {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.el-dropdown-selfdefine {
  margin-right: 15px;
  cursor: pointer;
  // color: #409eff;
  // border-left: #000 1px solid !important;
  // margin-left: 10px;
  // padding-left: 10px;
  // border-bottom: 0;
  // border-top: 0;
  // border-radius: 0;
  // //background: $aside-slide;
  // color: #000;
  // width: 120px;

  &:hover {
    // font-weight: 800;
    color: var(--main-thema-color);
  }
}

.el-aside {
  background-color: #f2f2f2;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: white;
  color: #333;
  text-align: center;
}

.notice {
  font-size: 20px;
  color: #e9302d;
}
</style>
