<template>
  <el-drawer
    title="我是标题"
    :visible.sync="visible"
    direction="rtl"
    size="50%"
    :with-header="false"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div class="content">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-row>
          <el-col :span="8">
            <el-form-item label="院校名称">
              <el-input v-model="form.school" placeholder="院校名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="专业">
              <el-input v-model="form.major" placeholder="专业"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="科目选择">
              <el-select
                v-model="form.subject"
                placeholder="科目选择"
                clearable
              >
                <el-option
                  v-for="item in subjects"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="批次选择">
              <el-select v-model="form.batch" placeholder="批次选择" clearable>
                <el-option
                  v-for="item in batches"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <main>
        <section v-loading="loading" class="tree-content">
          <el-tree
            class="book-tree"
            :data="treeList"
            @node-click="handleNodeClick"
          >
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="bottom"
                :open-delay="500"
              >
                <span>{{ node.label }}</span>
              </el-tooltip>
              <div v-if="node.isLeaf" class="tags">
                <span>{{ data.subject }}</span>
                <span>{{ data.batch }}</span>
              </div>
            </div>
          </el-tree>
        </section>
        <section
          class="img-content"
          v-loading="imgLoading"
          element-loading-text="图片加载中..."
        >
          <el-empty v-if="!url" description="可点击左侧目录查看图片"></el-empty>
          <el-image
            v-else
            :src="url"
            fit="fill"
            :preview-src-list="[url]"
            ref="imgRef"
            @load="imgLoading = false"
            @error="errorHandler"
            style="width: 100%; height: 100%"
          >
          </el-image>
        </section>
      </main>
    </div>
  </el-drawer>
</template>

<script>
import BookApi from "@/apis/book";
export default {
  name: "BookDetail",
  props: {
    bookDetailVisible: {
      type: Boolean,
      default: false,
    },
    curBook: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    curBook() {
      this.onSubmit();
    },
  },
  data() {
    return {
      form: {
        school: "",
        subject: "",
        batch: "",
        major: "",
      },
      page: {
        total: 0,
        pageSize: 99999,
        pageNum: 1,
      },
      treeList: [],
      url: "",
      loading: false,
      imgLoading: false,
      batches: [], // 批次
      subjects: [], // 科目
      activeNames: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.bookDetailVisible;
      },
      set(val) {
        this.$emit("update:bookDetailVisible", val);
        this.url = "";
        this.imgLoading = false;
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:bookDetailVisible", false);
    },
    errorHandler() {
      this.imgLoading = false;
    },
    handleNodeClick(item, node) {
      if (!node.isLeaf) {
        return;
      }
      this.url = "";
      this.$nextTick(() => {
        this.imgLoading = true;
        this.url = item.pageUrl;
      });
    },
    onSubmit() {
      const params = {
        ...this.form,
        schoolBatchBookId: this.curBook.id,
      };
      this.loading = true;
      BookApi.searchPage(this.page.pageNum, this.page.pageSize, params).then(
        ({
          data: {
            voPages: { records },
            batches,
            subjects,
          },
        }) => {
          this.treeList = this.aggregateBySchoolFirstName(records);
          this.batches = batches;
          this.subjects = subjects;
          this.loading = false;
        }
      );
    },
    aggregateBySchoolFirstName(arr) {
      const result = [];
      arr.forEach((item) => {
        const allLabels = result.map((item) => item.label);
        if (allLabels.includes(item.schoolFirstName)) {
          const index = allLabels.indexOf(item.schoolFirstName);
          result[index].children.push({
            ...item,
            label: item.school,
          });
        } else {
          result.push({
            label: item.schoolFirstName,
            children: [
              {
                ...item,
                label: item.school,
              },
            ],
          });
        }
      });
      return result;
    },
  },
};
</script>
<style>
.book-tree .custom-tree-node {
  font-size: 14px;
  padding-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 5px;
}
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
}
</style>
<style lang="scss" scoped>
::v-deep .el-tree-node__content {
  height: 100%;
}
.content {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  main {
    display: flex;
    height: 100%;
    & > section {
      flex: 1;
      width: 50%;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
    .tree-content {
      height: 100%;
      .tags {
        text-align: left;
        span {
          background-color: #ecf5ff;
          display: inline-block;
          padding: 5px 10px;
          font-size: 12px;
          color: #409eff;
          border: 1px solid #d9ecff;
          border-radius: 4px;
          box-sizing: border-box;
          white-space: nowrap;
          margin-right: 10px;
        }
      }
    }
    .img-content {
      height: 500px;
    }
  }
}
</style>
