<template>
  <div class="search-wrap">
    <el-input
      :placeholder="placeholder"
      :value="value"
      @input="(e) => $emit('input', e)"
    >
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <span class="search-btn" @click="search">搜索</span>
  </div>
</template>
<script>
export default {
  name: "SearchInput",
  data() {
    return {
      //   searchVal: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "搜索意向院校",
    },
  },
  methods: {
    search() {
      this.$emit("search", this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-wrap {
  display: flex;
  width: 422px;
  height: 42px;
  background: #f9f9f9;
  ::v-deep input {
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    border-right: none;
    flex: 1;
  }
  .search-btn {
    width: 101px;
    height: 42px;
    background: #e9302d;
    border-radius: 0px 8px 8px 0px;
    text-align: center;
    line-height: 42px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    font-family: PingFang SC;
    cursor: pointer;
  }
}
</style>
