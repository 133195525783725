<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="section_51 flex-row">
        <div class="image-text_78 flex-row justify-between">
          <div class="section_13 flex-col"></div>
          <span class="text-group_16">招生计划</span>
        </div>
        <div class="block_8 flex-row">
          <div class="image-text_79 flex-row justify-between">
            <el-select
              v-model="filterFormZy.province"
              style="width: 7.5rem"
              @change="init"
            >
              <el-option
                v-for="item in options.provinces"
                :key="item"
                :value="item"
                >{{ item }}</el-option
              >
            </el-select>
          </div>
        </div>
        <div class="block_9 flex-row">
          <div class="image-text_80 flex-row justify-between">
            <el-select
              v-model="filterFormZy.year"
              style="width: 7.5rem"
              @change="queryScoreLineZy"
            >
              <el-option
                v-for="item in options.scoreYears"
                :key="item"
                :value="item"
                >{{ item }}</el-option
              >
            </el-select>
          </div>
        </div>
        <div class="block_10 flex-row justify-between">
          <div class="text_127 flex-row justify-between">
            <el-select
              v-model="filterFormZy.subject"
              style="width: 7.5rem"
              @change="queryScoreLineZy"
            >
              <el-option v-for="item in courseList" :key="item" :value="item">{{
                item
              }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="block_12 flex-row">
          <div class="image-text_82 flex-row justify-between">
            <el-select
              v-model="filterFormZy.batch"
              style="width: 7.5rem"
              @change="queryScoreLineZy"
            >
              <el-option v-for="item in batchList" :key="item" :value="item">{{
                item
              }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="block_12 flex-row">
          <div class="image-text_82 flex-row justify-between">
            <el-select v-model="majorGroup" style="width: 7.5rem">
              <el-option
                v-for="item in majorGroupList"
                :key="item"
                :value="item"
                >{{ item }}</el-option
              >
            </el-select>
          </div>
        </div>
        <!-- <div class="image-text_83 flex-row justify-between">
          <el-checkbox
            :value="filterFormZy.isOnlyMatch"
            :true-label="true"
            :false-label="false"
          ></el-checkbox>
          <span class="text-group_21">仅看符合</span>
        </div>
        <el-tooltip
          v-if="filterFormZy.subjectSelection === '综合'"
          effect="light"
          placement="bottom"
        >
          <div slot="content">
            <el-checkbox-group
              v-model="filterFormZy.subSubjects"
              :max="3"
              :min="1"
            >
              <el-checkbox-button label="物">物理</el-checkbox-button>
              <el-checkbox-button label="化">化学</el-checkbox-button>
              <el-checkbox-button label="生">生物</el-checkbox-button>
              <el-checkbox-button label="政">政治</el-checkbox-button>
              <el-checkbox-button label="史">历史</el-checkbox-button>
              <el-checkbox-button label="地">地理</el-checkbox-button>
              <el-checkbox-button label="技">技术</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="block_13 flex-row">
            <span class="text_128">{{
              filterFormZy.subSubjects.join("")
            }}</span>
            <img
              class="label_6"
              referrerpolicy="no-referrer"
              src="https://lanhu-oss.lanhuapp.com/SketchPng115f5fcb8350e6ac02902a70d19f9f1e6b9dda1956a9507c6344f5e293cfd586"
            />
            <span class="text_129">选科专业</span>
          </div>
        </el-tooltip> -->
      </div>
      <!-- <div class="section_52 flex-row">
        <span class="text_130">专业组</span>

        <div :class="majorGroup == '' ? 'text-wrapper_42' : 'text-wrapper_43'">
          <span
            :class="majorGroup == '' ? 'text_131' : 'text_132'"
            @click="majorGroup = ''"
            style="cursor: pointer"
            >全部</span
          >
        </div>
        <div
          :class="
            majorGroup == item.majorGroupCode
              ? 'text-wrapper_42'
              : 'text-wrapper_43'
          "
          v-for="(item, index) in scoreLineListZyClone"
          :key="index + ''"
        >
          <span
            :class="majorGroup == item.majorGroupCode ? 'text_131' : 'text_132'"
            @click="majorGroup = item.majorGroupCode"
            style="cursor: pointer"
            >{{ item.majorGroupCode }}</span
          >
        </div>
      </div> -->
      <zw-table :columns="columns" :data="list">
        <template #majorName="{ row }">
          <div class="major-name">
            {{ row.majorName }}
            <div class="note" v-if="row.note">
              {{ row.note }}
            </div>
          </div>
        </template>
      </zw-table>
      <div class="box_41 flex-col">
        <div class="image-text_84 flex-row justify-between">
          <img
            class="label_7"
            referrerpolicy="no-referrer"
            src="@/views/student/icons/school/prompt@2x.png"
          />
          <span class="text-group_22">请注意</span>
        </div>
        <span class="paragraph_4"
          >1、本平台历年录取数据，均参考各省市出版的填报指南以及各本专科院校的官网历年录取数据。<br />2、本平台数据与各省教育考试院发布的数据一致，由于各省市加分政策、院校官网对征集志愿数据的显示等原因，会与院校官网存在数据不一致的情况，请以各省教育考仅供参考。</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import school from "@/apis/school";
import province from "@/apis/province";
import moment from "moment";
import options from "@/utils/options";
export default {
  mixins: [options],
  props: {
    student: {
      type: Object,
      default: () => ({}),
    },
    schoolId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      planConfig: [],
      filterFormZy: {
        province: "",
        year: moment().subtract(1, "year").format("YYYY"),
        subjectSelection: "",
        subSubjects: [],
        subject: "",
        batch: "",
      },
      majorGroup: "", //专业组
      subjectRule: "",
      scoreLineListZy: [
        {
          majorGroupCode: 1, //专业组代码
          admissionDirection: "", //招生方向
          subjectRequirement: "", //选课要求
          details: [
            {
              subject: "", //选科
              batch: "本科", //批次
              majorName: "", //院校/专业名称
              majorEnrollCode: "", //专业登记代码
              schoolName: "", //学校名称
              note: "", //备注
              highestScore: 0, //最高分
              planNum: 0, //计划数
              admittedCount: 0, //录取数
              lowestScore: 0, //最低分
              lowestRank: 0, //最低位次
              averageScore: 0, //平均分
              majorGroup: "", //专业组
              schoolSystem: "", //学制
              fee: "", //学费
            },
          ],
        },
      ],
      scoreLineListZyClone: [],
      courseList: [],
      batchList: [],
      majorGroupList: [],
      list: [],
    };
  },
  computed: {
    columns() {
      return [
        { label: "代码", prop: "majorEnrollCode" },
        {
          label: "院校/专业名称",
          prop: "majorName",
          width: 250,
          slot: "majorName",
        },
        { label: "批次/段", prop: "batch" },
        { label: "选科要求", prop: "subjectRequirement" },
        { label: "计划", prop: "planNumber" },
        { label: "学制", prop: "schoolSystem" },
        { label: "学费", prop: "fee" },
        // { label: "录取数", prop: "admittedCount" },
        // { label: "最高分", prop: "highestScore" },
        // { label: "最低分", prop: "lowestScore" },
        // { label: "平均分", prop: "averageScore" },
        // { label: "最低位次", prop: "lowestRank" },
      ];
    },
  },
  methods: {
    async init() {
      const { data } = await province.queryProvincePlan(
        this.filterFormZy.province
      );
      this.planConfig = data;
      const obj = data.find((item) => item.year == this.filterFormZy.year);
      this.courseList = obj.course;
      this.filterFormZy.subject = this.courseList[0];
      this.batchList = obj.batch;
      this.filterFormZy.batch = this.batchList[0];
      this.queryScoreLineZy();
    },
    queryScoreLineZy() {
      let data = {
        page: 1,
        pageSize: 1000,
        schoolId: this.schoolId,
        province: this.filterFormZy.province,
        year: this.filterFormZy.year,
        subject: this.filterFormZy.subject,
        subSubject: this.filterFormZy.subSubjects,
        batch: this.filterFormZy.batch,
        majorGroup: null,
      };
      school.queryAdmissionPlan(data).then((res) => {
        this.scoreLineListZy = res.message.records;
        this.majorGroupList = res.message.records.map(
          (item) => item.majorMessage.schoolNameText
        );
        this.scoreLineListZyClone = JSON.parse(
          JSON.stringify(this.scoreLineListZy)
        );
        this.majorGroup = this.majorGroupList[0];
        this.subjectGroup = "";
      });
    },
  },
  watch: {
    // filterFormZy: {
    //   handler(newVal) {
    //     this.majorGroup = "";
    //     this.queryScoreLineZy();
    //   },
    //   deep: true,
    // },
    majorGroup: {
      handler() {
        this.list = this.scoreLineListZy.find(
          (item) => item.majorMessage.schoolNameText === this.majorGroup
        )?.subMajors;
      },
    },
  },
  mounted() {
    this.filterFormZy.province = this.student.province;
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/chadaxue/assets/jihua.scss";
@import "@/assets/scss/common.scss";
.major-name {
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  .note {
    color: #999;
    font-size: 12px;
    font-weight: normal;
  }
}
</style>
