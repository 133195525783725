export default {
  data() {
    return {
      HighlightList: [],
      index: 0,
      curHightlight: 1,
    };
  },
  methods: {
    next() {
      this.isFixed = true;
      this.curHightlight =
        this.curHightlight <= this.HighlightList.length - 1
          ? this.curHightlight + 1
          : 1;
      this.scrollToHighlight();
    },
    prev() {
      this.isFixed = true;
      this.curHightlight =
        this.curHightlight > 1
          ? this.curHightlight - 1
          : this.HighlightList.length;
      this.scrollToHighlight();
    },
    scrollToHighlight() {
      const list = document.querySelectorAll(".highlight");
      if (!list.length) {
        return;
      }
      const targetElement = list[this.curHightlight - 1];
      list.forEach((element) => {
        element.classList.remove("highlight-position");
      });
      targetElement.classList.add("highlight-position");
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
  },
};
