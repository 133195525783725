<template>
  <div class="page flex-col" ref="chazhiye">
    <div class="box_1 flex-col">
      <div class="group_2 flex-col">
        <div class="content1">
          <div class="box_7 flex-col">
            <div class="text-wrapper_2 flex-col">
              <span class="text_18">全部职业</span>
            </div>
            <div class="section_2 flex-col"></div>
            <div class="section_3 flex-row">
              <template v-for="(item, index) in professionListClone">
                <div
                  :class="
                    filterForm.category == item.categoryName
                      ? 'text-wrapper_3 flex-col'
                      : 'flex-col'
                  "
                  style="cursor: pointer"
                  :key="index"
                  @click="
                    () => {
                      filterForm.category =
                        item.categoryName === filterForm.category
                          ? ''
                          : item.categoryName;
                      queryProfession();
                    }
                  "
                >
                  <span
                    :class="
                      filterForm.category == item.categoryName
                        ? 'text_19'
                        : 'text_20'
                    "
                    >{{ item.categoryName }}</span
                  >
                </div>
              </template>
            </div>
            <div :class="{ section_4: true, 'flex-row': true, fixed: fixed }">
              <el-input
                class="search_input"
                prefix-icon="el-icon-search"
                style="width: 321px"
                @keydown.enter.native="queryProfession()"
                type="text"
                placeholder="输入职业名称"
                v-model="filterForm.keyword"
              >
                <div
                  slot="suffix"
                  class="highlight-rate"
                  v-if="HighlightList.length > 0"
                >
                  <span style="user-select: none"
                    >{{ curHightlight }} / {{ HighlightList.length }}</span
                  >
                  <span style="user-select: none"> | </span>
                  <i class="el-icon-arrow-up" @click="prev"></i>
                  <i class="el-icon-arrow-down" @click="next"></i>
                </div>
              </el-input>
              <div
                class="text-wrapper_5 flex-col"
                style="cursor: pointer"
                @click="queryProfession()"
              >
                搜索
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper_6">
          <span class="text_32">共</span>
          <span class="text_33">{{
            professionList.reduce(
              (total, category) =>
                total +
                category.professionTypes.reduce(
                  (to, type) => to + type.professionVos.length,
                  0
                ),
              0
            )
          }}</span>
          <span class="text_34">条</span>
        </div>
        <template v-for="(category, index) in professionList">
          <div class="box_8 flex-row justify-between" :key="index + '_1'">
            <div class="block_1 flex-col"></div>
            <span class="text_35"
              ><span
                v-for="(item, index) in splitText(category.categoryName)"
                :key="index"
                :class="item === filterForm.keyword ? 'highlight' : ''"
                >{{ item }}</span
              >（{{ category.categoryCode }}）</span
            >
          </div>
          <div
            class="box_9 flex-col"
            v-for="(type, index2) in category.professionTypes"
            :key="index + ' ' + index2"
          >
            <div class="text-wrapper_7 flex-col">
              <span
                class="text_36"
                style="cursor: pointer"
                @click="getDetail(type.typeCode)"
                ><span
                  v-for="(item, index) in splitText(type.typeName)"
                  :key="index"
                  :class="item === filterForm.keyword ? 'highlight' : ''"
                  >{{ item }}</span
                >（{{ type.typeCode }}）</span
              >
            </div>
            <div class="grid_1 flex-row">
              <div
                class="text-wrapper_9 flex-col"
                v-for="(profession, index3) in type.professionVos"
                :key="index + ' ' + index2 + ' ' + index3"
                style="cursor: pointer"
                @click="getDetail(profession.professionCode)"
              >
                <span class="text_38"
                  ><span
                    v-for="(item, index) in splitText(
                      profession.professionName
                    )"
                    :key="index"
                    :class="item === filterForm.keyword ? 'highlight' : ''"
                    >{{ item }}</span
                  ></span
                >
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import profession from "@/apis/profession.js";
import mixin from "@/views/student/chazhuanye/mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      professionList: [],
      professionListClone: [],
      filterForm: {
        category: "",
        keyword: null,
      },
      fixed: false,
      keyword: null,
      curHightlight: 0,
      index: 1,
      HighlightList: [],
    };
  },
  watch: {},
  methods: {
    getDetail(code) {
      console.log(code);
      this.$emit("professionDetail", code);
    },
    addHighlight(str) {
      if (str.includes(this.filterForm.keyword)) {
        this.HighlightList.push(str);
      }
    },
    queryProfession() {
      let data = {};
      if (this.filterForm.category) {
        data.category = this.filterForm.category;
      }
      if (this.filterForm.keyword && this.filterForm.keyword !== "") {
        data.keyword = this.filterForm.keyword;
      }
      this.curHightlight = 1;
      this.HighlightList = [];
      profession.queryProfessionsSearch(data).then((res) => {
        this.professionList = res.message.sort((a, b) =>
          a.categoryCode.localeCompare(b.categoryCode)
        );
        this.professionList.forEach((category) => {
          category.professionTypes.sort((a, b) =>
            a.typeCode.localeCompare(b.typeCode)
          );
        });
        // 让所有高亮的元素都添加到一个数组中
        res.message.forEach((item) => {
          this.addHighlight(item.categoryName);
          item.professionTypes.forEach((type) => {
            this.addHighlight(type.typeName);
            type.professionVos.forEach((major) => {
              this.addHighlight(major.professionName);
            });
          });
        });
        this.$nextTick(() => {
          this.scrollToHighlight();
        });
        if (this.professionListClone.length == 0) {
          this.professionListClone = res.message;
        }
      });
      this.keyword = this.filterForm.keyword;
    },
    handleScroll() {
      console.log("防抖");
      // 处理滚动事件的逻辑
      const scrollTop = this.$parent.$refs.layoutContainer.scrollTop;
      console.log(`滚动像素位置：${scrollTop}`);
      if (scrollTop > 156 && !this.fixed) {
        this.fixed = true;
      } else if (scrollTop <= 156 && this.fixed) {
        this.fixed = false;
      }
    },
    debounce(func, wait) {
      let timeout = null;
      return function (...args) {
        const context = this;
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    splitText(text) {
      if (this.keyword && this.keyword !== "") {
        let index = 0;
        const result = [];
        while ((index = text.indexOf(this.keyword, index)) !== -1) {
          // 添加分隔符之前的部分（如果存在）
          if (index > 0) {
            result.push(text.slice(0, index));
          }
          // 添加分隔符本身
          result.push(this.keyword);

          // 更新索引，跳过分隔符
          index += this.keyword.length;
          // 更新字符串，去掉已处理的部分
          text = text.slice(index);
          index = 0; // 重置索引
        }
        if (text.length > 0) {
          result.push(text);
        }

        return result; // 使用正则表达式分割字符串
      } else {
        return text;
      }
    },
  },
  mounted() {
    this.queryProfession();
    //监听滚轮，当下滑到1.875rem时给背景添加一个类名，实现固定到顶部
    // this.$nextTick(() => {
    //   this.$parent.$refs.layoutContainer.addEventListener(
    //     "scroll",
    //     this.debounce(this.handleScroll, 100)
    //   ); // 100毫秒的防抖时间
    // });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhiye/assets/index.scss";
// .fixed {
//   position: fixed;
//   top: 156px;
// }
.highlight {
  background-color: red;
  color: #fff;
}
</style>
