import request from "@/utils/request";
// import { update } from "lodash";
export default {
  // 删除规划师
  deletePlanner(tel) {
    return request.delete("/api/planner/" + tel);
  },
  getPlannerList(data) {
    return request.post("/api/planner/query/simple", data);
  },
  queryPlanner(data) {
    return request.post("/api/planner/query/form", data);
  },
  queryPlannerSimple(data) {
    return request.post("/api/planner/query/simple", data);
  },
  // updatePlanner(data) {
  //   return request.put("/api/planner", data);
  // },
  updatePreference(data) {
    return request.put("/api/preference", data);
  },
  insertPreference(data) {
    return request.post("/api/preference", data);
  },
  verifyTel(data) {
    return request.get("/api/planner/verify/tel/" + data);
  },
  queryPlannerStatistics() {
    return request.get("/api/planner/statistics");
  },
  createPlanner(data) {
    return request.post("/api/planner", data);
  },
  updatePlanner(data) {
    return request.put("/api/planner", data);
  },
  queryPlannerPoll() {
    return request.get("/api/planner/poll");
  },
  queryPlannerLike(data) {
    return request.post("/api/planner/query/like", data);
  },
};
