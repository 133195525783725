// import { ACCESS_TOKEN } from "../store/modules/user";
import { ACCESS_TOKEN } from "../store/mutation-types";

const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

const setToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export { getToken, setToken, removeToken };
