/*
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-10-31 18:15:18
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-11-20 16:16:36
 * @FilePath: \ok-college-entrance-examination-choices\src\permission.js
//  * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Router from "vue-router";
import router from "./router";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import defaultSettings from "@/config/defaultSettings";
import { Loading } from "element-ui";
import guestRouter from "@/router/guestRoute";
import { routerSystem } from "@/router/index";
import studentRouter from "@/router/studentRoute";
import store from "./store";
import modelHook from "./utils/modalHook";

// 权限验证
router.beforeEach(async (to, from, next) => {
  if (store.getters.isLogin && to.meta?.system === "C") {
    await store.dispatch("user/getInfo");
  }
  if (to.meta && to.meta.system === "C") {
    setModal(to);
  }
  console.log("登录");
  Loading.service({ fullscreen: true });
  to.meta &&
    typeof to.meta.title !== "undefined" &&
    (document.title = `${to.meta.title} - ${defaultSettings.title}`);
  /** 判断是否存在token */
  let token = localStorage.getItem(ACCESS_TOKEN);
  console.log(
    "游客登录",
    to.path,
    defaultSettings.loginRoutePath,
    process.env.VUE_APP_B_MODE,
    process.env.VUE_APP_C_MODE
  );
  if (token) {
    next();
    /** 判断项目是否包含除登录注册等页面外的游客菜单 */
  } else if (
    process.env.VUE_APP_B_MODE === "true" &&
    defaultSettings.whiteList.includes(to.path)
  ) {
    // 在免登录白名单，直接进入
    next();
  } else if (process.env.VUE_APP_C_MODE === "true") {
    // 后台获取游客路由
    if (sessionStorage.getItem("phoneNumber") === "guest") {
      sessionStorage.removeItem("phoneNumber");
      next();
    } else {
      getGuestInfoAndRoute(to, from, next);
    }
  }
});

router.afterEach((to, form) => {
  // 路由跳转后，滚动条回到顶部
  // const content = document.querySelector(".layout-container > .content");
  // if (content) {
  //   content.scrollTop = 0;
  // }

  Loading.service({ fullscreen: true }).close();
});
function getGuestInfoAndRoute(to, from, next) {
  console.log("游客登录");
  // 动态添加可访问路由表
  // const guestRouter = store.getters.addRouters
  const guestRoutes = guestRouter.concat(studentRouter).concat(routerSystem);
  router.matcher = new Router({
    mode: "history",
    routes: guestRoutes,
  });
  console.log(router);
  // 游客登录添加guest权限 添加游客基础信息
  sessionStorage.setItem("phoneNumber", "guest");
  next();
}

function setModal(to) {
  // 未登录弹框
  if (to.meta.requireAuth && !store.getters.isLogin) {
    modelHook();
  }
  // 已登录但未完善信息弹框 不区分页面
  if (
    store.getters.isLogin &&
    (!store.getters.userInfo.province || !store.getters.userInfo.year)
  ) {
    modelHook();
  }
}
