import request from "@/utils/request";
export default {
  // 获取销售信息
  getSalesInfo(studentId) {
    return request.get(`/api/student/sales/query/student/${studentId}`);
  },
  updateSalesInfo(data) {
    return request.put("/api/student/sales", data);
  },
  // c端根据学生id查询学生详情
  queryStudentDetailByStudentId(studentId) {
    return request.get(`/api/student/query-by-student-id/${studentId}`);
  },
  // c端更新学生
  updateStudentDetailC(data) {
    return request.post("/api/student/update", data);
  },
  // b端根据规划师id查询学生详情
  queryStudentDetail(data) {
    return request.get("/api/student/query/" + data);
  },
  updateStudentDetail(data) {
    return request.put("/api/student", data);
  },
  queryPhysical(data) {
    return request.get("/api/physical/query/student/" + data);
  },
  updatePhysical(data) {
    return request.put("/api/physical", data);
  },
  queryIntention(data) {
    return request.get("/api/intention/query/student/" + data);
  },
  updateIntention(data) {
    return request.put("/api/intention", data);
  },
  queryStudentExt(data) {
    return request.get("/api/student/ext/query/student/" + data);
  },
  updateStudentExt(data) {
    return request.put("/api/student/ext", data);
  },
  verifyStudentInfo(data) {
    return request.get("/api/student/verify/info/" + data);
  },
};
