<template>
  <div class="page">
    <div class="block_5">
      <div class="row" style="margin-top: 39px">
        <span class="text_20">院校省份</span>
        <el-checkbox-group
          v-model="filterForm.province"
          size="mini"
          @change="filterFormChange"
        >
          <el-checkbox-button key="全部" label="全部">全部</el-checkbox-button>
          <el-checkbox-button
            v-for="item in options.provinces"
            :key="item"
            :label="item"
            >{{ item }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
      <!-- <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="https://lanhu-oss.lanhuapp.com/SketchPngd7ad814251778f878d97de07a73cd09d315b656a538d4550ef7ab2836cdec1ea"
      /> -->
      <div class="row">
        <span class="text_20">院校类型</span>
        <el-checkbox-group
          v-model="filterForm.type"
          @change="filterFormChange"
          size="mini"
        >
          <el-checkbox-button key="全部" label="全部">全部</el-checkbox-button>
          <el-checkbox-button
            v-for="it in options.schoolTypes"
            :key="it"
            :label="it"
            >{{ it }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
      <!-- <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="https://lanhu-oss.lanhuapp.com/SketchPngd7ad814251778f878d97de07a73cd09d315b656a538d4550ef7ab2836cdec1ea"
      /> -->
      <div class="row">
        <span class="text_20">院校特色</span>
        <el-checkbox-group
          v-model="filterForm.feature"
          @change="filterFormChange"
          size="mini"
        >
          <el-checkbox-button key="全部" label="全部">全部</el-checkbox-button>
          <el-checkbox-button
            v-for="it in options.schoolFeatures"
            :key="it"
            :label="it"
            >{{ it }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
      <!-- <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="https://lanhu-oss.lanhuapp.com/SketchPngd7ad814251778f878d97de07a73cd09d315b656a538d4550ef7ab2836cdec1ea"
      /> -->
      <div class="row">
        <span class="text_20">院校性质</span>
        <el-checkbox-group
          class="selectList"
          v-model="filterForm.institution"
          @change="filterFormChange"
          size="mini"
        >
          <el-checkbox-button key="全部" label="全部">全部</el-checkbox-button>
          <el-checkbox-button
            v-for="it in options.schoolInstitutions"
            :key="it"
            :label="it"
            >{{ it }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
      <div class="section_8 flex-row">
        <el-input
          prefix-icon="el-icon-search"
          style="width: 321px"
          type="text"
          class="search_input"
          placeholder="输入院校名称"
          v-model="filterForm.school"
        ></el-input>
        <div class="text-wrapper_70 flex-col" @click="searchHandler">搜索</div>
      </div>
    </div>
    <div class="text-wrapper_71">
      <span class="text_91">共{{ total }}条</span>
    </div>
    <div class="block_6 flex-col">
      <template v-if="schoolList.length === 0">
        <el-empty></el-empty>
      </template>
      <div v-for="(item, index) in schoolList" :key="index + ''">
        <div
          :key="index + ''"
          class="box_22 flex-row"
          style="gap: 8px"
          @click="goDetail(item.id)"
        >
          <div class="group_6 flex-col">
            <img :src="item.schoolCrestUrl" width="100%" height="auto" />
          </div>
          <div class="group_39 flex-col justify-between">
            <span class="text_94">{{ item.schoolName }}</span>
            <div class="group_40 flex-row" style="gap: 8px">
              <div
                class="text-wrapper_72 flex-col"
                v-for="(feature, index) in item.schoolFeatures"
                :key="index + ''"
              >
                {{ feature }}
              </div>
            </div>
            <span class="text_99"
              >{{ item.provinceName }}/{{ item.cityName }}/{{
                item.schoolType.join("/")
              }}/{{ item.schoolInstitution }}/{{ item.belongs }}</span
            >
          </div>
        </div>
        <span
          style="
            background-color: #f0f0f0;
            height: 1px;
            width: 1152px;
            display: block;
            margin-left: 24px;
          "
        ></span>
      </div>
    </div>
    <div class="group_46 flex-row">
      <el-pagination
        @size-change="searchSchool"
        @current-change="searchSchool"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        layout="prev, pager, jumper,next"
        :total="total"
        prev-text="上一页"
        next-text="下一页"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import options from "@/utils/options";
import school from "@/apis/school";
// import province from "@/apis/province";
export default {
  mixins: [options],
  data() {
    return {
      constants: {},
      filterForm: {
        province: ["全部"],
        type: ["全部"],
        feature: ["全部"],
        institution: ["全部"],
        school: "",
      },
      schoolList: [],
      total: 2,
      currentPage: 1,
      pageSize: 6,
    };
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    goDetail(id) {
      this.$emit("getDetail", id);
    },
    searchHandler() {
      this.currentPage = 1;
      this.searchSchool();
    },
    searchSchool() {
      let data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        query: this.filterForm.school,
        // 院校省份
        provinces: this.filterForm.province.includes("全部")
          ? []
          : [...this.filterForm.province],
        // 院校类型
        types: this.filterForm.type.includes("全部")
          ? []
          : [...this.filterForm.type],
        //  院校性质
        schoolInstitutions: this.filterForm.institution.includes("全部")
          ? []
          : [...this.filterForm.institution],
        // 院校特色
        features: this.filterForm.feature.includes("全部")
          ? []
          : [...this.filterForm.feature],
        majorId: this.id,
      };
      if (this.filterForm.school && this.filterForm.school.length > 0) {
        school.searchScoolByKeywords(data).then((res) => {
          this.schoolList = res.message.records;
          this.total = res.message.total;
          this.currentPage = res.message.current;
          this.pageSize = res.message.size;
        });
      } else {
        school.queryScool(data).then((res) => {
          this.schoolList = res.message.records;
          this.total = res.message.total;
          this.currentPage = res.message.current;
          this.pageSize = res.message.size;
        });
      }
    },
    filterFormChange() {
      if (
        this.filterForm.province.lastIndexOf("全部") ==
        this.filterForm.province.length - 1
      ) {
        this.filterForm.province = ["全部"];
      }
      if (
        this.filterForm.type.lastIndexOf("全部") ==
        this.filterForm.type.length - 1
      ) {
        this.filterForm.type = ["全部"];
      }
      if (
        this.filterForm.feature.lastIndexOf("全部") ==
        this.filterForm.feature.length - 1
      ) {
        this.filterForm.feature = ["全部"];
      }
      if (
        this.filterForm.institution.lastIndexOf("全部") ==
        this.filterForm.institution.length - 1
      ) {
        this.filterForm.institution = ["全部"];
      }
      if (
        this.filterForm.province.includes("全部") &&
        this.filterForm.province.length > 1
      ) {
        this.filterForm.province.splice(
          this.filterForm.province.indexOf("全部"),
          1
        );
      }
      if (
        this.filterForm.type.includes("全部") &&
        this.filterForm.type.length > 1
      ) {
        this.filterForm.type.splice(this.filterForm.type.indexOf("全部"), 1);
      }
      if (
        this.filterForm.feature.includes("全部") &&
        this.filterForm.feature.length > 1
      ) {
        this.filterForm.feature.splice(
          this.filterForm.feature.indexOf("全部"),
          1
        );
      }
      // 院校性质为单选
      this.filterForm.institution = [
        this.filterForm.institution[this.filterForm.institution.length - 1],
      ];
      this.currentPage = 1;
      this.$nextTick(() => {
        this.searchSchool();
      });
    },
  },
  watch: {
    // options(newV, oldV) {
    //   this.$forceUpdate();
    // },
    id() {
      this.searchSchool();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.searchSchool();
    });
  },
};
</script>
<style lang="scss" scoped>
// @import "../chadaxue/assets/index.scss";
@import "@/assets/scss/common.scss";
.page {
  width: 100%;
  .block_5 {
    margin: 0;
    .row {
      display: flex;
      .text_20 {
        width: 64px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #15171d;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 4px;
      }
      ::v-deep .el-checkbox-group {
        flex: 1;
        text-align: left;
        margin-left: 30px;
        border-bottom: 1px solid rgba(228, 233, 237, 1);
        margin-bottom: 20px;
        label {
          margin: 0 8px 8px 0;
        }
      }
    }
  }
  .section_8 {
    background-color: rgba(249, 249, 249, 1);
    border-radius: 8px;
    width: 422px;
    height: 42px;
    border: 1px solid rgba(224, 226, 227, 1);
    margin: 20px 0 32px 16px;
    display: flex;
    align-items: center;
    .image-text_13 {
      width: 300px;
      height: 22px;
      margin: 0 0 0 19px;
      .thumbnail_3 {
        width: 16px;
        height: 16px;
        margin-top: 3px;
      }
      .text-group_1 {
        width: 96px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(193, 197, 205, 1);
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
      }
    }
    .text-wrapper_70 {
      background-color: rgba(233, 48, 45, 1);
      border-radius: 0px 8px 8px 0px;
      height: 42px;
      width: 101px;
      margin: 0 1px 0 0;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      .text_90 {
        width: 32px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 10px 0 0 33px;
      }
    }
  }
  .text-wrapper_71 {
    width: 78px;
    height: 25px;
    overflow-wrap: break-word;
    font-size: 0;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 25px;
    margin-left: 20px;
    .text_91 {
      width: 78px;
      height: 25px;
      overflow-wrap: break-word;
      color: rgba(50, 64, 75, 1);
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 25px;
    }
    .text_92 {
      width: 78px;
      height: 25px;
      overflow-wrap: break-word;
      color: rgba(21, 23, 29, 1);
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 25px;
    }
    .text_93 {
      width: 78px;
      height: 25px;
      overflow-wrap: break-word;
      color: rgba(50, 64, 75, 1);
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 25px;
    }
  }
  .block_6 {
    width: 100%;
    height: 817px;
    background-color: #fff;
    border-radius: 10px;
    background-size: 100% 100%;
    div {
      cursor: pointer;
    }
    .box_22 {
      width: auto;
      height: 86px;
      margin: 24px 0 24px 24px;
      .group_6 {
        width: 86px;
        height: 86px;
      }
      .group_39 {
        width: auto;
        height: 79px;
        margin-left: 8px;
        .text_94 {
          width: 64px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .group_40 {
          width: 215px;
          height: 21px;
          margin-top: 8px;
          .text-wrapper_72 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            // width: 50px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-wrap: break-word;
            color: rgba(113, 114, 116, 1);
            font-size: 12px;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
          }
          .text-wrapper_73 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 37px;
            .text_96 {
              width: 17px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_74 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_97 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_75 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_98 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
        }
        .text_99 {
          width: 196px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(45, 46, 66, 1);
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 8px 0 0 1px;
        }
      }
      .text-wrapper_76 {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 18px;
        height: 21px;
        border: 1px solid rgba(213, 215, 221, 1);
        width: 61px;
        margin: 30px 0 0 8px;
        .text_100 {
          width: 41px;
          height: 17px;
          overflow-wrap: break-word;
          color: rgba(113, 114, 116, 1);
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 17px;
          margin: 2px 0 0 10px;
        }
      }
      .text-wrapper_77 {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 18px;
        height: 21px;
        border: 1px solid rgba(213, 215, 221, 1);
        width: 104px;
        margin: 30px 0 0 8px;
        .text_101 {
          width: 84px;
          height: 17px;
          overflow-wrap: break-word;
          color: rgba(113, 114, 116, 1);
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 17px;
          margin: 2px 0 0 10px;
        }
      }
      .text-wrapper_78 {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 18px;
        height: 21px;
        border: 1px solid rgba(213, 215, 221, 1);
        width: 44px;
        margin: 30px 0 0 8px;
        .text_102 {
          width: 24px;
          height: 17px;
          overflow-wrap: break-word;
          color: rgba(113, 114, 116, 1);
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 17px;
          margin: 2px 0 0 10px;
        }
      }
      .text-wrapper_79 {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 18px;
        height: 21px;
        border: 1px solid rgba(213, 215, 221, 1);
        width: 56px;
        margin: 30px 0 0 8px;
        .text_103 {
          width: 36px;
          height: 17px;
          overflow-wrap: break-word;
          color: rgba(113, 114, 116, 1);
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 17px;
          margin: 2px 0 0 10px;
        }
      }
      .text-wrapper_80 {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 18px;
        height: 21px;
        border: 1px solid rgba(213, 215, 221, 1);
        width: 68px;
        margin: 30px 0 0 8px;
        .text_104 {
          width: 48px;
          height: 17px;
          overflow-wrap: break-word;
          color: rgba(113, 114, 116, 1);
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 17px;
          margin: 2px 0 0 10px;
        }
      }
      .text-wrapper_81 {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 18px;
        height: 21px;
        border: 1px solid rgba(213, 215, 221, 1);
        width: 36px;
        margin: 30px 0 0 8px;
        .text_105 {
          width: 16px;
          height: 17px;
          overflow-wrap: break-word;
          color: rgba(113, 114, 116, 1);
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 17px;
          margin: 2px 0 0 10px;
        }
      }
      .text-wrapper_82 {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 18px;
        height: 21px;
        border: 1px solid rgba(213, 215, 221, 1);
        width: 68px;
        margin: 30px 0 0 8px;
        .text_106 {
          width: 48px;
          height: 17px;
          overflow-wrap: break-word;
          color: rgba(113, 114, 116, 1);
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 17px;
          margin: 2px 0 0 10px;
        }
      }
    }
    .image_8 {
      width: 1168px;
      height: 1px;
      margin: 24px 0 0 16px;
    }
    .box_23 {
      width: 361px;
      height: 86px;
      margin: 24px 0 0 24px;
      .box_2 {
        background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8a7f4d20399d45f9a6a6fd04287029df_mergeImage.png);
        width: 86px;
        height: 86px;
      }
      .block_21 {
        width: 267px;
        height: 79px;
        .text_107 {
          width: 112px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .group_41 {
          width: 267px;
          height: 21px;
          margin-top: 8px;
          .image-text_2 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            width: 42px;
            .text_108 {
              width: 22px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_83 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 37px;
            .text_109 {
              width: 17px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_84 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_110 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_85 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 44px;
            .text_111 {
              width: 24px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_86 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_112 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
        }
        .text_113 {
          width: 196px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(45, 46, 66, 1);
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 8px 0 0 1px;
        }
      }
    }
    .image_9 {
      width: 1168px;
      height: 1px;
      margin: 24px 0 0 16px;
    }
    .box_24 {
      width: 361px;
      height: 86px;
      margin: 24px 0 0 24px;
      .section_9 {
        background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/af85bf29124d45d2b54f8e00ce25254e_mergeImage.png);
        width: 86px;
        height: 86px;
      }
      .group_42 {
        width: 267px;
        height: 79px;
        .text_114 {
          width: 128px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .box_25 {
          width: 267px;
          height: 21px;
          margin-top: 8px;
          .image-text_3 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            width: 42px;
            .text_115 {
              width: 22px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_87 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 37px;
            .text_116 {
              width: 17px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_88 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_117 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_89 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 44px;
            .text_118 {
              width: 24px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_90 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_119 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
        }
        .text_120 {
          width: 196px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(45, 46, 66, 1);
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 8px 0 0 1px;
        }
      }
    }
    .image_10 {
      width: 1168px;
      height: 1px;
      margin: 24px 0 0 16px;
    }
    .box_26 {
      width: 738px;
      height: 86px;
      margin: 24px 0 0 24px;
      .section_11 {
        background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e478b8141498447ba32188ff69610ce7_mergeImage.png);
        width: 86px;
        height: 86px;
      }
      .box_27 {
        width: 644px;
        height: 79px;
        .text_121 {
          width: 64px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .group_43 {
          width: 644px;
          height: 21px;
          margin-top: 8px;
          .text-wrapper_91 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            width: 42px;
            .text_122 {
              width: 22px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_92 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 37px;
            .text_123 {
              width: 17px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_93 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_124 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_94 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 61px;
            .text_125 {
              width: 41px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_95 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 104px;
            .text_126 {
              width: 84px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_96 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 44px;
            .text_127 {
              width: 24px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_97 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_128 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_98 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 68px;
            .text_129 {
              width: 48px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_99 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 36px;
            .text_130 {
              width: 16px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_42 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 68px;
            .text_131 {
              width: 48px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
        }
        .text_132 {
          width: 196px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(45, 46, 66, 1);
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 8px 0 0 1px;
        }
      }
    }
    .image_11 {
      width: 1168px;
      height: 1px;
      margin: 24px 0 0 16px;
    }
    .box_28 {
      width: 739px;
      height: 86px;
      margin: 24px 0 0 23px;
      .box_5 {
        background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/51237bf50bd34e6cad0eee39187ecc14_mergeImage.png);
        width: 86px;
        height: 86px;
      }
      .group_44 {
        width: 644px;
        height: 79px;
        .text_133 {
          width: 112px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .group_45 {
          width: 644px;
          height: 21px;
          margin-top: 8px;
          .image-text_4 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            width: 42px;
            .text_134 {
              width: 22px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_101 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 37px;
            .text_135 {
              width: 17px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_102 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_136 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_103 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 61px;
            .text_137 {
              width: 41px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_104 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 104px;
            .text_138 {
              width: 84px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_105 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 44px;
            .text_139 {
              width: 24px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_106 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_140 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_107 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 68px;
            .text_141 {
              width: 48px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_108 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 36px;
            .text_142 {
              width: 16px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_109 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 68px;
            .text_143 {
              width: 48px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
        }
        .text_144 {
          width: 196px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(45, 46, 66, 1);
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 8px 0 0 1px;
        }
      }
    }
    .image_12 {
      width: 1168px;
      height: 1px;
      margin: 24px 0 0 16px;
    }
    .box_29 {
      width: 739px;
      height: 86px;
      margin: 23px 0 33px 23px;
      .group_12 {
        background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/31c12b7d13a0424ebb314f7f349d2cab_mergeImage.png);
        width: 86px;
        height: 86px;
      }
      .box_30 {
        width: 644px;
        height: 79px;
        .text_145 {
          width: 112px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .box_31 {
          width: 644px;
          height: 21px;
          margin-top: 8px;
          .image-text_5 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            width: 42px;
            .text_146 {
              width: 22px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_110 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 37px;
            .text_147 {
              width: 17px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_111 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_148 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_112 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 61px;
            .text_149 {
              width: 41px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_113 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 104px;
            .text_150 {
              width: 84px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_114 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 44px;
            .text_151 {
              width: 24px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_115 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 56px;
            .text_152 {
              width: 36px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_116 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 68px;
            .text_153 {
              width: 48px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_117 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 36px;
            .text_154 {
              width: 16px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
          .text-wrapper_118 {
            background-color: rgba(245, 245, 245, 1);
            border-radius: 18px;
            height: 21px;
            border: 1px solid rgba(213, 215, 221, 1);
            margin-left: 8px;
            width: 68px;
            .text_155 {
              width: 48px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(113, 114, 116, 1);
              font-size: 12px;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 17px;
              margin: 2px 0 0 10px;
            }
          }
        }
        .text_156 {
          width: 196px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(45, 46, 66, 1);
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 8px 0 0 1px;
        }
      }
    }
  }
  .group_46 {
    width: 672px;
    height: 40px;
    // margin: 30px 0 5px 624px;
    margin-top: 30px;
    .text-wrapper_119 {
      background-color: rgba(233, 48, 45, 1);
      border-radius: 4px;
      height: 40px;
      width: 40px;
      .text_157 {
        width: 6px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 17px;
      }
    }
    .text-wrapper_120 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      height: 40px;
      margin-left: 24px;
      width: 40px;
      .text_158 {
        width: 9px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(21, 23, 29, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 17px;
      }
    }
    .text-wrapper_121 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      height: 40px;
      margin-left: 24px;
      width: 40px;
      .text_159 {
        width: 9px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(21, 23, 29, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 17px;
      }
    }
    .text-wrapper_122 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      height: 40px;
      margin-left: 24px;
      width: 40px;
      .text_160 {
        width: 9px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(21, 23, 29, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 17px;
      }
    }
    .text-wrapper_123 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      height: 40px;
      margin-left: 24px;
      width: 40px;
      .text_161 {
        width: 9px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(21, 23, 29, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 17px;
      }
    }
    .text_162 {
      width: 14px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(21, 23, 29, 1);
      font-size: 14px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin: 10px 0 0 24px;
    }
    .text-wrapper_124 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      height: 40px;
      margin-left: 24px;
      width: 40px;
      .text_163 {
        width: 17px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(21, 23, 29, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 11px;
      }
    }
    .text_164 {
      width: 28px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(21, 23, 29, 1);
      font-size: 14px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin: 10px 0 0 34px;
    }
    .text-wrapper_125 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      height: 40px;
      margin-left: 24px;
      width: 40px;
      .text_165 {
        width: 6px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(169, 178, 191, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 17px;
      }
    }
    .text_166 {
      width: 14px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(21, 23, 29, 1);
      font-size: 14px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin: 10px 0 0 24px;
    }
    .text-wrapper_126 {
      background-color: rgba(233, 48, 45, 1);
      border-radius: 4px;
      height: 40px;
      margin-left: 34px;
      width: 76px;
      .text_167 {
        width: 42px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
        margin: 10px 0 0 17px;
      }
    }
  }
  .block_14 {
    background-color: rgba(255, 255, 255, 1);
    height: 340px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 1729px;
    .box_32 {
      width: 1168px;
      height: 151px;
      margin: 40px 0 0 376px;
      .group_47 {
        width: 180px;
        height: 139px;
        margin-top: 12px;
        .image-text_14 {
          width: 180px;
          height: 44px;
          .label_1 {
            width: 44px;
            height: 44px;
          }
          .text-group_2 {
            width: 120px;
            height: 44px;
            overflow-wrap: break-word;
            color: rgba(21, 23, 29, 1);
            font-size: 30px;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 44px;
          }
        }
        .group_48 {
          width: 173px;
          height: 28px;
          margin: 44px 0 0 1px;
          .thumbnail_4 {
            width: 18px;
            height: 17px;
            margin-top: 4px;
          }
          .text_168 {
            width: 145px;
            height: 28px;
            overflow-wrap: break-word;
            color: rgba(45, 46, 66, 1);
            font-size: 20px;
            font-family: PingFangSC-Semibold;
            font-weight: 600;
            text-align: left;
            white-space: nowrap;
            line-height: 28px;
          }
        }
        .text-wrapper_135 {
          width: 175px;
          height: 21px;
          margin-top: 2px;
          .text_169 {
            width: 75px;
            height: 20px;
            overflow-wrap: break-word;
            color: rgba(132, 133, 135, 1);
            font-size: 14px;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 20px;
            margin-top: 1px;
          }
          .text_170 {
            width: 98px;
            height: 20px;
            overflow-wrap: break-word;
            color: rgba(132, 133, 135, 1);
            font-size: 14px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 20px;
          }
        }
      }
      .text-group_7 {
        width: 70px;
        height: 80px;
        margin: 9px 0 0 334px;
        .text_171 {
          width: 64px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(21, 23, 29, 1);
          font-size: 16px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .text_172 {
          width: 56px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(132, 133, 135, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin-top: 9px;
        }
        .text_173 {
          width: 70px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(132, 133, 135, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin-top: 9px;
        }
      }
      .text-wrapper_136 {
        width: 64px;
        height: 109px;
        margin: 8px 0 0 133px;
        .text_174 {
          width: 64px;
          height: 22px;
          overflow-wrap: break-word;
          color: rgba(21, 23, 29, 1);
          font-size: 16px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 22px;
        }
        .text_175 {
          width: 56px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(132, 133, 135, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin-top: 9px;
        }
        .text_176 {
          width: 56px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(132, 133, 135, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin-top: 9px;
        }
        .text_177 {
          width: 56px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(132, 133, 135, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin-top: 9px;
        }
      }
      .image-text_15 {
        width: 108px;
        height: 148px;
        margin-left: 139px;
        .group_17 {
          background-color: rgba(255, 255, 255, 1);
          border-radius: 8px;
          height: 108px;
          border: 1px solid rgba(236, 236, 236, 1);
          width: 108px;
          .group_18 {
            height: 84px;
            background: url(@/assets/png/logo.png) 100% no-repeat;
            background-size: 100% 100%;
            width: 84px;
            margin: 12px 0 0 12px;
            .text-wrapper_129 {
              background-color: rgba(233, 48, 45, 1);
              border-radius: 2px;
              height: 23px;
              width: 24px;
              margin: 31px 0 0 29px;
              .text_178 {
                width: 16px;
                height: 20px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 20px;
                margin: 2px 0 0 4px;
              }
            }
          }
        }
        .text-group_4 {
          width: 98px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(132, 133, 135, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 20px 0 0 5px;
        }
      }
      .image-text_16 {
        width: 108px;
        height: 147px;
        margin-left: 32px;
        .block_16 {
          background-color: rgba(255, 255, 255, 1);
          border-radius: 8px;
          height: 108px;
          border: 1px solid rgba(236, 236, 236, 1);
          width: 108px;
          .block_17 {
            height: 84px;
            background: url(@/assets/png/daxiang-red.png) 100% no-repeat;
            background-size: 100% 100%;
            width: 84px;
            margin: 12px 0 0 12px;
            .text-wrapper_130 {
              background-color: rgba(233, 48, 45, 1);
              border-radius: 2px;
              height: 23px;
              width: 24px;
              margin: 31px 0 0 30px;
              .text_179 {
                width: 16px;
                height: 20px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 20px;
                margin: 2px 0 0 4px;
              }
            }
          }
        }
        .text-group_5 {
          width: 53px;
          height: 20px;
          overflow-wrap: break-word;
          color: rgba(132, 133, 135, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 20px;
          margin: 19px 0 0 27px;
        }
      }
    }
    .text-wrapper_137 {
      width: 295px;
      height: 20px;
      margin: 78px 0 51px 810px;
      .text_180 {
        width: 295px;
        height: 20px;
        overflow-wrap: break-word;
        color: rgba(113, 114, 116, 1);
        font-size: 14px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 20px;
      }
    }
  }
}
::v-deep .el-checkbox-button {
  .el-checkbox-button__inner {
    padding: 0 14px !important;
    height: 30px;
    line-height: 30px;
    margin-left: 8px;
  }
  &.is-checked {
    .el-checkbox-button__inner {
      background-color: rgba(255, 244, 243, 1) !important;
    }
  }
}
</style>
