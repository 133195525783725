import { render, staticRenderFns } from "./Admission.vue?vue&type=template&id=3b7d4510&scoped=true"
import script from "./Admission.vue?vue&type=script&lang=js"
export * from "./Admission.vue?vue&type=script&lang=js"
import style0 from "./Admission.vue?vue&type=style&index=0&id=3b7d4510&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7d4510",
  null
  
)

export default component.exports