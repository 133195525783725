<template>
  <div class="layout">
    <el-row type="flex" justify="space-around" class="layout-header">
      <Header></Header>
    </el-row>
    <el-row type="flex" class="layout-sidebar">
      <div :class="isCollapse ? 'leftNav leftNav-collapse' : 'leftNav'">
        <Menu :isCollapse="isCollapse"></Menu>
        <el-tooltip
          effect="light"
          :content="isCollapse ? '展开' : '收起'"
          placement="right"
          style="
            position: absolute;
            right: 10px;
            width: 10px;
            top: 50%;
            transform: translateY(-50%);
          "
        >
          <i
            :class="
              isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'
            "
            style="color: rgb(100, 100, 100); cursor: pointer"
            @click="isCollapse = !isCollapse"
          ></i>
        </el-tooltip>
      </div>
      <div :class="isCollapse ? 'frame frame-collapse' : 'frame'">
        <router-view></router-view>
      </div>
    </el-row>
    <el-row type="flex" justify="space-around" class="layout-footer">
      <Footer></Footer>
    </el-row>
  </div>
</template>

<script>
import Header from "@/components/layout/header/Index.vue";
import Menu from "@/components/layout/menu/Index";
import Footer from "@/components/layout/footer/Index.vue";
import B from "@/mixins/b.js";
export default {
  components: { Footer, Header, Menu },
  mixins: [B],
  props: {},
  data() {
    return {
      isCollapse: false,
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.layout {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
}
.layout-header {
  background-color: rgb(239, 243, 243);
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
}
.layout-sidebar {
  background-color: rgb(239, 243, 243);
  width: 100%;
  height: calc(100% - 100px);
  margin-bottom: 10px;
}
.layout-footer {
  width: 100%;
  margin-top: 10px;
}

.leftNav {
  width: 13%;
  height: 100%;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
  position: relative;
}

.leftNav-collapse {
  width: 4% !important;
}

.frame {
  width: 87%;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
}

.frame-collapse {
  width: 96% !important;
}

.leftNav ul li {
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
}

.leftNav ul li:hover {
  background-color: #171a1d;
}

.leftNav ul li a {
  display: block;
  color: #fff;
  text-decoration: none;
}

.leftNav ul li:hover a {
  color: #fff !important;
}

.leftNav ul li:focus a {
  color: #fff !important;
}

.leftNav ul li a span {
  display: none;
}

.leftNav:hover ul li a span {
  display: inline;
}
</style>
