<template>
  <div class="block_1 flex-col">
    <div class="group_2 flex-col">
      <div class="section_2 flex-row justify-between">
        <div class="group_9 flex-col">
          <div class="group_11 flex-row justify-between">
            <div class="box_5 flex-col"></div>
            <span class="text_39">专业简介</span>
          </div>
          <span
            class="text_40"
            :style="
              expendOverview ? {} : { height: '160px', overflow: 'hidden' }
            "
          >
            <span
              v-html="
                overviewData.overview
                  ? overviewData.overview.replace(/\n/g, '<br/>')
                  : '-'
              "
            ></span>
          </span>
          <div
            class="group_12 flex-row justify-between"
            v-if="overviewData.overview"
          >
            <span class="text_41" @click="expendOverview = !expendOverview">{{
              expendOverview ? "收起" : "展开全部"
            }}</span>
            <div class="box_6 flex-col"></div>
          </div>
          <div class="group_13 flex-col">
            <div class="box_7 flex-col">
              <div class="section_5 flex-row">
                <div class="block_6 flex-col"></div>
              </div>
              <div class="section_6 flex-row">
                <div class="text-wrapper_8">
                  <span class="text_42">{{ overviewData.sampleSize }}</span>
                  <span class="text_43">份</span>
                </div>
              </div>
              <div class="section_7 flex-row">
                <div class="box_8 flex-col"></div>

                <span class="text_44"
                  ><svg-icon iconClass="star"></svg-icon
                  >获取相关岗位样本数<svg-icon iconClass="star"></svg-icon
                ></span>
                <div class="box_9 flex-col"></div>
              </div>
            </div>
          </div>
          <div class="group_14 flex-row justify-between">
            <div class="box_10 flex-col"></div>
            <span class="text_45">人才需求</span>
          </div>
          <div class="group_15 flex-row">
            <div class="text-wrapper_9">
              <span class="text_46">招聘人数约</span>
              <span class="text_47">{{ peopleNum }}</span>
              <span class="text_48">人</span>
            </div>
            <div class="text-wrapper_10">
              <span class="text_49">招聘薪资范围</span>
              <span class="text_50">{{ salaryRange }}</span>
              <span class="text_51">元/月</span>
            </div>
          </div>
          <div class="group_16 flex-row justify-between">
            <div class="block_7 flex-col">
              <div
                ref="chartPeopleTend"
                style="width: 100%; height: 20rem"
              ></div>
            </div>
            <div class="block_9 flex-col">
              <div
                ref="chartSalaryTend"
                style="width: 100%; height: 20rem"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section_19 flex-row justify-between">
        <div class="box_18 flex-col"></div>
        <span class="text_91">专业要求</span>
      </div>
      <div class="section_20 flex-row">
        <template>
          <div class="box_19 flex-col">
            <span class="text_92">专业占比趋势</span>
            <div class="group_19 flex-row justify-between">
              <span class="text_94">1</span>
              <div class="section_22 flex-col"></div>
              <span class="text_95">{{
                majorRequire.length > 0 ? majorRequire[0].majorName : null
              }}</span>
            </div>
            <div class="group_19 flex-row justify-between">
              <span class="text_94">2</span>
              <div class="section_22 flex-col"></div>
              <span class="text_95">{{
                majorRequire.length > 1 ? majorRequire[1].majorName : null
              }}</span>
            </div>
            <div class="group_20 flex-row justify-between">
              <span class="text_96">3</span>
              <div class="group_21 flex-col"></div>
              <span class="text_97">{{
                majorRequire.length > 2 ? majorRequire[2].majorName : null
              }}</span>
            </div>
            <div class="group_22 flex-row justify-between">
              <span class="text_98">4</span>
              <div class="section_23 flex-col"></div>
              <span class="text_99">{{
                majorRequire.length > 3 ? majorRequire[3].majorName : null
              }}</span>
            </div>
            <div class="group_23 flex-row justify-between">
              <span class="text_100">5</span>
              <div class="group_24 flex-col"></div>
              <span class="text_101">{{
                majorRequire.length > 4 ? majorRequire[4].majorName : null
              }}</span>
            </div>
          </div>
          <div class="text-wrapper_14 flex-col">
            <span class="text_102">6</span> <span class="text_103">7</span>
            <span class="text_104">8</span> <span class="text_105">9</span>
            <span class="text_106">10</span>
          </div>
          <div class="box_23 flex-col">
            <div class="group_25 flex-col"></div>
            <div class="group_26 flex-col"></div>
            <div class="group_27 flex-col"></div>
            <div class="group_28 flex-col"></div>
            <div class="group_29 flex-col"></div>
          </div>
          <div class="text-wrapper_15 flex-col">
            <span class="text_107">{{
              majorRequire.length > 5 ? majorRequire[5].majorName : null
            }}</span>
            <span class="text_108">{{
              majorRequire.length > 6 ? majorRequire[6].majorName : null
            }}</span>
            <span class="text_108">{{
              majorRequire.length > 7 ? majorRequire[7].majorName : null
            }}</span>
            <span class="text_109">{{
              majorRequire.length > 8 ? majorRequire[8].majorName : null
            }}</span>
            <span class="text_110">{{
              majorRequire.length > 9 ? majorRequire[9].majorName : null
            }}</span>
          </div>
        </template>
        <div
          ref="chartMajorRequire"
          style="width: 25rem; height: 20rem; margin-left: 3.75rem"
        ></div>
      </div>
      <div class="section_27 flex-row justify-between">
        <div class="block_21 flex-col"></div>
        <span class="text_133">学历要求</span>
      </div>
      <div class="section_28 flex-row justify-between">
        <div class="section_29 flex-col">
          <div
            ref="chartQualificationRose"
            style="width: 100%; height: 21rem"
          ></div>
        </div>
        <div class="section_29 flex-col">
          <div
            ref="chartQualificationTrend"
            style="width: 100%; height: 21rem"
          ></div>
        </div>
      </div>
      <div class="section_37 flex-row justify-between">
        <div class="box_54 flex-col"></div>
        <span class="text_163">各省招聘统计</span>
      </div>
      <div class="section_38 flex-row justify-between">
        <template>
          <div class="block_34 flex-col">
            <div class="text-wrapper_26 flex-row">
              <span class="text_164">排位</span>
              <span class="text_165">专业名称</span>
              <span class="text_166">专业占比</span>
            </div>
            <div class="group_31 flex-row">
              <span class="text_168">1</span>
              <span class="text_169">{{
                majorRequire.length > 0 ? majorRequire[0].majorName : null
              }}</span>
              <span class="text_170">{{
                majorRequire.length > 0
                  ? majorRequire[0].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_32 flex-row">
              <span class="text_171">2</span>
              <span class="text_172">{{
                majorRequire.length > 1 ? majorRequire[1].majorName : null
              }}</span>
              <span class="text_173">{{
                majorRequire.length > 1
                  ? majorRequire[1].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_33 flex-row">
              <span class="text_174">3</span>
              <span class="text_175">{{
                majorRequire.length > 2 ? majorRequire[2].majorName : null
              }}</span>
              <span class="text_176">{{
                majorRequire.length > 2
                  ? majorRequire[2].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_34 flex-row">
              <span class="text_177">4</span>
              <span class="text_178">{{
                majorRequire.length > 3 ? majorRequire[3].majorName : null
              }}</span>
              <span class="text_179">{{
                majorRequire.length > 3
                  ? majorRequire[3].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_35 flex-row">
              <span class="text_180">5</span>
              <span class="text_181">{{
                majorRequire.length > 4 ? majorRequire[4].majorName : null
              }}</span>
              <span class="text_182">{{
                majorRequire.length > 4
                  ? majorRequire[4].majorPercentage + "%"
                  : null
              }}</span>
            </div>
          </div>
          <div class="block_35 flex-col">
            <div class="text-wrapper_27 flex-row">
              <span class="text_183">排位</span>
              <span class="text_184">专业名称</span>
              <span class="text_185">专业占比</span>
            </div>
            <div class="group_36 flex-row">
              <span class="text_187">6</span>
              <span class="text_188">{{
                majorRequire.length > 5 ? majorRequire[5].majorName : null
              }}</span>
              <span class="text_189">{{
                majorRequire.length > 5
                  ? majorRequire[5].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_37 flex-row">
              <span class="text_190">7</span>
              <span class="text_191">{{
                majorRequire.length > 6 ? majorRequire[6].majorName : null
              }}</span>
              <span class="text_192">{{
                majorRequire.length > 6
                  ? majorRequire[6].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_38 flex-row">
              <span class="text_193">8</span>
              <span class="text_194">{{
                majorRequire.length > 7 ? majorRequire[7].majorName : null
              }}</span>
              <span class="text_195">{{
                majorRequire.length > 7
                  ? majorRequire[7].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_39 flex-row">
              <span class="text_196">9</span>
              <span class="text_197">{{
                majorRequire.length > 8 ? majorRequire[8].majorName : null
              }}</span>
              <span class="text_198">{{
                majorRequire.length > 8
                  ? majorRequire[8].majorPercentage + "%"
                  : null
              }}</span>
            </div>
            <div class="group_40 flex-row">
              <span class="text_199">10</span>
              <span class="text_200">{{
                majorRequire.length > 9 ? majorRequire[9].majorName : null
              }}</span>
              <span class="text_201">{{
                majorRequire.length > 9
                  ? majorRequire[9].majorPercentage + "%"
                  : null
              }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="section_40 flex-row justify-between">
      <div class="group_41 flex-col"></div>
      <span class="text_202">招聘企业</span>
    </div>
    <template>
      <div class="text-wrapper_28 flex-row justify-between">
        <span class="text_203">招聘企业类型</span>
        <span class="text_204">优质招聘企业</span>
      </div>
      <div class="section_41 flex-row justify-between">
        <div class="box_56 flex-col">
          <div class="text-wrapper_29 flex-row">
            <span class="text_205">企业类型</span>
            <span class="text_206">企业数量</span>
            <span class="text_207">招聘人数占比</span>
          </div>
          <div
            class="block_36 flex-row"
            v-for="(item, index) in companyNatureStat"
            :key="index + ''"
          >
            <span class="text_209" :title="item.companyNature">{{
              item.companyNature
            }}</span>
            <span class="text_210">{{ item.total }}</span>
            <span class="text_211">{{ item.percent }}%</span>
          </div>

          <div class="block_41 flex-col"></div>
        </div>
        <div class="box_57 flex-col">
          <div class="text-wrapper_30 flex-row">
            <span class="text_224">企业类型</span>
            <span class="text_225">企业数量</span>
            <span class="text_226">招聘人数占比</span>
          </div>
          <div
            class="block_42 flex-row"
            v-for="(item, index) in companyNameStat"
            :key="index + ''"
          >
            <span class="text_228" :title="item.companyName">{{
              item.companyName
            }}</span>
            <span class="text_229">{{ item.total }}</span>
            <span class="text_230">{{ item.percent }}%</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import profession from "@/apis/profession";
export default {
  data() {
    return {
      constants: null,
      peopleInneed: null,
      majorRequire: [],
      qualificationsRequire: null,
      jobStat: null,
      comparyStat: null,
      companyNatureStat: [],
      companyNameStat: [],
      expendOverview: false,
      overviewData: {},
    };
  },
  props: {
    typeCode: {
      type: String,
      default: "",
    },
  },
  // watch: {
  //   $route: {
  //     handler(newV, oldV) {
  //       this.typeCode = this.$route.params.typeCode;
  //       this.init();
  //     },
  //     immediate: true,
  //   },
  // },
  computed: {
    // 获取专业名称
    peopleInneedChartData(newV, oldV) {
      if (this.peopleInneed) {
        return this.peopleInneed.salary
          .slice(0, 12)
          .map((item) => [
            item.salaryMedianL,
            item.salaryMedianH,
            item.salaryMin,
            item.salaryMax,
          ]);
      } else {
        return [];
      }
    },
    peopleNum() {
      try {
        return this.peopleInneed.hiring[0].hiringMonth[0].hiringNum;
      } catch (e) {
        return null;
      }
    },
    salaryRange() {
      try {
        return (
          this.peopleInneed.salary[0].salaryMin +
          "-" +
          this.peopleInneed.salary[0].salaryMax
        );
      } catch (e) {
        return null;
      }
    },
  },
  methods: {
    init() {
      this.queryData();
    },
    queryData() {
      profession.queryProfessionsSearch({}).then((res) => {
        let typeName = res.message
          .find((o) =>
            o.professionTypes.some((o) => o.typeCode === this.typeCode)
          )
          .professionTypes.find((o) => o.typeCode === this.typeCode).typeName;
        profession
          .queryProfessionsTypeOverview(encodeURIComponent(typeName))
          .then((res) => {
            this.overviewData = res.message;
          });
        profession
          .queryProfessionsQualificationsTrendType(encodeURIComponent(typeName))
          .then((res) => {
            this.qualificationsRequire = res.message;
            if (this.qualificationsRequire) {
              this.initChartQualificationRose();
              this.initChartQualificationTrend();
            }
          });
      });
      profession.queryProfessionsStatsCode(this.typeCode).then((res) => {
        this.peopleInneed = res.message;
        if (this.peopleInneed) {
          this.initChartPeopleTend();
          this.initChartSalaryTend();
        }
      });
      profession
        .queryProfessionsMajorRequirementsType(this.typeCode)
        .then((res) => {
          this.majorRequire = res.message
            .filter((o) => o.majorPercentage)
            .sort((a, b) => b.majorPercentage - a.majorPercentage);
          this.initChartMajorRequire();
        });
      profession
        .queryProfessionsJobPositionsByType(this.typeCode)
        .then((res) => {
          this.jobStat = res.message;
        });
      profession.queryProfessionsJobDetailsByType(this.typeCode).then((res) => {
        this.comparyStat = res.message;
        let total = this.comparyStat.reduce((pre, cur) => {
          return pre + cur.companyHiring;
        }, 0);
        let companyNatureStatTemp = this.groupedData(
          this.comparyStat,
          "companyNature"
        );
        this.companyNatureStat = [];
        Object.entries(companyNatureStatTemp).forEach(([item, value]) => {
          if (item && item !== "null") {
            let totalOne = value.reduce((pre, cur) => {
              return pre + cur.companyHiring;
            }, 0);
            let percent = Math.round((totalOne / total) * 100) || 1;
            this.companyNatureStat.push({
              companyNature: item,
              total: totalOne,
              percent: percent,
            });
          }
        });
        this.companyNatureStat = this.companyNatureStat
          .sort((a, b) => b.total - a.total)
          .slice(0, 10);

        let companyNameStatTemp = this.groupedData(
          this.comparyStat,
          "companyName"
        );
        this.companyNameStat = [];
        let companyNameStat_temp = [];
        Object.entries(companyNameStatTemp).forEach(([item, value]) => {
          if (item && item !== "null") {
            let totalOne = value.reduce((pre, cur) => {
              return pre + cur.companyHiring;
            }, 0);
            let percent = Math.round((totalOne / total) * 100) || 1;
            companyNameStat_temp.push({
              companyName: item,
              total: totalOne,
              percent: percent,
            });
          }
        });
        this.companyNameStat = companyNameStat_temp
          .sort((a, b) => b.total - a.total)
          .slice(0, 10);
      });
    },
    groupedData(items, group) {
      // 使用reduce进行分组
      return items.reduce((result, item) => {
        if (!result[item[group]]) {
          result[item[group]] = [];
        }
        result[item[group]].push(item);
        return result;
      }, {});
    },
    initChartPeopleTend() {
      const chartPeopleTendData = this.$echarts.init(
        this.$refs.chartPeopleTend
      );

      /** @type EChartsOption */
      const optionPeopleTend = {
        grid: {
          left: "20%",
        },
        title: {
          text: "近12月招聘人数趋势图",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            this.peopleInneed.hiring[0].year + "年",
            this.peopleInneed.hiring[1].year + "年",
          ],
          right: "0",
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: this.peopleInneed.hiring[0].year + "年",
            type: "line",
            data: this.peopleInneed.hiring[0].hiringMonth.map(
              (item) => item.hiringNum
            ),
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(255, 158, 68, 0.5)" }, // 渐变的起始颜色
                { offset: 1, color: "rgba(255, 70, 131, 0.5)" }, // 渐变的结束颜色
              ]),
            },
          },
          {
            name: this.peopleInneed.hiring[1].year + "年",
            type: "line",
            data: this.peopleInneed.hiring[1].hiringMonth.map(
              (item) => item.hiringNum
            ),
          },
        ],
      };
      chartPeopleTendData.setOption(optionPeopleTend);
    },
    initChartSalaryTend() {
      const chartSalaryTendData = this.$echarts.init(
        this.$refs.chartSalaryTend
      );
      /** @type EChartsOption */
      const optionSalaryTend = {
        grid: {
          left: "20%",
        },
        title: {
          text: "近12月薪资要求趋势图",
          subtext: "单位：元/月",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return (
              params.name +
              "<br>" +
              "最高薪资:" +
              params.value[4] +
              "<br>" +
              "中位数上限:" +
              params.value[2] +
              "<br>" +
              "中位数下限:" +
              params.value[1] +
              "<br>" +
              "最低薪资:" +
              params.value[3]
            );
          },
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "薪资",
            type: "candlestick",
            data: this.peopleInneedChartData,
            itemStyle: {
              color: "#FED59E",
              color0: "#FED59E",
            },
          },
        ],
      };
      chartSalaryTendData.setOption(optionSalaryTend);
    },
    initChartMajorRequire() {
      const chartMajorRequireData = this.$echarts.init(
        this.$refs.chartMajorRequire
      );
      /** @type EChartsOption */
      const optionMajorRequire = {
        title: {
          text: "专业分布",
          left: "center",
          top: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return params.name + ":" + params.value + "%";
          },
        },
        legend: {
          orient: "vertical",
          left: "left",
          show: false,
        },
        series: [
          {
            name: "专业",
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length: 2,
            },
            data: this.majorRequire
              .filter((o) => o.majorPercentage)
              .map((item) => {
                return { value: item.majorPercentage, name: item.majorName };
              }),
            // [
            //   { value: 40, name: '物联网工程' },
            //   { value: 20, name: '计算机信息技术' },
            //   { value: 30, name: '人机交互' },
            //   { value: 35, name: '电子与计算机工程' },
            //   { value: 22, name: '计算机生物工程' },
            //   { value: 15, name: '网络工程' },
            //   { value: 15, name: '信息安全' },
            //   { value: 9, name: '网络空间安全' },
            //   { value: 20, name: '软件工程' },
            //   { value: 19, name: '计算机科学与技术' }
            // ]
          },
        ],
      };
      chartMajorRequireData.setOption(optionMajorRequire);
    },
    initChartQualificationRose() {
      const chartQualificationRoseData = this.$echarts.init(
        this.$refs.chartQualificationRose
      );
      const optionQualificationRose = {
        title: {
          text: "招聘学历要求",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return params.name + ":" + params.value + "%";
          },
        },
        legend: {
          orient: "vertical",
          left: "left",
          show: false,
        },
        series: [
          {
            name: "学历要求",
            type: "pie",
            radius: [50, 100],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              show: true,
              formatter: "{b}\n{d}%",
            },
            data: [
              { value: 35, name: "不限" },
              { value: 20, name: "本科" },
              { value: 30, name: "专科以上" },
              { value: 5, name: "硕士以上" },
              { value: 15, name: "博士以上" },
            ],
          },
        ],
      };
      chartQualificationRoseData.setOption(optionQualificationRose);
    },
    initChartQualificationTrend() {
      const chartQualificationTrendData = this.$echarts.init(
        this.$refs.chartQualificationTrend
      );
      /** @type EChartsOption */
      const optionQualificationTrend = {
        title: {
          text: "近12月学历招聘趋势",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["博士", "硕士", "本科", "专科", "不限"],
          bottom: "0",
          icon: "rect",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "博士",
            type: "line",
            stack: "总量",
            areaStyle: {},
            data: this.qualificationsRequire.edu.map(
              (item) =>
                item.ratios.find((item) => item.edu.includes("博士"))?.ratio ||
                0
            ),
          },
          {
            name: "硕士",
            type: "line",
            stack: "总量",
            areaStyle: {},
            data: this.qualificationsRequire.edu.map(
              (item) =>
                item.ratios.find((item) => item.edu.includes("硕士"))?.ratio ||
                0
            ),
          },
          {
            name: "本科",
            type: "line",
            stack: "总量",
            areaStyle: {},
            data: this.qualificationsRequire.edu.map(
              (item) =>
                item.ratios.find((item) => item.edu.includes("本科"))?.ratio ||
                0
            ),
          },
          {
            name: "专科",
            type: "line",
            stack: "总量",
            areaStyle: {},
            data: this.qualificationsRequire.edu.map(
              (item) =>
                item.ratios.find((item) => item.edu.includes("专科"))?.ratio ||
                0
            ),
          },
          {
            name: "不限",
            type: "line",
            stack: "总量",
            areaStyle: {},
            data: this.qualificationsRequire.edu.map(
              (item) =>
                item.ratios.find((item) => item.edu.includes("不限"))?.ratio ||
                0
            ),
          },
        ],
      };
      chartQualificationTrendData.setOption(optionQualificationTrend);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhiye/assets/overview.scss";
</style>
