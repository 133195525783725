/*
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-11-25 16:26:38
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-11-25 18:30:32
 * @FilePath: \ok-college-entrance-examination-choices\src\apis\province.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";
export default {
  // 招生计划配置
  queryProvincePlan(province) {
    return request({
      url: "/api/province/planConfigs?provinceName=" + province,
      method: "get",
    });
  },
  // 录取分数线配置
  queryProvinceRule(data) {
    return request({
      url: "/api/province/majorScoreConfigs",
      params: data,
      method: "get",
    });
  },
  querySubjectRule(data) {
    return request({
      url: "/api/province/subject-rule",
      params: data,
      method: "get",
    });
  },
  querySubjectRuleList(data) {
    return request({
      url: "/api/province/subject-rule/list",
      params: data,
      method: "get",
    });
  },
  queryBatches(data) {
    return request.get("/api/province/batches", { params: data });
  },
  queryBatcheDetail(data) {
    return request.get("/api/province/batch-detail", { params: data });
  },
  queryProvinceList() {
    return request({
      url: "/api/province/list",
      type: "get",
    });
  },
  queryCitiesByProvince(data) {
    return request.get("/api/province/cities", { params: data });
  },
  queryDistrictsByProvinceAndCity(data) {
    return request.get("/api/province/districts", { params: data });
  },
  querySchoolByProvinceAndCityAndDistricts(data) {
    return request.get("/api/province/schools", { params: data });
  },
  queryProvinceIsBatches(data) {
    return request.get("/api/province/is-batches", { params: data });
  },
  queryScoreRangeByProvince(data) {
    return request.get("/api/province/score-range", { params: data });
  },
  queryBatchInfo(data) {
    return request.get("/api/province/batch-info", { params: data });
  },
};
