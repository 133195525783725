<template>
  <div class="container" v-if="$store.getters.userInfo.auth || schoolId">
    <p>
      <el-select v-model="year" style="width: 7.5rem" @change="getData">
        <el-option
          v-for="item in options.scoreYears"
          :key="item"
          :value="item"
          >{{ item }}</el-option
        >
      </el-select>
    </p>
    <main v-loading="loading">
      <section class="pre-container" ref="preContainer">
        <header ref="header" style="width: 100%">
          <div class="row category">所属目录：{{ breadcrumb }}</div>
          <div class="tr pd10" style="border-bottom: 1px solid #e0e0e0">
            <div class="td td-code">招生代码</div>
            <div class="td td-name">院校/专业名称</div>
            <div class="td td-plan">招生计划</div>
            <div class="td td-fee">学费</div>
            <div class="td td-year">学制</div>
            <div class="td td-subject">选科</div>
          </div>
        </header>
        <main ref="main">
          <template v-if="!tableList.length">
            <el-empty description="暂无数据"></el-empty>
          </template>
          <div v-for="item in tableList" :key="item.id">
            <template v-if="item.type === '1'">
              <div class="bread-crumb">
                {{ item.title }}
              </div>
            </template>
            <template v-if="item.type === '2'">
              <!-- <div class="bread-crumb" v-for="i in item.title.split('>')" :key="i">
            {{ i }}
          </div> -->
              <div class="tr school-row" @click="schoolClick(item)">
                <div class="td td-code">{{ item.schoolEnrollCode }}</div>
                <div class="td td-name hl">
                  {{ item.schoolName }}
                  <div class="note" v-if="item.note">
                    （注：{{ item.note }}）
                  </div>
                </div>
                <div class="td td-plan">{{ item.planNum || "-" }}</div>
                <div class="td hl" style="width: 20%">
                  <span @click="scoreClick(item)">
                    <SvgIcon iconClass="score-line" />
                    历年分数
                  </span>
                </div>
                <div class="td td-subject">{{ item.chooseSubject || "-" }}</div>
              </div>
            </template>
            <template v-if="item.type === '4'">
              <div class="tr major-row">
                <div class="td td-code">{{ item.majorEnrollCode }}</div>
                <div class="td td-name hl" @click="majorClick(item)">
                  {{ item.majorName }}
                  <div class="note" v-if="item.note">
                    （注：{{ item.note }}）
                  </div>
                </div>
                <div class="td td-plan">{{ item.planNum || "-" }}</div>
                <div class="td td-fee">{{ item.cost || "-" }}</div>
                <div class="td td-year">{{ item.learnYearText || "-" }}</div>
                <div class="td td-subject">{{ item.chooseSubject || "-" }}</div>
              </div>
            </template>
          </div>
        </main>
      </section>
    </main>
  </div>
  <div v-else>
    <vip-page />
  </div>
</template>
<script>
import options from "@/utils/options";
import school from "@/apis/school";
import { mapGetters, mapState } from "vuex";
export default {
  mixins: [options],
  name: "preFraction",
  props: {
    student: {
      type: Object,
      default: () => ({}),
    },
    schoolId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      year: "",
      tableList: [],
      breadcrumb: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapState(["globalLocation"]),
  },
  mounted() {},
  methods: {
    scoreClick(item) {
      const href = this.$router.resolve({
        path: `/preFractionScoreline/${item.schoolId}`,
      });
      window.open(href.href, "_blank");
    },
    schoolClick(item) {
      if (this.schoolId) {
        this.$emit("getSchoolDetail", item.schoolId);
      } else {
        const href = this.$router.resolve({
          path: `/chadaxueoverview/${item.schoolId}`,
        });
        window.open(href.href, "_blank");
      }
    },
    majorClick(major) {
      if (!major.majorCode) {
        return this.$message.error("该专业暂无介绍！");
      }
      if (this.schoolId) {
        const obj = {
          1: "本科",
          2: "专科",
          3: "职高",
        };
        this.$emit("getDetail", major.majorCode, obj[major.majorLevel]);
        return;
      }
      let href;
      const batchMap = {
        1: "本科",
        2: "专科",
        3: "职高",
      };
      if (major.majorCode.length === 6) {
        href = this.$router.resolve(
          `/chazhuanyedetail/${major.majorId}?code=${major.majorCode}&batch=${
            batchMap[major.majorLevel]
          }`
        );
      } else {
        href = this.$router.resolve(
          `/chazhuanyetype/${major.majorName}?code=${major.majorCode}&batch=${
            batchMap[major.majorLevel]
          }`
        );
      }
      window.open(href.href, "_blank");
    },
    tableRowClassName({ row }) {
      if (row.first) {
        return "warning-row";
      }
      return "";
    },
    arraySpanMethod({ row }) {
      if (row.children) {
        return [1, 7];
      }
    },
    getData() {
      this.loading = true;
      const params = {
        province: this.userInfo.province || this.globalLocation,
        year: +this.year,
        // batch: "提前批",
        schoolId: this.$route.params.id,
        // page: 1,
        // pageSize: 999,
        // directory: "",
      };
      if (this.schoolId) {
        params.schoolId = this.schoolId;
        params.province = this.student.province;
      }
      school.queryPrePlan(params).then(({ data }) => {
        this.tableList = data;
        this.loading = false;
        this.breadcrumb = data[0]?.title;
      });
    },
  },
  created() {
    this.year = this.options.scoreYears[0];
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.pre-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #666;
  font-size: 14px;
  height: calc(100% - 80px);
  .hl {
    cursor: pointer;
    &:hover {
      color: var(--main-thema-color) !important;
    }
  }
  .pd10 {
    padding: 10px;
  }
  .row {
    border: 1px solid #e0e0e0;
    border-bottom: none;
  }
  .category {
    text-align: left;
    font-weight: 700;
    padding: 10px;
  }
  .tr {
    width: 100%;
    display: flex;
    border: 1px solid #e0e0e0;
    border-bottom: none;
    .td {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #86909c;
    }
    .td-code {
      width: 10%;
    }
    .td-name {
      width: 50%;
    }
    .td-plan {
      width: 10%;
    }
    .td-fee {
      width: 10%;
    }
    .td-year {
      width: 10%;
    }
    .td-subject {
      width: 10%;
    }
  }
  header {
    background-color: #fff;
    .tr {
      font-weight: 700;
    }
  }
  main {
    border-bottom: 1px solid #e0e0e0;
    .bread-crumb {
      border: 1px solid #e0e0e0;
      border-bottom: none;
      padding: 10px 0;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
    .school-row,
    .major-row {
      .td {
        padding: 10px;
      }
      .td:not(:last-child) {
        border-right: 1px solid #e0e0e0;
      }
      .td-name {
        text-align: left;
      }
      .note {
        font-size: 12px;
        color: #999 !important;
      }
    }
    .school-row {
      background-color: rgb(250, 250, 250);
      .td {
        color: #121212;
        font-weight: 600;
      }
    }
    .major-row {
      .td {
        color: #333;
      }
    }
  }
  .loading {
    text-align: center;
    margin: 10px 0;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
  p {
    margin: 20px 0;
    text-align: left;
  }
}
::v-deep .el-table__expand-icon {
  display: none !important;
}
.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.school {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: rgba(50, 64, 75, 1);
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.note {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
::v-deep .warning-row {
  background: #f5f5f5;
}
::v-deep .el-table_1_column_2 {
  width: 600px !important;
}
</style>
