<!--
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-10-10 15:37:29
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-11-15 17:07:17
 * @FilePath: \ok-college-entrance-examination-choices\src\views\HomeView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <layout />
  </div>
</template>

<script>
// @ is an alias to /src
import layout from "@/components/layout/index/Index";

export default {
  name: "HomeView",
  components: {
    layout,
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  // padding: 10px 10px;
}
</style>
