import request from "@/utils/request";
export default {
  queryProfessionsTypeOverview(data) {
    return request.get("/api/professions/type-overview/" + data);
  },
  searchProfessions(data) {
    return request.get("/api/professions/search", { params: data });
  },

  queryProfessionsMajorRequirementsType(data) {
    return request.get("/api/professions/major-requirements-type/" + data);
  },
  // 获取就业前景-学历要求
  queryProfessionsQualificationsTrendType(data) {
    return request.get(
      "/api/professions/qualifications-trend-type?professionType=" + data
    );
  },
  queryProfessionsQualificationsTrendName(data) {
    return request.get("/api/professions/qualifications-trend-name" + data);
  },
  queryProfessionsByMajorType(data) {
    return request.get("/api/professions/by-major-type/" + data);
  },
  queryProfessionsByCategory(data) {
    return request.get("/api/professions/by-category/" + data);
  },
  queryProfessionsStatsCode(data) {
    return request.get("/api/professions/stats-code/" + data);
  },
  queryProfessionsJobPositionsByType(data) {
    return request.get("/api/professions/job-positions/by-type/" + data);
  },
  queryProfessionsJobPositionsByProfession(data) {
    return request.get("/api/professions/job-positions/by-profession/" + data);
  },
};
