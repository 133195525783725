var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page flex-col",staticStyle:{"padding-top":"20px"}},[_c('div',{staticClass:"group_1 flex-col"},[_c('div',{staticClass:"group_2 flex-row justify-end"},[_c('div',{staticClass:"block_8 flex-col"},[_c('div',{staticClass:"group_28 flex-row justify-between search-row"},[_c('el-select',{on:{"change":_vm.getConfig},model:{value:(_vm.province),callback:function ($$v) {_vm.province=$$v},expression:"province"}},_vm._l((_vm.$store.state.provinces),function(item){return _c('el-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1),_c('el-select',{on:{"change":_vm.yearChange},model:{value:(_vm.filterForm.year),callback:function ($$v) {_vm.$set(_vm.filterForm, "year", $$v)},expression:"filterForm.year"}},_vm._l((_vm.yearList),function(item){return _c('el-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1),_c('el-select',{on:{"change":_vm.subjectChange},model:{value:(_vm.filterForm.subject),callback:function ($$v) {_vm.$set(_vm.filterForm, "subject", $$v)},expression:"filterForm.subject"}},_vm._l((_vm.subjectList),function(item){return _c('el-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1),(_vm.batches.length)?_c('el-select',{on:{"change":_vm.batchChange},model:{value:(_vm.filterForm.batch),callback:function ($$v) {_vm.$set(_vm.filterForm, "batch", $$v)},expression:"filterForm.batch"}},_vm._l((_vm.batches),function(item){return _c('el-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1):_vm._e(),_c('SearchInput',{attrs:{"placeholder":"输入您的分数"},on:{"search":_vm.queryBatchLineSegment},model:{value:(_vm.filterForm.score),callback:function ($$v) {_vm.$set(_vm.filterForm, "score", $$v)},expression:"filterForm.score"}})],1),[_c('div',{staticClass:"group_29 flex-row"},[_c('div',{staticClass:"score"},[_c('span',{staticClass:"text_33"},[_vm._v(" "+_vm._s(_vm.batchLineSegment.highestRank || "-")+" "),(
                    _vm.batchLineSegment.lowestRank &&
                    _vm.batchLineSegment.highestRank
                  )?_c('span',{staticStyle:{"margin":"0 5px"}},[_vm._v("~")]):_vm._e(),_vm._v(" "+_vm._s(_vm.batchLineSegment.lowestRank))]),_c('span',{staticClass:"text_34"},[_vm._v("位次区间")])]),_c('div',{staticClass:"score"},[_c('span',{staticClass:"text_33"},[_vm._v(_vm._s(_vm.batchLineSegment.sameScoreCount || "-"))]),_c('span',{staticClass:"text_34"},[_vm._v("同分人数")])]),_c('div',{staticClass:"score"},[_c('span',{staticClass:"text_33"},[_vm._v(_vm._s(_vm.batchLineSegment.suggestedRank || "-"))]),_c('span',{staticClass:"text_34"},[_vm._v("建议位次")])]),_c('div',{staticClass:"box_13 flex-row"},[_c('div',{staticClass:"box_14 flex-col"}),_c('div',{staticClass:"text-wrapper_54 flex-col justify-between"},[_c('span',{staticClass:"text_39"},[_vm._v(_vm._s(_vm.batchLineSegment.competitionLevel || "-"))]),_c('span',{staticClass:"text_40"},[_vm._v("竞争程度")])]),_c('el-popover',{attrs:{"placement":"bottom","title":"","width":"200","trigger":"hover"}},[_c('div',[(_vm.batchLineSegment.competitionLevel === '高')?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" 高：1000<=同分人数 ")]):(_vm.batchLineSegment.competitionLevel === '中')?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" 中：300<=同分人数 < 1000 - "),_c('br')]):_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" 低：同分人数<300 ")])]),_c('img',{staticClass:"thumbnail_4",attrs:{"slot":"reference","referrerpolicy":"no-referrer","src":"https://lanhu-oss.lanhuapp.com/MasterDDSSlicePNG6da684d6304d8d1eef2cc7c41533c8ce.png"},slot:"reference"})])],1)]),_c('div',{staticClass:"text-wrapper_11"}),_c('div',{staticClass:"box_15 flex-col"},[_c('div',{staticClass:"block_3 flex-col"},[_c('Echarts',{attrs:{"option":_vm.chartOption,"height":"110%"}})],1)]),_vm._m(0),_c('div',{staticClass:"container"},[_c('el-table',{attrs:{"data":_vm.tableList,"border":"","header-cell-style":{
                'background-color': '#f5f5f5',
                height: '5.1rem',
                color: '#2D2E42',
                'text-align': 'center',
              },"cell-style":{
                height: '5.1rem',
                color: '#2D2E42',
                'text-align': 'center',
              }}},[_c('el-table-column',{attrs:{"label":"年份","prop":"year"}}),_c('el-table-column',{attrs:{"label":"位次区间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.lowestRank)+" - "+_vm._s(scope.row.highestRank)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"historicalEquivalentScore"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" 我的等效分 "),_c('el-tooltip',{staticClass:"item",attrs:{"slot":"header","effect":"dark","content":"历年等效分是根据历年省控线变化幅度等比浮动后，根据等效位次得到的历年等效分数。","placement":"top"},slot:"header"},[_c('i',{staticClass:"el-icon-question"})])],1)]),_c('el-table-column',{attrs:{"prop":"historicalEquivalentRank","label":"我的等效位"}})],1)],1),_vm._m(1),_vm._m(2),_c('el-table',{attrs:{"data":_vm.curveData,"border":"","header-cell-style":{
              'background-color': '#f5f5f5',
              height: '5.1rem',
              color: '#2D2E42',
              'text-align': 'center',
            },"cell-style":{
              height: '5.1rem',
              color: '#2D2E42',
              'text-align': 'center',
            }}},[_c('el-table-column',{attrs:{"label":"分数"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.score)+" "),(
                    scope.row.highScore &&
                    scope.row.score !== scope.row.highScore
                  )?_c('span',[_vm._v(" - "+_vm._s(scope.row.highScore))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"位次区间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.highestRank)+" - "+_vm._s(scope.row.lowestRank)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sameScoreCount","label":"同分人数"}})],1)]],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group_30 flex-row justify-between"},[_c('div',{staticClass:"group_11 flex-col"}),_c('span',{staticClass:"text_61"},[_vm._v("历年等效分")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_21 flex-col"},[_c('div',{staticClass:"image-text_6 flex-row justify-between"},[_c('img',{staticClass:"label_3",attrs:{"referrerpolicy":"no-referrer","src":require("@/views/student/icons/school/prompt@2x.png")}}),_c('span',{staticClass:"text-group_4"},[_vm._v("请注意")])]),_c('span',{staticClass:"paragraph_2"},[_vm._v("1、位次查询结果根据2024年教育考试院发布录取位次计算；"),_c('br'),_vm._v("2、在正式填报时，以教育考试院公布的高考成绩（位次）为准。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group_32 flex-row justify-between"},[_c('div',{staticClass:"section_9 flex-col"}),_c('span',{staticClass:"text_81"},[_vm._v("一分一段表")])])
}]

export { render, staticRenderFns }