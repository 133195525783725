<template>
  <div class="page flex-col">
    <div class="section_1 flex-col" v-if="!detailStutas">
      <div class="group_9 flex-row justify-between">
        <span class="text_47">共{{ total }}条</span>
        <div class="block_5 flex-row justify-end">
          <el-input
            class="search_input"
            prefix-icon="el-icon-search"
            style="width: 321px"
            type="text"
            placeholder="请输入内容"
            v-model="keywords"
          ></el-input>
          <div class="text-wrapper_22 flex-col" @click="queryAdmissionGuide()">
            <span class="text_48">搜索</span>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in admissionGuideList" :key="index + ''">
        <div
          class="text-wrapper_23 flex-row justify-between"
          @click="queryAdmissionGuideById(item.id)"
        >
          <span class="text_49">{{ item.title }}</span>
          <span class="text_50">{{ item.createTime.split(" ")[0] }}</span>
        </div>
        <span
          style="
            background-color: #f0f0f0;
            height: 1px;
            width: 1200px;
            display: block;
          "
        ></span>
      </div>

      <div class="group_10 flex-row">
        <el-pagination
          @size-change="queryAdmissionGuide"
          @current-change="queryAdmissionGuide"
          :current-page.sync="currentPage"
          :page-size.sync="pageSize"
          layout="prev, pager, jumper,next"
          :total="total"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else>
      <Detail :id="id" />
    </div>
  </div>
</template>
<script>
import school from "@/apis/school";
import Detail from "@/views/student/chadaxue/ZhaoshengDetail.vue";
export default {
  components: {
    Detail,
  },
  props: {
    schoolId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      keywords: "",
      admissionGuideList: [],
      admissionGuide: {
        // id: 2,
        // title: "清华大学2024年强基计划招生简章",
        // createTime: "2024-05-24",
        // matchDegree: 3,
      },
      detailStutas: false,
      id: "",
    };
  },
  methods: {
    queryAdmissionGuide() {
      let data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        keyword: this.keywords,
        schoolId: this.$route.params.id ? this.$route.params.id : this.schoolId,
      };
      school.queryAdmissionGuide(data).then((res) => {
        this.total = res.message.total;
        this.currentPage = res.message.current;
        this.pageSize = res.message.size;
        this.admissionGuideList = res.message.records;
      });
    },
    queryAdmissionGuideById(id) {
      this.id = id;
      this.detailStutas = true;
    },
  },
  watch: {
    schoolId(id) {
      if (id) {
        this.querySchoolBase();
      }
    },
  },
  mounted() {
    this.queryAdmissionGuide();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/chadaxue/assets/zhaosheng.scss";
@import "@/assets/scss/common.scss";
</style>
