<template>
  <div class="zhuanyedetail">
    <div class="box_5 flex-col">
      <div class="box_9 flex-row justify-between">
        <div class="group_5 flex-row">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="@/views/student/icons/major/undergraduate@2x.png"
          />
          <div class="text-group_14 flex-col justify-between">
            <span class="text_49">{{
              levelMap[majorDetail.level] || "-"
            }}</span>
            <span class="text_50">学历层次</span>
          </div>
        </div>
        <div class="group_6 flex-row">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="@/views/student/icons/major/time@2x.png"
          />
          <div class="text-group_15 flex-col justify-between">
            <span class="text_51">{{ majorDetail.years || "-" }}年</span>
            <span class="text_52">修学年限</span>
          </div>
        </div>
        <div class="group_7 flex-row">
          <div class="image-text_14 flex-row justify-between">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="@/views/student/icons/major/academic degree@2x.png"
            />
            <div class="text-group_16 flex-col justify-between">
              <span class="text_53" :title="majorDetail.degree || '-'">{{
                majorDetail.degree || "-"
              }}</span>
              <span class="text_54">授予学位</span>
            </div>
          </div>
        </div>
        <div class="group_8 flex-row">
          <div class="image-text_15 flex-row justify-between">
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="@/views/student/icons/major/proportion@2x.png"
            />
            <div class="text-group_17 flex-col justify-between">
              <span class="text_55">{{ majorDetail.genderRatio || "-" }}</span>
              <span class="text_56">男女比例</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_10 flex-row justify-between">
        <div class="group_9 flex-col"></div>
        <span class="text_57">专业介绍</span>
      </div>
      <span class="text_58">{{ majorDetail.introduction }}</span>
      <div class="box_11 flex-row justify-between">
        <div class="group_10 flex-col"></div>
        <span class="text_59">培养目标</span>
      </div>
      <span class="text_60">{{ majorDetail.cultivationGoal }}</span>
      <div class="box_12 flex-row justify-between">
        <div class="group_11 flex-col"></div>
        <span class="text_61">培养要求</span>
      </div>
      <span class="text_62">{{ majorDetail.cultivationRequirements }}</span>
      <div class="box_13 flex-row justify-between">
        <div class="group_12 flex-col"></div>
        <span class="text_63">学科要求</span>
      </div>
      <span class="text_64">{{ majorDetail.subjectRequirements }}</span>
      <div class="box_14 flex-row justify-between">
        <div class="block_7 flex-col"></div>
        <span class="text_65">知识能力</span>
      </div>
      <span class="paragraph_1">{{ majorDetail.knowledgeAndSkills }}</span>
      <template v-if="majorDetail.postgraduateDirections">
        <div class="box_15 flex-row justify-between">
          <div class="box_16 flex-col"></div>
          <span class="text_66">考研方向</span>
        </div>
        <span class="text_67">{{ majorDetail.postgraduateDirections }}</span>
      </template>
      <template v-if="majorDetail.mainCourses">
        <div class="box_17 flex-row justify-between">
          <div class="section_19 flex-col"></div>
          <span class="text_68">主要课程</span>
        </div>
        <span class="text_69">{{ majorDetail.mainCourses }}</span>
      </template>
      <template v-if="majorDetail.famousPersons">
        <div class="box_18 flex-row justify-between">
          <div class="box_19 flex-col"></div>
          <span class="text_70">社会名人</span>
        </div>
        <span class="text_71">{{ majorDetail.famousPersons }}</span>
      </template>
    </div>
  </div>
</template>
<script>
import major from "@/apis/major";
import eventBus from "@/eventBus";
export default {
  name: "MajorDetail",
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      majorDetail: {},
    };
  },
  watch: {
    id: {
      handler: function () {
        if (this.id) {
          this.queryMajorDetail();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    levelMap() {
      return {
        1: "本科",
        2: "专科",
        3: "职高",
      };
    },
  },
  methods: {
    queryMajorDetail() {
      major.queryMajorDetail(this.id).then((res) => {
        this.majorDetail = res.message;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhuanye/assets/detail.scss";
// .zhuanyedetail {
//   width: 100%;
//   height: 1200px;
//   overflow: hidden;
// }
</style>
