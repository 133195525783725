<template>
  <div class="page flex-col" ref="page">
    <div class="group_1 flex-row justify-end">
      <div class="block_3 flex-col">
        <div class="box_6 flex-row">
          <div class="flex-row">
            <div
              class="box_7 flex-col"
              :style="{
                'background-image': 'url(' + schoolForm.schoolCrestUrl + ')',
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
              }"
            ></div>
            <div class="box_8 flex-col justify-between">
              <span class="text_31">{{ schoolForm.schoolName }}</span>
              <div class="box_9 flex-row justify-between">
                <div
                  class="text-wrapper_7 flex-col"
                  v-for="(feature, index) in schoolForm.schoolFeatures"
                  :key="index + ''"
                >
                  <span class="text_32">{{ feature }}</span>
                </div>
              </div>
              <span class="text_37"
                >{{ schoolForm.provinceName }}/{{ schoolForm.cityName }}/{{
                  this.schoolForm.schoolType.join("/")
                }}/{{ schoolForm.schoolInstitution }}/{{
                  schoolForm.belongs
                }}</span
              >
            </div>
          </div>
          <div class="box_12 flex-col" style="background: none">
            <div class="group_13 flex-row justify-between">
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/web@2x.png"
              />
              <span class="text_52"
                >官方网址：
                <a
                  :href="schoolExcForm.officialWebsites.join()"
                  target="_blank"
                  style="color: rgba(50, 64, 75)"
                  >{{ schoolExcForm.officialWebsites.join() }}</a
                >
              </span>
            </div>
            <div class="group_14 flex-row justify-between">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/phone@2x.png"
              />
              <span class="text_53"
                >招生电话：{{ schoolExcForm.admissionPhones.join(",") }}</span
              >
            </div>
            <!-- <div class="group_15 flex-row justify-between">
                <img
                  class="label_4"
                  referrerpolicy="no-referrer"
                  src="https://lanhu-oss.lanhuapp.com/SketchPng65bef3410f131f612f3a7b28b89f58d7ab1542a615913f208a9d6b4d7ee5e446"
                />
                <span class="text_54"
                  >电子邮箱：{{ schoolExcForm.emails.join(",") }}</span
                >
              </div> -->
          </div>
        </div>
        <div class="box_17 flex-col">
          <div class="box_18 flex-row">
            <div class="image-wrapper_2 flex-col">
              <img
                class="thumbnail_5"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/time@2x.png"
              />
            </div>
            <div class="text-wrapper_27">
              <span class="text_62">创办时间：</span>
              <span class="text_63">{{ schoolForm.createYear || "-" }}</span>
            </div>
            <div class="image-wrapper_3 flex-col">
              <img
                class="thumbnail_6"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/public@2x.png"
              />
            </div>
            <div class="text-wrapper_28">
              <span class="text_64">办学性质：</span>
              <span class="text_65">{{
                schoolForm.schoolInstitution || "-"
              }}</span>
            </div>
            <div class="image-wrapper_4 flex-col">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/comprehensive@2x.png"
              />
            </div>
            <div class="text-wrapper_29">
              <span class="text_66">院校类型：</span>
              <span class="text_67">{{
                this.schoolForm.schoolType.join("/")
              }}</span>
            </div>
            <div class="image-wrapper_5 flex-col">
              <img
                class="thumbnail_7"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/framework@2x.png"
              />
            </div>
            <div class="text-wrapper_30">
              <span class="text_68">隶属于：</span>
              <span class="text_69" :title="schoolForm.belongs">{{
                schoolForm.belongs || "-"
              }}</span>
            </div>
            <div class="image-wrapper_6 flex-col">
              <img
                class="thumbnail_8"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/level@2x.png"
              />
            </div>
            <div class="text-wrapper_31">
              <span class="text_70">学科层次：</span>
              <span class="text_71">{{ schoolForm.schoolLevel || "-" }}</span>
            </div>
            <div class="image-wrapper_7 flex-col">
              <img
                class="thumbnail_9"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/IP@2x.png"
              />
            </div>
            <div class="text-wrapper_32">
              <span class="text_72">所在地：</span>
              <span class="text_73">{{ schoolForm.provinceName || "-" }}</span>
            </div>
            <div class="image-wrapper_8 flex-col">
              <img
                class="thumbnail_10"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/Dr@2x.png"
              />
            </div>
            <div class="text-wrapper_33">
              <span class="text_74">博士点：</span>
              <span class="text_75">{{ schoolForm.numDoctor || "-" }}个</span>
            </div>
          </div>
          <div class="box_19 flex-row">
            <div class="image-text_8 flex-row justify-between">
              <div class="image-wrapper_9 flex-col">
                <img
                  class="image_6"
                  referrerpolicy="no-referrer"
                  src="@/views/student/icons/school/master@2x.png"
                />
              </div>
              <div class="text-group_9">
                <span class="text_76">硕士点：</span>
                <span class="text_77">{{ schoolForm.numMaster || "-" }}个</span>
              </div>
            </div>
            <!-- <div class="image-text_9 flex-row justify-between">
                <div class="image-wrapper_10 flex-col">
                  <img
                    class="thumbnail_11"
                    referrerpolicy="no-referrer"
                    src="https://lanhu-oss.lanhuapp.com/SketchPng00a1ff1d5f45d1a5cb0cdaaeaf460d13a6f5e20e910d48d6f51e9764140cc16f"
                  />
                </div>
                <div class="text-group_10">
                  <span class="text_78">重点学科：</span>
                  <span class="text_79">{{ schoolForm.keyDiscipline }}个</span>
                </div>
              </div> -->
            <div class="image-text_10 flex-row justify-between">
              <div class="image-wrapper_11 flex-col">
                <img
                  class="image_7"
                  referrerpolicy="no-referrer"
                  src="@/views/student/icons/school/people@2x.png"
                />
              </div>
              <div class="text-group_11">
                <span class="text_80">学生人数：</span>
                <span class="text_81"
                  >{{ schoolForm.numStudent || "-" }}人</span
                >
              </div>
            </div>
            <div class="image-text_11 flex-row justify-between">
              <div class="image-wrapper_12 flex-col">
                <img
                  class="image_8"
                  referrerpolicy="no-referrer"
                  src="@/views/student/icons/school/academician@2x.png"
                />
              </div>
              <div class="text-group_12">
                <span class="text_82">院士人数：</span>
                <span class="text_83"
                  >{{ schoolForm.numAcademician || "-" }}人</span
                >
              </div>
            </div>
            <div class="image-wrapper_13 flex-col">
              <img
                class="label_7"
                referrerpolicy="no-referrer"
                src="@/views/student/icons/school/Enrollment rate@2x.png"
              />
            </div>
            <div class="image-text_12 flex-row justify-between">
              <div class="text-group_13">
                <span class="text_84">升学率：</span>
                <span class="text_85"
                  >{{ schoolForm.furtherEducationRate || "-" }}%</span
                >
              </div>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  数据为2022年数据，<br />
                  来源为2022年毕业生就业质量报告<br />
                  （升学率 = 升学人数 / 应届毕业生人数 ✖ 100%）
                </div>
                <img
                  class="thumbnail_12"
                  referrerpolicy="no-referrer"
                  src="@/views/student/icons/school/question@2x.png"
                />
              </el-tooltip>
            </div>
            <div class="section_3 flex-row">
              <div class="image-wrapper_14 flex-col">
                <img
                  class="thumbnail_13"
                  referrerpolicy="no-referrer"
                  src="@/views/student/icons/school/postgraduate@2x.png"
                />
              </div>
              <div class="image-text_13 flex-row justify-between">
                <div class="text-group_14">
                  <span class="text_86">保研率：</span>
                  <span class="text_87"
                    >{{
                      schoolForm.postgraduateRecommendationRate || "-"
                    }}%</span
                  >
                </div>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">
                    数据为2024年数据，<br />
                    来源为 院校官方或第三方<br />
                    （保研率 = 保研人数 / 毕业生人数 ✖ 100%）
                  </div>
                  <img
                    class="thumbnail_14"
                    referrerpolicy="no-referrer"
                    src="@/views/student/icons/school/question@2x.png"
                  />
                </el-tooltip>
              </div>
            </div>
            <div class="image-text_11 flex-row justify-between">
              <div class="image-wrapper_12 flex-col">
                <img
                  class="image_8"
                  referrerpolicy="no-referrer"
                  src="@/views/student/icons/school/academician@2x.png"
                />
              </div>
              <div class="text-group_12">
                <span class="text_82">杰出校友数量：</span>
                <span class="text_83">{{ schoolForm.aluMniNum || "-" }}人</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box_10 flex-row justify-between">
          <div class="group_8 flex-col">
            <img
              :src="schoolExcForm.schoolPicUrl"
              style="width: 100%; height: 100%; border-radius: 8px"
            />
          </div>
          <div class="group_10 flex-col justify-between">
            <div class="box_11 flex-row justify-between">
              <div
                class="group_11 flex-col"
                v-for="(val, key) in rankObj"
                :key="key"
                :class="{ active: key === curRank }"
                @click="curRank = key"
              >
                <span class="rank">{{ getRank(val) || "-" }}</span>
                <span class="title">{{ key }}</span>
                <span class="title">{{ getYear(val) || "-" }}</span>
              </div>
            </div>
            <div class="box_12 flex-col">
              <Echarts :option="chartOption" height="110%" />
            </div>
          </div>
        </div>
        <div class="box_14 flex-row">
          <div
            class="text-wrapper_26 flex-col"
            v-for="item in tabList"
            :key="item"
            :class="{
              active: curTab === item,
            }"
          >
            <span class="text_61" @click="changeTab(item)">{{ item }}</span>
          </div>
        </div>
        <main>
          <Detail :schoolId="schoolId" v-if="curTab === '院校概况'"></Detail>
          <Brochure
            :schoolId="schoolId"
            v-if="curTab === '招生简章'"
            ref="brochure"
          ></Brochure>
          <Admission
            :student="student"
            v-if="curTab === '录取分数线'"
            :schoolId="schoolId"
          ></Admission>
          <EnrollmentPlan
            :student="student"
            v-if="curTab === '招生计划'"
            :schoolId="schoolId"
          ></EnrollmentPlan>
          <Major
            v-if="curTab === '专业院系'"
            v-bind="$attrs"
            v-on="$listeners"
            :schoolId="schoolId"
          ></Major>
          <PreFraction
            v-bind="$attrs"
            v-on="$listeners"
            @getSchoolDetail="curTab = '院校概况'"
            v-if="curTab === '提前批招生计划'"
            :student="student"
            :schoolId="schoolId"
          ></PreFraction>
          <PreFractionLine
            v-bind="$attrs"
            v-on="$listeners"
            v-if="curTab === '提前批分数线'"
            @getSchoolDetail="curTab = '院校概况'"
            :student="student"
            :schoolId="schoolId"
          ></PreFractionLine>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import school from "@/apis/school";
import Detail from "@/views/student/chadaxue/Overview.vue";
import Brochure from "./Brochure.vue";
import Admission from "./Admission.vue";
import EnrollmentPlan from "./EnrollmentPlan.vue";
import Major from "./Major.vue";
import PreFraction from "@/views/student/chadaxue/preFraction.vue";
import PreFractionLine from "@/views/student/chadaxue/preFractionScoreline.vue";
import Echarts from "@/components/Echarts";
export default {
  components: {
    Detail,
    Brochure,
    Admission,
    EnrollmentPlan,
    Major,
    PreFraction,
    PreFractionLine,
    Echarts,
  },
  name: "SchoolDetail",
  props: {
    schoolId: {
      type: [String, Number],
      default: 0,
    },
    student: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    schoolId(id) {
      if (id) {
        this.querySchoolBase();
      }
    },
  },
  data() {
    return {
      loopData0: [],
      constants: {},
      schoolForm: {
        schoolType: [], //院校类型
      },
      schoolExcForm: {
        officialWebsites: [], //官方网址列表
        admissionPhones: [], //招生电话列表
        emails: [], //电子邮件列表
      },
      curTab: "院校概况",
      rankObj: {},
      curRank: "软科排名",
    };
  },
  computed: {
    tabList() {
      return [
        "院校概况",
        "招生简章",
        "录取分数线",
        "招生计划",
        "专业院系",
        "提前批招生计划",
        "提前批分数线",
      ];
    },
    chartOption() {
      const originalData = Object.values(this.rankObj[this.curRank] || {});
      const offset = 2; // 定义偏移值 *
      // 计算最大值并创建处理后的数据列表 *
      const maxValue = Math.max(...originalData);
      const convertedData = originalData.map(
        (value) => Math.abs(value - maxValue) + offset
      );
      return {
        xAxis: {
          type: "category",
          data: Object.keys(this.rankObj[this.curRank] || {}),
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false, // 不展示 y 轴刻度
          },
          axisLabel: {
            show: false, // 不展示 y 轴刻度标签
          },
        },
        series: [
          {
            data: convertedData,
            type: "bar",
            itemStyle: {
              color: "#e9302d", // 柱状颜色为红色
            },
            label: {
              show: true,
              position: "top", // 在柱状图顶部展示值
              formatter: (params) => originalData[params.dataIndex],
            },
            barWidth: 20, // 设置柱状图宽度
          },
        ],
      };
    },
  },
  methods: {
    getRank(obj) {
      const keys = Object.keys(obj);
      return obj[keys[keys.length - 1]];
    },
    getYear(obj) {
      const keys = Object.keys(obj);
      return keys[keys.length - 1];
    },
    changeTab(tab) {
      this.curTab = tab;
      if (tab === "招生简章") {
        this.$refs.brochure && (this.$refs.brochure.detailStutas = false);
      }
    },
    querySchoolBase() {
      school.getRank(this.schoolId).then(({ data }) => {
        this.rankObj = data;
      });
      school
        .queryScoolBase(this.schoolId)
        .then((res) => {
          this.schoolForm = res.message;
        })
        .then(() => {
          school.queryScoolDetail(this.schoolId).then((res) => {
            this.schoolExcForm = res.message;
          });
        });
    },
  },
  mounted() {
    // const content = document.querySelector(".layout-container > .content");
    // if (content) {
    //   content.scrollTop = 0;
    // }
    this.querySchoolBase();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/chadaxue/assets/detail.scss";
@import "@/assets/scss/common.scss";
.text-wrapper_26 {
  cursor: pointer;
  &.active {
    background-color: var(--main-thema-color) !important;
    span {
      color: rgba(255, 255, 255, 1) !important;
    }
  }
}
.group_11 {
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #32404b;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  &.active {
    background-color: var(--main-thema-color);
    background-image: none !important;
    .rank,
    .title {
      color: #fff;
    }
  }
  .rank {
    overflow-wrap: break-word;
    color: #e9302d;
    font-size: 40px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    line-height: 56px;
  }
  .title {
    margin-bottom: 10px;
  }
}
</style>
