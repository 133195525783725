<template>
  <div class="page flex-col">
    <div class="group_1 flex-row justify-end">
      <div class="block_3 flex-col">
        <div class="box_15 flex-row justify-between">
          <div class="box_16 flex-col"></div>
          <span class="text_59">校园简介</span>
        </div>
        <span
          class="text_60"
          :style="
            expendOverview ? {} : { 'max-height': '120px', overflow: 'hidden' }
          "
          >{{ schoolExForm.schoolOverview }}</span
        >
        <div class="image-text_5 flex-row justify-between">
          <span class="text-group_6" @click="expendOverview = !expendOverview"
            >{{ expendOverview ? "点击收起" : "点击展开"
            }}<i
              :class="
                expendOverview ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
              "
            ></i
          ></span>
        </div>
        <div class="image-text_6 flex-row justify-between">
          <div class="section_2 flex-col"></div>
          <span class="text-group_7">校园风光</span>
        </div>
        <div
          class="grid_1 flex-row"
          :style="
            expendMedia ? {} : { 'max-height': '396px', overflow: 'hidden' }
          "
        >
          <el-image
            class="list-items_1 flex-col"
            :src="item.url"
            v-for="(item, index) in schoolExForm.campusSceneryMedia"
            :key="index"
            :preview-src-list="previewSrcList"
          ></el-image>
        </div>
        <div class="image-text_7 flex-row justify-between">
          <span class="text-group_8" @click="expendMedia = !expendMedia"
            >{{ expendMedia ? "点击收起" : "点击展开"
            }}<i
              :class="expendMedia ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            ></i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import school from "@/apis/school";
export default {
  props: {
    schoolId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/eb31d55d4b7f47e497bab4d1813ef53b_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0fe19f801f23488d9fc4e80ea553a98f_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3efc42d6a5fe47ddaacda801f1640e9e_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/bcc7f7503da94f64a9d98f1fabb77ceb_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/216b1ddf7cf241559e44e2affabbbe4b_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/c989628f3de54409917cd15384e84f77_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/1d00e68ac7b542c99928c0c2ff66a9af_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8339dd0f82f44964b468352c247635d3_mergeImage.png)",
        },
      ],
      constants: {},
      schoolExForm: {
        schoolId: 1, //学校ID
        schoolName: "清华大学", //学校名称
        mainImageUrl:
          "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8b978bfb59eb447d9fb6c73192a066b2_mergeImage.png", //校园风光首图URL
        ruankeRank: 1, //软科排名
        qsWorldRank: 2, //QS世界排名
        usWorldRank: 3, //US世界排名
        officialWebsites: ["https://www.baidu.com"], //官方网址列表
        admissionPhones: ["123456789"], //招生电话列表
        emails: ["123456789"], //电子邮件列表
        schoolOverview: "学校概览", //院校概览
        campusSceneryMedia: [
          {
            type: "image",
            url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8339dd0f82f44964b468352c247635d3_mergeImage.png",
          },
        ],
      },
      expendOverview: false,
      expendMedia: false,
    };
  },
  methods: {
    querySchoolOverview() {
      school
        .queryScoolDetail(
          this.$route.params.id ? this.$route.params.id : this.schoolId
        )
        .then((res) => {
          this.schoolExForm = res.message;
        });
    },
  },
  watch: {
    schoolId(val) {
      if (val) {
        this.querySchoolOverview();
      }
    },
  },
  mounted() {
    this.querySchoolOverview();
  },
  computed: {
    previewSrcList() {
      return this.schoolExForm.campusSceneryMedia.map((item) => item.url);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./assets/overview.scss";
@import "@/assets/scss/common.scss";
</style>
