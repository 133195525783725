import request from "@/utils/request";
export default {
  // 获取排名
  getRank(schoolId) {
    return request.get(`/api/school/rank/all/${schoolId}`);
  },
  // 提前批分数线配置
  getEarlyBatchConfig(params) {
    return request.get("/api/school/pre-enter-configs", { params });
  },
  // 提前批分数线
  queryScoreLine(key) {
    return request.get("/api/school/pre-enter-details?dataKey=" + key);
  },
  // 获取院校性质
  getSchoolNature(data) {
    return request.get("/api/school/institution/extend", data);
  },
  // 获取提前批年份
  getPreYearList(province) {
    return request.get(
      "/api/admission-plan/pre-plan-years?province=" + province
    );
  },
  // 获取提前批目录列表
  getPreDirectories(data) {
    return request.get("/api/admission-plan/pre-plan-directories", data);
  },
  // 获取提前批数据
  getPreData(data) {
    return request.post("/api/admission-plan/pre-plan-details", data);
  },
  querySchoolReports(id, data) {
    return request.get(`/api/school/${id}/reports`, data);
  },
  querySchoolNews(data) {
    return request.get("/api/school/news/" + data);
  },
  querySchoolNewDetail(data) {
    return request.get("/api/school/news/detail/" + data);
  },
  querySchoolListFeature(data) {
    return request.get("/api/school/listFeature", data);
  },
  searchScoolByKeywords(data) {
    return request.post("/api/school/search?query=" + data.query, data);
  },
  querySchoolSpecialMajors(id) {
    return request.get("/api/school/special-majors/" + id);
  },
  querySchoolMajorHierarchy(id) {
    return request.get("/api/school/major-hierarchy/" + id);
  },
  querySchoolDAndMNum(id) {
    return request.get("/api/school/d-and-m-num/" + id);
  },
  querySchoolAcademicianInfo(id) {
    return request.get("/api/school/academician-info/" + id);
  },
  querySchoolDistinguishedAlumniList(id) {
    return request.get("/api/school/distinguished-alumni/list/" + id);
  },
  querySchoolDistinguishedAlumni(id) {
    return request.get("/api/school/distinguished-alumni/" + id);
  },
  // 获取学科评估信息
  querySchoolSubjectAssessmentList(data) {
    return request.get("/api/school/subject-assessment", {
      params: data,
    });
  },
  querySchoolSubjectAssessment(id, data) {
    return request.get("/api/school/subject-assessment/" + id, data);
  },
  querySchoolSubjectAssessmentGrades(id) {
    return request.get("/api/school/subject-assessment/grades/" + id);
  },
  querySchoolSubjectAssessmentRounds(id) {
    return request.get("/api/school/subject-assessment/rounds/" + id);
  },
  querySchoolDepartments(id) {
    return request.get("/api/school/departments/" + id);
  },
  queryScool(data) {
    return request.post("/api/school/query", data);
  },
  queryScoolRanking(data) {
    return request.post("/api/school/ranking", data);
  },
  queryScoolBase(data) {
    return request.get("/api/school/query/base/" + data);
  },
  queryScoolBySchoolAndBatch(data) {
    return request.get("/api/school/by-school-and-batch", data);
  },
  queryScoolBatchesBySchool(data) {
    return request.get("/api/school/batches-by-school", data);
  },
  queryScoolDetail(data) {
    return request.get("/api/school/query/detail/" + data);
  },
  querySchoolType() {
    return request.get("/api/school/types");
  },
  querySchoolInstitution() {
    return request.get("/api/school/institution");
  },
  queryAdmissionGuideBySchool(data) {
    return request.get("/api/admission-guide/school/" + data);
  },
  queryAdmissionGuideById(data) {
    return request.get("/api/admission-guide/" + data);
  },
  queryAdmissionGuide(data) {
    return request.get("/api/admission-guide/search", { params: data });
  },
  queryScoreLineSchool(data) {
    return request.post("/api/score-line/school", data);
  },
  queryScoreLineMajor(data) {
    return request.post("/api/score-line/major", data);
  },
  queryAdmissionPlan(data) {
    return request.post("/api/admission-plan/query", data);
  },
  queryPrePlan(params) {
    return request.get(
      `/api/admission-plan/pre-plan-details/${params.schoolId}`,
      { params }
    );
  },
};
