<template>
  <div class="page flex-col" style="padding-top: 20px">
    <div class="group_1 flex-col">
      <div class="group_2 flex-row justify-end">
        <div class="block_8 flex-col">
          <div class="group_28 flex-row justify-between search-row">
            <el-select v-model="province" @change="getConfig">
              <el-option
                v-for="item in $store.state.provinces"
                :key="item"
                :value="item"
                >{{ item }}</el-option
              >
            </el-select>
            <el-select v-model="filterForm.year" @change="yearChange">
              <el-option v-for="item in yearList" :key="item" :value="item">{{
                item
              }}</el-option>
            </el-select>
            <el-select v-model="filterForm.subject" @change="subjectChange">
              <el-option
                v-for="item in subjectList"
                :key="item"
                :value="item"
                >{{ item }}</el-option
              >
            </el-select>
            <el-select
              v-if="batches.length"
              v-model="filterForm.batch"
              @change="batchChange"
            >
              <el-option v-for="item in batches" :key="item" :value="item">{{
                item
              }}</el-option>
            </el-select>
            <SearchInput
              v-model="filterForm.score"
              placeholder="输入您的分数"
              @search="queryBatchLineSegment"
            />
          </div>
          <template>
            <div class="group_29 flex-row">
              <div class="score">
                <span class="text_33">
                  {{ batchLineSegment.highestRank || "-" }}
                  <span
                    v-if="
                      batchLineSegment.lowestRank &&
                      batchLineSegment.highestRank
                    "
                    style="margin: 0 5px"
                    >~</span
                  >
                  {{ batchLineSegment.lowestRank }}</span
                >
                <span class="text_34">位次区间</span>
              </div>
              <div class="score">
                <span class="text_33">{{
                  batchLineSegment.sameScoreCount || "-"
                }}</span>
                <span class="text_34">同分人数</span>
              </div>
              <div class="score">
                <span class="text_33">{{
                  batchLineSegment.suggestedRank || "-"
                }}</span>
                <span class="text_34">建议位次</span>
              </div>
              <div class="box_13 flex-row">
                <div class="box_14 flex-col"></div>
                <div class="text-wrapper_54 flex-col justify-between">
                  <span class="text_39">{{
                    batchLineSegment.competitionLevel || "-"
                  }}</span>
                  <span class="text_40">竞争程度</span>
                </div>
                <el-popover
                  placement="bottom"
                  title=""
                  width="200"
                  trigger="hover"
                >
                  <div>
                    <div
                      style="text-align: center"
                      v-if="batchLineSegment.competitionLevel === '高'"
                    >
                      高：1000&lt;=同分人数
                    </div>
                    <div
                      style="text-align: center"
                      v-else-if="batchLineSegment.competitionLevel === '中'"
                    >
                      中：300&lt;=同分人数 &lt; 1000 - <br />
                    </div>
                    <div style="text-align: center" v-else>
                      低：同分人数&lt;300
                    </div>
                  </div>
                  <img
                    class="thumbnail_4"
                    slot="reference"
                    referrerpolicy="no-referrer"
                    src="https://lanhu-oss.lanhuapp.com/MasterDDSSlicePNG6da684d6304d8d1eef2cc7c41533c8ce.png"
                  />
                </el-popover>
              </div>
            </div>
            <div class="text-wrapper_11">
              <!-- <span
                class="text_41"
                v-html="batchLineSegment.scorePrompt"
              ></span> -->
            </div>
            <div class="box_15 flex-col">
              <div class="block_3 flex-col">
                <!-- <div ref="chartScoreLine" style="height: 120%"></div> -->
                <Echarts :option="chartOption" height="110%" />
              </div>
            </div>
            <div class="group_30 flex-row justify-between">
              <div class="group_11 flex-col"></div>
              <span class="text_61">历年等效分</span>
            </div>
            <div class="container">
              <el-table
                :data="tableList"
                border
                :header-cell-style="{
                  'background-color': '#f5f5f5',
                  height: '5.1rem',
                  color: '#2D2E42',
                  'text-align': 'center',
                }"
                :cell-style="{
                  height: '5.1rem',
                  color: '#2D2E42',
                  'text-align': 'center',
                }"
              >
                <el-table-column label="年份" prop="year"> </el-table-column>
                <el-table-column label="位次区间">
                  <template slot-scope="scope">
                    {{ scope.row.lowestRank }} - {{ scope.row.highestRank }}
                  </template>
                </el-table-column>
                <el-table-column prop="historicalEquivalentScore">
                  <div slot="header">
                    我的等效分
                    <el-tooltip
                      slot="header"
                      class="item"
                      effect="dark"
                      content="历年等效分是根据历年省控线变化幅度等比浮动后，根据等效位次得到的历年等效分数。"
                      placement="top"
                    >
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </div>
                </el-table-column>
                <el-table-column
                  prop="historicalEquivalentRank"
                  label="我的等效位"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="box_21 flex-col">
              <div class="image-text_6 flex-row justify-between">
                <img
                  class="label_3"
                  referrerpolicy="no-referrer"
                  src="@/views/student/icons/school/prompt@2x.png"
                />
                <span class="text-group_4">请注意</span>
              </div>
              <span class="paragraph_2"
                >1、位次查询结果根据2024年教育考试院发布录取位次计算；<br />2、在正式填报时，以教育考试院公布的高考成绩（位次）为准。</span
              >
            </div>
            <div class="group_32 flex-row justify-between">
              <div class="section_9 flex-col"></div>
              <span class="text_81">一分一段表</span>
            </div>
            <el-table
              :data="curveData"
              border
              :header-cell-style="{
                'background-color': '#f5f5f5',
                height: '5.1rem',
                color: '#2D2E42',
                'text-align': 'center',
              }"
              :cell-style="{
                height: '5.1rem',
                color: '#2D2E42',
                'text-align': 'center',
              }"
            >
              <el-table-column label="分数">
                <template slot-scope="scope">
                  {{ scope.row.score }}
                  <span
                    v-if="
                      scope.row.highScore &&
                      scope.row.score !== scope.row.highScore
                    "
                  >
                    - {{ scope.row.highScore }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="位次区间">
                <template slot-scope="scope">
                  {{ scope.row.highestRank }} - {{ scope.row.lowestRank }}
                </template>
              </el-table-column>
              <el-table-column prop="sameScoreCount" label="同分人数">
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import options from "@/utils/options";
import batchline from "@/apis/batchline";
// import province from "@/apis/province";
// import moment from "moment";
import SearchInput from "@/views/common/search";
// import preferences from "@/apis/preferences";
import Echarts from "@/components/Echarts";
import face from "@/views/student/weici/assets/face.png";
export default {
  name: "Weici",
  mixins: [options],
  components: {
    SearchInput,
    Echarts,
  },
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    batch: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chartOption: {},
      subjectRule: "",
      filterForm: {
        province: "",
        year: "",
        subject: "",
        score: "",
        batch: "",
      },
      batchLineSegment: {},
      isBatches: [],
      tableList: [],
      curveData: [],
      yearSubjectBatchMap: {},
      yearList: [],
      subjectList: [],
      batches: [],
      province: "",
    };
  },
  computed: {
    link() {
      return this.$router.resolve({
        path: "/fillIn",
      }).href;
    },
    studentInfo() {
      return JSON.parse(localStorage.getItem("student") || "{}");
    },
    subject() {
      return this.student.subject || this.studentInfo.subject;
    },
  },
  methods: {
    provinceChange() {},
    yearChange() {
      this.filterForm.subject = Object.keys(
        this.yearSubjectBatchMap[this.filterForm.year].subjectBatchMap
      )[0];
      this.subjectList =
        Object.keys(
          this.yearSubjectBatchMap[this.filterForm.year]?.subjectBatchMap
        ) || [];
      this.filterForm.batch =
        this.yearSubjectBatchMap[this.filterForm.year].subjectBatchMap[
          this.filterForm.subject
        ][0];
      this.batches =
        this.yearSubjectBatchMap[this.filterForm.year]?.subjectBatchMap[
          this.filterForm.subject
        ];
      this.queryBatchLineSegment();
    },
    async getConfig() {
      const {
        data: { yearSubjectBatchMap },
      } = await batchline.getFilter(this.province);
      this.yearSubjectBatchMap = yearSubjectBatchMap;
      this.yearList = Object.keys(this.yearSubjectBatchMap).sort(
        (a, b) => b - a
      );
      this.filterForm.year = this.yearList[0];
      this.subjectList =
        Object.keys(
          this.yearSubjectBatchMap[this.filterForm.year]?.subjectBatchMap
        ) || [];
      // 先区分综合
      if (this.subjectList[0] === "综合") {
        this.filterForm.subject = this.subjectList[0];
      } else {
        if (this.subject) {
          // 物理 / 历史
          const s = this.subject.findIndex((item) => item === 4 || item === 7);
          this.filterForm.subject = this.options.firstSubjects.find(
            (item) => item.id === this.subject[s]
          ).value;
          if (this.subject[s] === 4) {
            const list = ["物理", "理科"];
            list.forEach((item) => {
              if (this.subjectList.includes(item)) {
                this.filterForm.subject = item;
              }
            });
          }
          if (this.subject[s] === 7) {
            const list = ["历史", "文科"];
            list.forEach((item) => {
              if (this.subjectList.includes(item)) {
                this.filterForm.subject = item;
              }
            });
          }
        } else {
          this.filterForm.subject = this.subjectList[0];
        }
      }
      this.batches =
        this.yearSubjectBatchMap[this.filterForm.year]?.subjectBatchMap[
          this.filterForm.subject
        ];
      if (this.batches.includes(this.batch)) {
        this.filterForm.batch = this.batch;
      } else {
        this.filterForm.batch = this.batches[0];
      }
      this.filterForm.score = String(
        this.student.totalScore || this.studentInfo.score || ""
      );
      this.queryBatchLineSegment();
    },
    subjectChange() {
      // this.filterForm.batch =
      //   this.yearSubjectBatchMap[this.filterForm.year].subjectBatchMap[
      //     this.filterForm.subject
      //   ][0];
      // this.batches =
      //   this.yearSubjectBatchMap[this.filterForm.year]?.subjectBatchMap[
      //     this.filterForm.subject
      //   ];
      this.queryBatchLineSegment();
    },
    batchChange() {
      this.queryBatchLineSegment();
    },
    handleSubmit() {
      const href = this.$router.resolve({
        path: "/fillIn",
      });
      window.open(href.href, "_blank");
    },
    async queryBatchLineSegment() {
      // 获取折线图和一分一段信息
      const {
        data: { curveData },
      } = await batchline.queryLines({
        province: this.province,
        year: this.filterForm.year,
        batch: this.filterForm.batch || this.studentInfo.batch || undefined,
        subject: this.filterForm.subject,
      });
      this.curveData = curveData;
      let data = {
        // todo
        province: this.province,
        year: this.filterForm.year,
        subject: this.filterForm.subject,
        score: this.filterForm.score || this.studentInfo.score,
        batch: this.filterForm.batch || this.studentInfo.batch,
      };
      localStorage.setItem("rankScore", data.score);
      if (!this.filterForm.subject) {
        return this.$message.warning("请选择科目");
      }
      if (!this.filterForm.score) {
        return this.$message.warning("请输入分数");
      }
      batchline.queryBatchLinesSegment(data).then((res) => {
        if (res.message.code === 200) {
          this.$message.warning(
            "您输入的" +
              this.filterForm.score +
              "分无匹配的位次。换个分数再试试吧！"
          );
        }
        this.batchLineSegment = res.message;
        this.batchLineSegment.scorePrompt =
          this.batchLineSegment.scorePrompt.replace(
            /智能填报/,
            (match) =>
              `<a style="color: rgb(234, 48, 45); cursor: pointer" href="${this.link}"  target="_blank">${match}</a>`
          );
        this.initChartScoreLine();
      });
      batchline.queryHistoricalEquivalentScore(data).then(({ message }) => {
        this.tableList = message.sort((a, b) => b.year - a.year);
      });
    },
    initChartScoreLine() {
      const list = this.curveData;
      let y = list.find((item) => item.score === +this.filterForm.score);
      let coord;
      if (y) {
        coord = [this.filterForm.score, String(y.sameScoreCount + 10)];
      }
      const optionScoreLine = {
        // title: {
        //   text: "分数分布图",
        //   left: "center",
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "rgba(245, 245, 245, 0.8)",
            },
          },
          formatter: (params) => {
            const rank = list.find((item) => item.score === +params[0].name);
            const result = `分数：${params[0].name}分 <br/> 同分人数：${params[0].value}人 <br/> 位次区间：${rank?.lowestRank} - ${rank?.highestRank}`;
            return result;
          },
        },
        xAxis: {
          type: "category",
          // min: 400, // 根据数据调整最小值
          // max: 700, // 根据数据调整最大值
          axisLabel: {
            formatter: "{value}",
            interval: 50, // 根据数据调整间隔
          },
          data: this.curveData.map((item) => item.score),
          axisPointer: {
            type: "line", // 设置为十字准星模式
            label: {
              backgroundColor: "#6a7985", // 标签背景颜色
              color: "#fff", // 文字颜色
              borderWidth: 1, // 边框宽度
              borderColor: "#333", // 边框颜色
              borderRadius: 4, // 圆角
            },
            lineStyle: {
              color: "#FF6B6B", // 准星线条颜色
              width: 2, // 线条宽度
              type: "dashed", // 虚线样式 (可选: 'solid', 'dashed', 'dotted')
            },
          },
        },
        yAxis: {
          type: "value",
          name: "人数",
          // minInterval: 1,
          // interval: 250, // 根据数据调整间隔
          axisLabel: {
            formatter: "{value} 人",
          },
          axisPointer: {
            type: "line",
            label: {
              formatter: "{value} 人", // 自定义数值显示格式
              backgroundColor: "#6a7985",
              color: "#fff",
            },
            lineStyle: {
              color: "#FF6B6B",
              width: 2,
            },
          },
        },
        series: [
          {
            name: "人数",
            type: "line",
            stack: "总量",
            symbol: "none",
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(255, 38, 38, 1)" },
                { offset: 1, color: "rgba(255, 151, 111, 0.68)" },
              ]),
            },
            data: this.curveData.map((item) => item.sameScoreCount),
            smooth: true,
            markPoint: {
              data: [
                // {
                //   name: "自定义长方形及文案",
                //   xAxis: "500", // 与 markLine 的 x 轴位置一致
                //   yAxis: "300", // 假设要在 y 轴值为 150 的位置添加长方形，可根据需求修改
                //   itemStyle: {
                //     normal: {
                //       color: "rgba(0,0,0,0)", // 使标记点背景透明
                //       borderWidth: 0, // 去除边框
                //     },
                //     emphasis: {
                //       borderWidth: 0, // 去除强调状态下的边框
                //     },
                //   },
                //   label: {
                //     show: true,
                //     position: "inside", // 文案在标记点内部
                //     formatter: "本科\n434分", // 自定义文案内容
                //     color: "#E9302D", // 文案颜色
                //     fontSize: 16, // 文案字体大小
                //     // padding: [5, 10, 5, 10], // 文案的内边距，顺序为 [上, 右, 下, 左]
                //     backgroundColor: "rgba(233,48,45,0.1)", // 文案的背景颜色，半透明白色
                //     borderColor: "#E9302D", // 文案的边框颜色
                //     borderWidth: 1, // 文案的边框宽度
                //     borderRadius: 5, // 文案背景的圆角
                //     rich: {
                //       // 富文本样式，可以对不同部分进行不同样式设置
                //       a: {
                //         color: "red",
                //         fontSize: 14,
                //         lineHeight: 20,
                //       },
                //     },
                //     width: 93, // 文案宽度
                //     height: 84, // 文案高度
                //   },
                //   symbol: "rect", // 使用长方形作为标记点的形状
                //   symbolSize: [80, 30], // 长方形的宽和高
                //   symbolOffset: [0, 0], // 标记点的偏移，可根据需要调整
                // },
              ],
            },
            // markLine: {
            //   data: [
            //     {
            //       name: "垂直标记线",
            //       xAxis: "500",
            //       symbol: "none",
            //       lineStyle: {
            //         color: "#E9302D",
            //         type: "dashed",
            //         width: 1,
            //       },
            //       label: {
            //         show: false,
            //       },
            //     },
            //   ],
            // },
          },
        ],
      };
      if (y) {
        optionScoreLine.series[0].markPoint.data.push({
          name: "自定义标记",
          coord, // 指定数据点坐标
          symbol: `image://${face}`, // 自定义图形路径
          symbolSize: 30, // 图形大小
        });
      }
      this.chartOption = optionScoreLine;
      // chartScoreLine.setOption(optionScoreLine);
    },
  },
  async mounted() {
    this.province =
      this.student.province || this.studentInfo.province || "安徽";
    this.getConfig();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/weici/assets/index.scss";
@import "@/assets/scss/common.scss";
.search-row {
  .el-select {
    width: 126px;
    margin-right: 15px;
    ::v-deep input {
      background-color: rgba(245, 245, 245, 1);
    }
  }
}
.el-table {
  width: 1198px;
  margin: 25px auto;
}
.score {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18.75rem;
  height: 100%;
  color: rgb(45, 45, 46);
  background-color: rgb(251, 250, 250);
  justify-content: center;
  .text_33 {
    height: 40px;
    font-size: 2rem;
    font-weight: 500;
  }
  .text_34 {
    color: rgb(113, 114, 116);
  }
}
</style>
