/*
 *
 *  * <!--
 *  *   - Copyright@XLP 2019-Present
 *  *   - @author 肖林朋
 *  *   -->
 *
 *
 */

import Vue from 'vue';
import SvgIcon from '@/assets/svg/component';// svg component

// register globally
Vue.component('svg-icon',SvgIcon);

const req=require.context('../resource',false,/\.svg$/);
const requireAll=requireContext=>requireContext.keys()
                                               .map(requireContext);
requireAll(req);
