import Vue from "vue";
import LoginModal from "@/views/student/Login";

export default function modalHook() {
  // 如果未登录或者未完善信息 则展示该弹框
  const ModalConstructor = Vue.extend(LoginModal);
  const modalInstance = new ModalConstructor({
    propsData: {
      visible: true,
    },
  });
  // 手动挂载弹框组件
  const modal = modalInstance.$mount();
  document.body.appendChild(modal.$el);
  // 监听弹框关闭事件
  modalInstance.$on("update:visible", () => {
    document.body.removeChild(modal.$el);
  });
}
