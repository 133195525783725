class WS {
  constructor(url) {
    this.ws = null;
    this.url = url;
    this.reconnectTimer = null;
    this.heartbeatInterval = 30000; // 30秒心跳
    this.isManualClose = false; // 是否主动关闭
  }

  // 初始化连接
  connect(cb) {
    this.ws = new WebSocket(this.url);
    this.ws.onopen = () => {
      console.log("WebSocket connected");
      cb && cb();
      this.startHeartbeat();
    };

    this.ws.onmessage = (e) => {
      this.handleMessage(e.data);
    };

    this.ws.onerror = (err) => {
      console.error("WebSocket error:", err);
      this.reconnect();
    };

    this.ws.onclose = (e) => {
      if (!this.isManualClose) {
        console.log("WebSocket disconnected, reconnecting...");
        this.reconnect();
      }
    };
  }

  // 消息处理
  handleMessage(data) {
    try {
      const msg = JSON.parse(data);
      switch (msg.type) {
        case "announcement":
          this.onAnnouncement && this.onAnnouncement(msg);
          break;
        case "heartbeat":
          this.resetHeartbeat();
          break;
      }
    } catch (err) {
      console.error("Message parse error:", err);
    }
  }

  // 发送消息
  send(data) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(data));
    }
  }

  // 心跳机制
  startHeartbeat() {
    this.heartbeatTimer = setInterval(() => {
      this.send({ type: "heartbeat" });
    }, this.heartbeatInterval);
  }

  resetHeartbeat() {
    clearInterval(this.heartbeatTimer);
    this.startHeartbeat();
  }

  // 重连机制
  reconnect() {
    clearTimeout(this.reconnectTimer);
    this.reconnectTimer = setTimeout(() => this.connect(), 5000);
  }

  // 关闭连接
  close() {
    this.isManualClose = true;
    clearInterval(this.heartbeatTimer);
    this.ws?.close();
  }
}

export default WS;
