<!--
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-10-10 15:37:29
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-11-12 14:20:24
 * @FilePath: \ok-college-entrance-examination-choices\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
body
{
  overflow: hidden;
}

html, body, #app
{
  height: 100%;
  color: #42b983;
  font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
