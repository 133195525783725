import request from "@/utils/request";
export default {
  // 就业前景
  queryJob(id) {
    return request.get(`/api/majors/${id}/employment-overview`);
  },
  queryMajor(data) {
    return request.get("/api/majors/query", { params: data });
  },
  queryMajorByCategory(data) {
    return request.get("/api/majors/by-category/" + data);
  },
  searchMajor(data) {
    return request.get("/api/majors/search", { params: data });
  },
  queryMajorByLevel(data) {
    return request.get("/api/majors/by-level/" + data);
  },
  queryMajorByType(data, level) {
    return request.get(`/api/majors/by-type/${data}?level=${level}`);
  },
  queryMajorEmploymentOverview(data) {
    return request.get("/api/majors/" + data + "/employment-overview/");
  },
  querySchoolMajor(data) {
    return request.post("/api/majors/querySchools" + data);
  },
  queryMajorDetail(data) {
    return request.get("/api/majors/detail/" + data);
  },
  // 获取就业前景-就业占比
  queryMajorProfessionTypes(data) {
    return request.get("/api/majors/" + data + "/profession-types");
  },
};
