<template>
  <div class="group_new flex-col justify-start">
    <template v-if="!!typeCode || $store.getters.isVip">
      <div v-for="(item, index) in jobList" :key="index" class="job-item">
        <div class="desc">
          <div class="content">
            <div class="title" :title="item.job">{{ item.job }}</div>
            <div
              class="detail"
              :title="`${item.provinceName} | ${item.edu} | ${item.salaryStr}元/月 | 招聘${item.companyHiring}人`"
            >
              {{ item.provinceName }}｜{{ item.edu }}｜{{
                item.salaryStr
              }}元/月｜招聘{{ item.companyHiring }}人
            </div>
          </div>
          <div class="company">
            <div class="name" :title="item.companyName">
              {{ item.companyName }}
            </div>
            <div
              class="detail"
              :title="`${item.companyNature} | ${item.companyScale}`"
            >
              {{ item.companyNature }}
              <template v-if="item.companyScale"
                >｜{{ item.companyScale }}</template
              >
            </div>
          </div>
          <div class="source" :title="item.source">
            来源：<img src="@/views/student/icons/major/source@2x.png" alt="" />
            {{ item.source }}
          </div>
        </div>
        <div class="weal">工作福利：{{ item.welfare || "-" }}</div>
        <div class="weal">专业要求：{{ item.majors || "-" }}</div>
      </div>
    </template>
    <template v-if="!$store.getters.isVip">
      <vip-page></vip-page>
    </template>
  </div>
</template>
<script>
import profession from "@/apis/profession";
export default {
  props: {
    typeCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      constants: {},
      jobList: [],
    };
  },
  methods: {
    queryJyJobList() {
      const code = this.typeCode || this.$route.params.typeCode;
      profession.queryProfessionsJobDetailsByType(code).then((res) => {
        this.jobList = res.message;
      });
    },
  },
  created() {
    this.queryJyJobList();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./assets/zhaopin.scss";
.group_new {
  background: none;
}
.job-item {
  text-align: left;
  margin-top: 22px;
  .desc {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    & > div {
      flex: 1;
      // width: 30%;
    }
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .content {
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #15171d;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .detail {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #32404b;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin: 8px 0 16px 0;
      }
    }
    .company {
      .name {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #15171d;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .detail {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #32404b;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 8px;
      }
    }
    .source {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #15171d;
      font-style: normal;
      text-transform: none;
    }
  }
  .weal {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #717274;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>
