<template>
  <div class="custom-table">
    <!-- 表格主体 -->
    <el-table
      ref="tableRef"
      v-bind="$attrs"
      :data="tableData"
      :row-key="rowKey"
      v-on="$listeners"
      @sort-change="handleSortChange"
      border
      :header-cell-style="{
        'background-color': '#f5f5f5',
        color: '#2D2E42',
        'text-align': 'center',
      }"
      :cell-style="{
        color: '#2D2E42',
        'text-align': 'center',
      }"
    >
      <!-- 默认列渲染 -->
      <template v-for="col in columns">
        <el-table-column v-if="!col.slot" :key="col.prop" v-bind="col" />

        <!-- 支持作用域插槽 -->
        <el-table-column
          v-else
          :key="col.prop"
          :label="col.label"
          :width="col.width"
        >
          <template slot-scope="scope">
            <slot :name="col.slot" :row="scope.row" :$index="scope.$index" />
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!-- 分页组件 -->
    <div v-if="showPagination" class="pagination-container">
      <el-pagination
        :current-page.sync="currentPage"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :layout="paginationLayout"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ZwTable",
  props: {
    // 表格列配置
    columns: {
      type: Array,
      default: () => [],
    },
    // 数据源
    data: {
      type: Array,
      default: () => [],
    },
    // 是否显示分页
    showPagination: {
      type: Boolean,
      default: false,
    },
    // 分页配置
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 1,
        pageSize: 10,
        total: 0,
        pageSizes: [10, 20, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
      }),
    },
    // 行数据唯一标识
    rowKey: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      sortParams: null,
    };
  },
  computed: {
    // 表格显示数据
    tableData() {
      return this.showPagination
        ? this.data.slice(
            (this.currentPage - 1) * this.pageSize,
            this.currentPage * this.pageSize
          )
        : this.data;
    },
    // 分页相关计算属性
    total() {
      return this.pagination.total || this.data.length;
    },
    pageSizes() {
      return this.pagination.pageSizes || [10, 20, 50, 100];
    },
    paginationLayout() {
      return (
        this.pagination.layout || "total, sizes, prev, pager, next, jumper"
      );
    },
  },
  methods: {
    // 分页大小变化
    handleSizeChange(size) {
      this.pageSize = size;
      this.$emit("page-change", {
        page: this.currentPage,
        size,
        sort: this.sortParams,
      });
    },

    // 当前页变化
    handleCurrentChange(page) {
      this.currentPage = page;
      this.$emit("page-change", {
        page,
        size: this.pageSize,
        sort: this.sortParams,
      });
    },

    // 排序变化
    handleSortChange({ column, prop, order }) {
      this.sortParams = { prop, order };
      this.$emit("sort-change", {
        prop,
        order,
        page: this.currentPage,
        size: this.pageSize,
      });
    },

    // 重置分页
    resetPagination() {
      this.currentPage = 1;
      this.pageSize = this.pagination.pageSize || 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-table {
  margin: 15px 0;
  //   position: relative;

  //   ::v-deep .el-table {
  //     margin-top: 15px;

  //     // 表头样式
  //     th {
  //       background-color: #f5f7fa;
  //       color: #606266;
  //     }

  //     // 斑马纹样式
  //     &.el-table--striped .el-table__body tr.el-table__row--striped td {
  //       background: #fafafa;
  //     }
  //   }

  .pagination-container {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
