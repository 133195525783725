import { ACCESS_TOKEN } from "@/store/mutation-types";
import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import JSONbig from "json-bigint";
import { getToken } from "./auth";

//     var urlPath=window.document.location.href;
//     let urlSplit=urlPath.split(new RegExp(`[/]+`));
//     var url= urlSplit[0]+"//"+urlSplit[1];
axios.defaults.baseURL = "/";
axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
import JSONBigInt from "json-bigint";

// const excludePathMap=[
//   {path:'report/instance',method:'get'},
//   {path:'plot/dto',method:'get'},
//   {path:'plot/vo/page/enhance',method:'post'},
//   {path:'application/vo/page/enhance',method:'post'},
// ];
let msgInstance = null;
// 存储最新的 AbortController
const searchControllers = new Map();
// 生成搜索请求唯一标识
const generateSearchId = (config) => {
  return `SEARCH_${config.url}}`;
};
const request = axios.create({
  timeout: 120000,
  transformResponse: [
    function (data) {
      const JSONbigToString = JSONbig({ storeAsString: true });
      return JSONbigToString.parse(data);
    },
  ],
});
request.interceptors.request.use(
  (config) => {
    // 仅对搜索类请求启用取消逻辑
    if (config.isSearchRequest) {
      const searchId = generateSearchId(config);

      // 取消前序请求
      if (searchControllers.has(searchId)) {
        searchControllers.get(searchId).abort();
      }

      // 创建新控制器
      const controller = new AbortController();
      searchControllers.set(searchId, controller);

      // 绑定到请求配置
      config.signal = controller.signal;
    }
    // 加密
    // if(window.licardo.cipherGo&&config.data&&Object.prototype.toString.call(config.data)!=='[object FormData]')
    // {
    //   config.data=s4.encryptData_ECB(JSON.stringify(config.data));
    // }
    //仅对实例整合起效
    // let token=getCookieData("token");
    // b端和c端token不同
    let token = localStorage.getItem(ACCESS_TOKEN) || getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    let res;
    //解密
    // if(window.licardo.cipherBack)
    // {
    //   if(excludePathAndMethod(response,excludePathMap))
    //   {
    //     res=response.data;
    //   }
    //   else
    //   {
    //     try
    //     {
    //       //let st=new Date().getTime();
    //       res=JSON.parse(s4.decryptData_ECB(response.data));
    //       //let et=new Date().getTime();
    //       //console.log('解析时间差',et-st);
    //     }
    //     catch(e)
    //     {
    //       //let st=new Date().getTime();
    //       res=eval('('+s4.decryptData_ECB(response.data)+')');
    //       //let et=new Date().getTime();
    //       //console.log('eval时间差',et-st);
    //     }
    //   }
    // }
    // else
    // {
    // 清理完成的搜索请求
    msgInstance?.close();
    if (response.config.isSearchRequest) {
      const searchId = generateSearchId(response.config);
      searchControllers.delete(searchId);
    }
    res = response.data;
    if (response.headers.authorization) {
      res["Authorization"] = response.headers.authorization;
    }
    if (Object.prototype.hasOwnProperty.call(res, "message")) {
      //判断返回数据是否为json字符串
      if (
        typeof res.message === "string" &&
        (res.message.indexOf("{") > -1 || res.message.indexOf("[") > -1)
      ) {
        try {
          res.message = JSONBigInt.parse(res.message);
        } catch (e) {
          res.message = JSONBigInt.parse(
            res.message.replace(/(\d+):/g, '"$1":')
          );
        }
        console.log(response.config.url + ":res", res);
      }
    }

    if (res.code && res.code !== 200) {
      msgInstance = Message({
        message: res.message || "Error",
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return {
        ...res,
        // message字段是为了兼容前端之前的逻辑处理
        message:
          res.message || res.message == "" ? res.message : res.data || res,
      };
    }
  },
  (error) => {
    // let errorData=JSON.parse(JSON.stringify(error));
    // console.log(222,errorData);
    // console.log(222,error.response.data.message);
    // let errorStatus=error.status;
    // 取消请求的特殊处理
    if (axios.isCancel(error)) {
      return Promise.reject({ isCancelled: true });
    }
    if (
      error.status === 500 &&
      (error.response.data.message === "凭证已过期，请重新登陆" ||
        error.response.data.message === "认证失败，请重新登陆" ||
        error.response.data.message === "Token已过期，请重新登录")
    ) {
      store.dispatch("logout").then(() => {
        this.$message({
          message: "凭证已过期，请重新登录",
          type: "error",
          duration: 5 * 1000,
        });
      });
    }
    // else if(errorStatus===307)
    // {
    //   //针对实例的,有SDDToken的不会再进行跳转
    //   // let integration=getCookieData("integration");
    //   // let token=getCookieData("token");
    //   let integration=localStorage.getItem("integration");
    //   let token=localStorage.getItem("token");
    //   if(integration&&token&&token!=='tempOadToken')
    //   {
    //     integration=JSON.parse(integration);
    //     if(integration.redirectUrl)
    //     {
    //       window.location.href=integration.redirectUrl;
    //     }
    //   }
    // }
    // else if(errorStatus===502)
    // {
    //   window.alert("调用服务不可用，或者网关出错");
    // }
    msgInstance = Message({
      message: error.response.data.message || "Error",
      type: "error",
      duration: 5 * 1000,
    });
    if (axios.isCancel(error)) {
      console.log("请求被取消:", error.message);
    } else {
      console.error("请求出错:", error);
    }
    return Promise.reject(error);
  }
);

// function excludePathAndMethod(response,excludeMap)
// {
//   let flag=null;
//   excludeMap.forEach(item=>
//                      {
//                        let filter=response.config.url.indexOf(item.path)> -1&&response.config.method===item.method;
//                        if(flag===null)
//                        {
//                          flag=filter;
//                        }
//                        else
//                        {
//                          flag=flag||filter;
//                        }
//                      });
//   return flag;
// }

export default request;
