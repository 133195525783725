<template>
  <el-drawer
    :size="1300"
    :withHeader="false"
    :visible="drawer"
    direction="rtl"
    @close="handleClose"
    :destroy-on-close="true"
    append-to-body
  >
    <div>
      <header>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-submenu index="2">
            <template slot="title"
              ><i class="el-icon-tickets"></i>信息库</template
            >
            <el-menu-item index="school">院校库</el-menu-item>
            <el-menu-item index="major">专业库</el-menu-item>
            <el-menu-item index="profession">职业库</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title"><i class="el-icon-medal"></i>榜单</template>
            <el-menu-item index="rank">大学排名</el-menu-item>
            <!-- <el-menu-item index="majorRank">学科排名</el-menu-item> -->
          </el-submenu>
          <el-submenu index="5">
            <template slot="title"><i class="el-icon-more"></i>更多</template>
            <el-menu-item index="ScoreLine">批次线</el-menu-item>
            <el-menu-item index="RankLine">一分一段</el-menu-item>
            <el-menu-item index="book" v-if="origin === 'b'"
              >原始书籍</el-menu-item
            >
          </el-submenu>
        </el-menu>
        <i class="el-icon-close" @click="handleClose"></i>
      </header>
      <main>
        <transition name="fade-transform" mode="out-in">
          <universityRankings
            v-if="activeIndex === 'rank'"
            @getDetail="getDetail"
          />
          <collegeLibrary
            v-if="activeIndex === 'school'"
            @getDetail="getDetail"
          />
          <SchoolDetail
            ref="schoolDetail"
            v-if="activeIndex === 'schoolDetail'"
            :student="student"
            :schoolId="sid"
            @getDetail="getMajorDetail"
            @getSchoolDetail="getDetail"
          />
          <BookPage v-if="activeIndex === 'book'" />
          <Major v-if="activeIndex === 'major'" @getDetail="getMajorDetail" />
          <MajorDetail
            ref="majorDetail"
            v-if="activeIndex === 'majorDetail'"
            :majorCode="mCode"
            :majorBatch="mBatch"
            @getDetail="getDetail"
          />
          <Profession
            @professionDetail="getProfessionDetail"
            v-if="activeIndex === 'profession'"
          />
          <ProfessionDetail
            v-if="activeIndex === 'professionDetail'"
            :code="professionCode"
            @getDetail="getDetail"
          />
          <ScoreLine v-if="activeIndex === 'ScoreLine'" :student="student" />
          <RankLine
            v-if="activeIndex === 'RankLine'"
            :student="student"
            :batch="batch"
          />
        </transition>
      </main>
    </div>
  </el-drawer>
</template>

<script>
import collegeLibrary from "./components/collegeLibrary.vue";
import universityRankings from "./components/universityRankings.vue";
import SchoolDetail from "./SchoolDetail";
import BookPage from "./Book/BookPage.vue";
import Major from "./Major";
import MajorDetail from "./Major/MajorDetail";
import Profession from "./Profession";
import ProfessionDetail from "./Profession/ProfessionDetail";
import ScoreLine from "./components/scoreLine.vue";
import RankLine from "./components/rankLine.vue";
export default {
  name: "DrawerMenu",
  components: {
    collegeLibrary,
    universityRankings,
    SchoolDetail,
    BookPage,
    Major,
    MajorDetail,
    Profession,
    ProfessionDetail,
    ScoreLine,
    RankLine,
  },
  props: {
    curMenu: {
      type: String,
      default: "school",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    schoolId: {
      type: String,
      default: "",
    },
    student: {
      type: Object,
      default: () => {
        return {};
      },
    },
    majorCode: {
      type: String,
      default: "",
    },
    majorBatch: {
      type: String,
      default: "",
    },
    batch: {
      type: String,
      default: "",
    },
    origin: {
      type: String,
      default: "b",
    },
  },
  data() {
    return {
      activeIndex: "",
      sid: "",
      professionCode: "",
      mCode: "",
      mBatch: "",
    };
  },
  watch: {
    curMenu: {
      handler(val) {
        this.activeIndex = val;
      },
      immediate: true,
    },
    schoolId() {
      this.sid = this.schoolId;
    },
    majorCode() {
      this.mCode = this.majorCode;
      this.mBatch = this.batchMap[this.majorBatch];
    },
  },
  computed: {
    componentMap() {
      return {
        school: collegeLibrary,
        rank: universityRankings,
        schoolDetail: SchoolDetail,
        book: BookPage,
        major: Major,
      };
    },
    drawer: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    batchMap() {
      return {
        本科: "本科",
        专科: "专科",
        高职本科: "职高",
        "1段": "本科",
        "2段": "专科",
      };
    },
  },
  methods: {
    getProfessionDetail(id) {
      this.activeIndex = "professionDetail";
      this.professionCode = id;
    },
    getDetail(id) {
      this.sid = String(id);
      this.activeIndex = "schoolDetail";
    },
    getMajorDetail(code, batch) {
      this.mCode = code;
      this.mBatch = batch;
      this.activeIndex = "majorDetail";
    },
    handleClose() {
      this.$emit("update:visible", false);
      this.$emit("update:curMenu", this.activeIndex);
    },
    handleSelect(val) {
      this.activeIndex = val;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
  & > ul {
    flex: 1;
  }
  & > i {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 20px;
  }
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
