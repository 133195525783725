<template>
  <div class="page flex-col" style="padding-top: 20px">
    <div class="section_1 flex-col">
      <div class="block_1 flex-row justify-end">
        <div class="section_6 flex-col">
          <div style="width: 1200px; margin: 0 auto">
            <div
              class="block_6 flex-row justify-between"
              style="cursor: pointer"
              @click="visibleTooltip = !visibleTooltip"
            >
              <span class="text_33">
                <el-tooltip
                  placement="bottom"
                  effect="light"
                  :visible-arrow="false"
                  popper-class="tooltip-header"
                  manual
                  :value="visibleTooltip"
                >
                  <el-card
                    slot="content"
                    body-style="display: grid;grid-template-columns: repeat(4,1fr); border-radius: 10px;width:400px;"
                  >
                    <span
                      v-for="(item, index) in options.provinces"
                      :key="index + ''"
                      @click="
                        () => {
                          location = item;
                          visibleTooltip = !visibleTooltip;
                        }
                      "
                      class="tooltip-selector"
                      >{{ item }}
                    </span>
                  </el-card>
                  <span class="text_56">{{ location }}</span>
                </el-tooltip>
              </span>
              <img
                class="thumbnail_4"
                referrerpolicy="no-referrer"
                src="https://lanhu-oss.lanhuapp.com/MasterDDSSlicePNGd0f53358a1af458bb85d4516b1f0f8b7.png"
              />
            </div>
          </div>
          <div v-for="item in batchLines" :key="item.title">
            <BatchTable :year="item.title" :table-data="item.tableData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import batchline from "@/apis/batchline";
// import c from "@/mixins/c";
import options from "@/utils/options";
import BatchTable from "@/views/student/pici/batchTable.vue";
export default {
  name: "Pici",
  mixins: [options],
  components: {
    BatchTable,
  },
  props: {
    student: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      location: "",
      batchLines: {},
      visibleTooltip: false,
    };
  },
  watch: {
    location() {
      this.queryBatchLines();
    },
    // student() {
    //   this.location = this.student.province;
    // },
  },
  methods: {
    queryBatchLines() {
      batchline
        .queryBatchLinesByProvince({ province: this.location })
        .then((res) => {
          console.log(res);
          // this.batchLines = res.message;
          this.batchLines = Object.keys(res.data).reduce((prev, item) => {
            prev.push({
              title: item,
              tableData: res.data[item],
            });
            return prev;
          }, []);
          this.batchLines.sort((a, b) => {
            return b.title - a.title;
          });
        });
    },
  },
  mounted() {
    const student = JSON.parse(localStorage.getItem("student") || "{}");
    this.location = this.student.province || student.province || "安徽";
    this.queryBatchLines();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/pici/assets/index.scss";
@import "@/assets/scss/common.scss";
.page {
  width: auto !important;
  height: auto !important;
  .section_1 {
    width: auto !important;
    height: auto !important;
    .block_1 {
      width: auto !important;
      height: auto !important;
      .section_6 {
        width: auto !important;
        height: auto !important;
      }
    }
  }
}
</style>
