var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page flex-col"},[_c('div',{staticClass:"group_1 flex-col"},[_c('div',{staticClass:"box_42 flex-row justify-between"},[_c('div',{staticClass:"box_5 flex-col"},[_c('div',{staticClass:"box_6 flex-row"},[_c('div',{staticClass:"text-wrapper_2 flex-col",style:(_vm.batch == '本科'
                ? { 'background-color': 'rgba(245, 245, 245, 1)' }
                : {}),on:{"click":function($event){return _vm.batchClick('本科')}}},[_c('span',{class:_vm.batch == '本科' ? 'text_18' : 'text_19'},[_vm._v("本科专业")])]),_c('div',{staticClass:"text-wrapper_2 flex-col",style:(_vm.batch == '专科'
                ? { 'background-color': 'rgba(245, 245, 245, 1)' }
                : {}),on:{"click":function($event){return _vm.batchClick('专科')}}},[_c('span',{class:_vm.batch == '专科' ? 'text_18' : 'text_19'},[_vm._v("专科专业")])]),_c('div',{staticClass:"text-wrapper_2 flex-col",style:(_vm.batch == '职高'
                ? { 'background-color': 'rgba(245, 245, 245, 1)' }
                : {}),on:{"click":function($event){return _vm.batchClick('职高')}}},[_c('span',{class:_vm.batch == '职高' ? 'text_18' : 'text_19'},[_vm._v("高职本科")])])]),_c('div',{staticClass:"menu-wrap"},[_c('el-tree',{ref:"tree",attrs:{"data":_vm.treeMenuData,"node-key":"code","highlight-current":"","default-expanded-keys":_vm.defaultExpandedKeys,"current-node-key":_vm.currentNodeKey,"accordion":"","expand-on-click-node":false},on:{"node-click":_vm.handleNodeClick}})],1)]),_c('div',{staticClass:"section_8 flex-col"},[_c('div',{staticClass:"text-wrapper_3 flex-col"},[_c('span',{staticClass:"text_44"},[_vm._v(_vm._s(_vm.name))]),_c('span',{staticClass:"text_45"},[_vm._v("国标代码"+_vm._s(_vm.code || "-")+"(不可用于填报)")])]),_c('div',{staticClass:"block_2 flex-col"},[(_vm.isSecondMajor)?[_c('div',{staticClass:"tabs"},_vm._l((_vm.major2List),function(item){return _c('span',{key:item,class:{ active: _vm.tab === item },on:{"click":function($event){_vm.tab = item}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('main',[_c(_vm.tab === '专业概况' ? 'Professional' : 'Direction',{tag:"component",attrs:{"major-type":_vm.name,"batch":_vm.batch}})],1)]:[_c('div',{staticClass:"tabs"},_vm._l((_vm.major3List),function(item){return _c('span',{key:item,class:{ active: _vm.activeTab === item },on:{"click":function($event){_vm.activeTab = item}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('main',[_c(_vm.activeTab === '专业概况'
                    ? 'Detail'
                    : _vm.activeTab === '就业前景'
                    ? 'Job'
                    : 'School',_vm._g(_vm._b({tag:"component",attrs:{"id":_vm.majorId}},'component',_vm.$attrs,false),_vm.$listeners))],1)]],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }