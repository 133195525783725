<template>
  <div class="login-dialog" v-if="visible">
    <!-- 注册/登录 -->
    <div class="login-container" v-if="!isLogin">
      <div class="close" @click="close"></div>
      <transition name="el-fade-in-linear">
        <div>
          <div class="logo"></div>
          <el-form
            :model="loginForm"
            label-width="0"
            :rules="rules"
            ref="loginForm"
            class="login-form"
          >
            <el-form-item prop="phone">
              <el-input
                class="login-input"
                placeholder="请输入手机号"
                v-model="loginForm.phone"
              >
                <i slot="prefix" class="phone"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="code-item" prop="code">
              <el-input
                class="login-input"
                placeholder="请输入验证码"
                v-model="loginForm.code"
                style="flex: 1"
              >
                <i slot="prefix" class="code"></i>
              </el-input>
              <div
                class="default-btn code-btn"
                :class="{ disabled: countdown }"
                @click="sendCode"
              >
                {{ countdown ? `发送中${countdown}` : "获取验证码" }}
              </div>
            </el-form-item>
            <el-form-item prop="agree" class="agree">
              <el-checkbox v-model="loginForm.agree"></el-checkbox>
              <span class="check">勾选用户协议</span>
              <a
                href="http://47.98.240.140/h5/UserAgreement.html"
                target="_blank"
                class="protocol"
                >《用户服务协议》</a
              >
              <a
                href="http://47.98.240.140/h5/PrivacyPolicy.html"
                target="_blank"
                class="protocol"
                >《隐私政策》</a
              >
            </el-form-item>
            <el-form-item>
              <div
                class="default-btn"
                style="padding: 10px 0"
                @click="handleLogin"
              >
                注册 / 登录
              </div>
              <div class="tip">未注册手机号验证后自动创建账号</div>
            </el-form-item>
          </el-form>
        </div>
      </transition>
    </div>
    <!-- 完善信息 -->
    <div
      class="login-container"
      v-if="isLogin && (!userInfo.province || !userInfo.year)"
    >
      <transition name="el-fade-in-linear">
        <template v-if="isLogin && (!userInfo.province || !userInfo.year)">
          <div>
            <p class="title" style="text-align: center">完善您的信息</p>
            <el-form
              :model="signForm"
              :rules="signRules"
              ref="signForm"
              label-width="100px"
              class="sign-form"
            >
              <el-form-item label="考生姓名：" prop="name" required>
                <el-input
                  placeholder="请输入姓名"
                  v-model="signForm.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="高考省份：" prop="province">
                <el-select
                  filterable
                  v-model="signForm.province"
                  placeholder="请选择省份"
                  :disabled="disabledForm.province"
                >
                  <el-option
                    v-for="(item, index) in options.provinces"
                    :key="index + ''"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="高考年份："
                prop="year"
                placeholder="请选择年份"
              >
                <el-select
                  v-model="signForm.year"
                  :disabled="disabledForm.year"
                >
                  <el-option
                    v-for="(item, index) in options.gaokaoYears"
                    :label="item"
                    :value="item"
                    :key="index + ''"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="tip-info">以上信息一旦提交不能更改</div>
            <div
              class="default-btn"
              style="width: 80%; padding: 10px 0; margin-left: 49px"
              @click="handleSign"
            >
              提交
            </div>
          </div>
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import selector from "@/utils/options";
import options from "@/utils/options";
import login from "@/apis/login";
import { mapState, mapGetters } from "vuex";
// import student from "@/apis/student";
import studentdetail from "@/apis/studentdetail";
export default {
  name: "login",
  mixins: [selector, options],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateAgree = (rule, value, callback) => {
      if (!value) {
        callback(new Error("未勾选用户服务协议"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("姓名不能为空！"));
      } else if (!/^[a-zA-Z0-9\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error("姓名格式错误，不允许出现特殊字符！"));
      } else {
        callback();
      }
    };
    return {
      countdown: 0,
      timer: null,
      loginForm: {
        phone: "",
        code: "",
        agree: false,
      },
      rules: {
        phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        code: [{ required: true, trigger: "blur", message: "请输入验证码" }],
        agree: [{ validator: validateAgree, trigger: "change" }],
      },
      signRules: {
        name: [{ validator: validateName, trigger: "blur" }],
        province: [
          { required: true, trigger: "blur", message: "请选择高考省份" },
        ],
        year: [{ required: true, trigger: "blur", message: "请选择高考年份" }],
      },
      loading: false,
      show: true,
      signForm: {
        name: "",
        province: "",
        year: "",
      },
      disabledForm: {
        name: false,
        province: false,
        year: false,
      },
    };
  },
  computed: {
    ...mapState({
      deviceUUID: (state) => state.deviceUUID,
    }),
    ...mapGetters(["isLogin", "userInfo"]),
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    sendCode() {
      this.$refs.loginForm.validateField("phone", (valid) => {
        if (!valid) {
          if (this.countdown) {
            return;
          }
          this.countdown = 60;
          this.timer = setInterval(() => {
            this.countdown--;
            if (this.countdown === 0) {
              clearInterval(this.timer);
            }
          }, 1000);
          // 发送验证码
          login.sms(this.loginForm.phone).then(() => {
            // this.loginForm.code = message;
            this.$message.success({
              message: '您的验证码已发送至手机，5分钟内有效，请及时查收并完成验证！',
              duration: 5000
            });
          });
        }
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const loginform = {
          tel: this.loginForm.phone,
          code: this.loginForm.code,
          loginFrom: 4,
          uid: this.deviceUUID,
        };
        this.$store
          .dispatch("user/login", loginform)
          .then((data) => {
            if (data.signUp || !data.province || !data.year) {
              // 已经创建过客单做信息回填
              const { name, province, year } = data;
              this.signForm = {
                name,
                province,
                year,
              };
              if (name) {
                this.disabledForm.name = true;
              }
              if (province) {
                this.disabledForm.province = true;
              }
              if (year) {
                this.disabledForm.year = true;
              }
            } else {
              this.$store.dispatch("user/getInfo").then(() => {
                this.$message.success("登录成功");
                this.$emit("loginSuccess");
                location.reload();
              });
            }
          })
          .catch(() => {
            this.countdown = 0;
            clearInterval(this.timer);
          });
      });
    },
    async handleSign() {
      this.$refs.signForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const params = {
          ...this.signForm,
          tel: this.loginForm.phone,
          id: this.$store.getters.userInfo.roleId,
        };
        await studentdetail.updateStudentDetailC(params);
        this.$store.dispatch("user/getInfo").then(() => {
          this.$message.success("登录成功");
          this.$emit("loginSuccess");
          location.reload();
        });
      });
    },
  },
  mounted() {
    try {
      this.loginForm.phone = JSON.parse(localStorage.getItem("loginTel"));
    } catch (error) {
      this.loginForm.phone = "";
    }
    if (this.isLogin) {
      const { username, province, year } = this.userInfo;
      this.signForm.name = username || "";
      this.signForm.province = province || "";
      this.signForm.year = year || "";
      if (username) {
        this.disabledForm.name = true;
      }
      if (province) {
        this.disabledForm.province = true;
      }
      if (year) {
        this.disabledForm.year = true;
      }
    }
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.tip-info {
  text-align: center;
  color: var(--main-thema-color);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}
.login-dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
  background-color: #00000080;
  cursor: zoom-out;
  z-index: 1051;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}
.login-container {
  width: 648px;
  height: 455px;
  background: url("../../../assets/png/login-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .logo {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    background: url("../../../assets/png/zw-logo.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 28px;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
    height: 30px;
    background: url("../wellcome/assets/img/close.png") no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .login-form {
    margin-top: 20px;
    .login-input {
      height: 45px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #e0e2e3;
      overflow: hidden;
      padding-left: 30px;
      box-sizing: border-box;
      ::v-deep input {
        border: none;
      }
      ::v-deep .el-input__inner {
        width: 100%;
        height: 100%;
        padding: 0 24px;
        box-sizing: border-box;
        display: flex;
        font-size: 16px;
        font-weight: 400;
      }
      ::v-deep .el-input__prefix {
        display: flex;
        align-items: center;
        margin: 0 10px;
        i {
          display: inline-block;
          width: 22px;
          height: 22px;
          background-size: contain;
          background-image: url("../wellcome/assets/img/phone.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .phone {
          background-image: url("../wellcome/assets/img/phone.png");
        }
        .code {
          background-image: url("../wellcome/assets/img/code.png");
        }
      }
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .code-item {
      ::v-deep .el-form-item__content {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .agree {
    text-align: left;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    .check {
      margin: 0 7px;
    }
    .protocol {
      font-weight: 700;
      color: rgb(0, 0, 0);
    }
  }
  .default-btn {
    display: inline-block;
    background-color: #e32626;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    // padding: 24px 0;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    font-weight: 400;
    &.disabled {
      background: #f5f5f5;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #d5d7dd;
      cursor: not-allowed;
    }
  }
  .code-btn {
    width: 180px;
    line-height: 44px;
    margin-left: 24px;
    background-color: #fff;
    color: #3d3d3d;
    border: 1px solid #e0e2e3;
    font-size: 16px;
  }
  .tip {
    margin-top: 24px;
    color: #979797;
    font-size: 16px;
  }
  .title {
    margin: 32px 0 36px;
    font-weight: 500;
    color: #3d3d3d;
    font-size: 24px;
  }
  .sign-form {
    div {
      text-align: left;
    }
    .el-form-item {
      margin-bottom: 24px;
      .el-form-item__label {
        font-weight: 400;
        color: #717274;
        font-size: 16px;
      }
      .el-select {
        width: 100%;
      }
      ::v-deep .el-input__inner {
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        padding: 0 15px;
      }
    }
  }
}
</style>
