<!--
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-10-13 19:31:13
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-12-26 23:40:51
 * @FilePath: \ok-college-entrance-examination-choices\src\components\layout\menu\Index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="layout-menu">
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      :router="true"
      :collapse="isCollapse"
      @open="handleOpen"
      @close="handleClose"
    >
      <template v-for="(item, index) in menuList">
        <el-submenu v-if="item.children" :index="index + ''" :key="index">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
            <span
              style="
                color: #fff;
                background-color: rgb(255, 0, 0);
                width: 22px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                border-radius: 50%;
                position: relative;
                margin-left: -4px;
                margin-top: -19px;
                display: inline-block;
              "
              v-if="item.name === '我的学员' && myStudentPin > 0"
              >{{ myStudentPin }}</span
            >
          </template>
          <el-menu-item
            v-for="(child, index2) in item.children"
            :key="index2"
            :index="index + '_' + index2"
            :route="child.path"
            >{{ child.name }}</el-menu-item
          >
        </el-submenu>

        <el-menu-item
          v-if="!item.children"
          :index="index + ''"
          :route="item.path"
          :key="index"
        >
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import planner from "@/apis/planner";
import { getProtocolAndIpAndPort } from "@/utils/httpUtils";
export default {
  components: {},
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuList: [
        {
          name: "学员管理",
          icon: "el-icon-user",
          children: [
            { name: "学员列表", path: "/departStudentList" },
            { name: "学员详情", path: "/departStudentDetail" },
          ],
          role: [2, 3],
        },
        {
          name: "我的学员",
          icon: "el-icon-user",
          children: [
            { name: "我的学员列表", path: "/myStudentList" },
            { name: "我的学员详情", path: "/myStudentDetail" },
          ],
          role: [1, 2, 3],
        },
        {
          name: "规划师管理",
          icon: "el-icon-user",
          children: [{ name: "规划师列表", path: "/masterList" }],
          role: [3],
        },
        {
          name: "网站运营",
          icon: "el-icon-setting",
          path: "/websiteOpeartion",
          role: [3],
        },
        {
          name: "核心数据",
          icon: "el-icon-timer",
          path: "/coreData",
          role: [3],
        },
        // { name: "测试", icon: "el-icon-timer", path: "/test", role: [1] },
      ],
      myStudentPin: 0,
    };
  },
  watch: {
    $route(to, from) {
      console.log(to);
    },
  },
  computed: {},
  methods: {
    async queryNyStudentPin() {
      while (true) {
        await new Promise((resolve) => {
          setTimeout(() => {
            // console.log("queryNyStudentPin", res);
            planner.queryPlannerPoll().then((res) => {
              this.myStudentPin = res.data;
              resolve();
            });
          }, 30 * 1000);
        });
      }
    },
    filterMenu() {
      return new Promise((resolve) => {
        let menus = this.menuList.filter((item) =>
          JSON.parse(localStorage.getItem("autherization")).roleTypes.some(
            (role) => item.role.includes(role)
          )
        );
        resolve(menus);
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("beforeRouteEnter", from);

    next();
    // next((vm) => {
    //   vm.previousRoute = from.path;
    // });
  },
  created() {
    this.filterMenu().then((res) => {
      this.menuList = res;
      // debugger
      // console.log(getProtocolAndIpAndPort())
      // console.log(this.menuList[0].path?this.menuList[0].path:this.menuList[0].children[0].path)
      // window.location.href=getProtocolAndIpAndPort().concat( this.menuList[0].path?this.menuList[0].path:this.menuList[0].children[0].path)
      // this.$router.push(
      //   this.menuList[0].path
      //     ? this.menuList[0].path
      //     : this.menuList[0].children[0].path
      // );
      // this.$router.push('/departStudentList')
    });
  },
  mounted() {
    planner.queryPlannerPoll().then((res) => {
      this.myStudentPin = res.data;
    });
    this.queryNyStudentPin();
  },
};
</script>
<style lang="scss" scoped>
.layout-menu {
  width: 100%;
  height: 100%;
  border-right: 1px solid #e6e6e6;
  background-color: transparent !important;
}
.el-menu-vertical-demo {
  text-align: left;
}
</style>
