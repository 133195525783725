<template>
  <div class="group_7 flex-col">
    <div class="box_6 flex-col"></div>
    <div class="box_7 flex-row">
      <div class="box_8 flex-col"></div>
      <span class="text_48">相关职业</span>
      <span class="text_49"
        >（{{
          professionList.reduce((total, Model) => {
            return (
              total +
              Model.professionTypes.reduce((tot, type) => {
                return type.professionVos.length + tot;
              }, 0)
            );
          }, 0)
        }}个）</span
      >
    </div>
    <div
      v-for="(Model, index) in professionList"
      :key="index"
      style="margin: 10px 0"
    >
      <span class="text_50" @click="goDetail(professionType)">{{
        Model.categoryName
      }}</span>
      <div class="flex-row flex-wrap">
        <div
          class="text-wrapper_6 flex-col"
          v-for="(professionVo, index3) in Model.professionTypes"
          :key="index3"
        >
          <span class="text_51" @click="goDetail3(professionVo)">{{
            professionVo.typeName
          }}</span>
        </div>
      </div>
      <!-- <template v-for="(professionType, index2) in Model.professionTypes">
        <span
          class="text_50"
          :key="index + '_' + index2 + '_1'"
          @click="goDetail(professionType)"
          >{{ professionType.typeName }}</span
        >
        <div class="flex-row flex-wrap" :key="index + '_' + index2 + '_1'">
          <div
            class="text-wrapper_6 flex-col"
            :key="index + '_' + index2 + '_' + index3"
            v-for="(professionVo, index3) in professionType.professionVos"
          >
            <span class="text_51" @click="goDetail3(professionVo)">{{
              professionVo.professionName
            }}</span>
          </div>
        </div>
      </template> -->
    </div>
  </div>
</template>
<script>
import professions from "@/apis/professions";
export default {
  data() {
    return {
      professionList: [],
    };
  },
  props: {
    majorType: {
      type: String,
      default: "",
    },
  },
  methods: {
    goDetail({ typeCode }) {
      // const routeData = this.$router.resolve({
      //   path: `/chazhiyeoverview/${typeCode}`,
      // });
      // window.open(routeData.href, "_blank");
    },
    goDetail3(professionVo) {
      // const routeData = this.$router.resolve({
      //   path: `/chazhiyedetail/${professionVo.professionCode}`,
      // });
      // window.open(routeData.href, "_blank");
    },
    queryProfessions() {
      professions.queryProfessionsByMajorType(this.majorType).then((res) => {
        this.professionList = res.message;
        console.log(this.professionList, 22);
      });
    },
  },
  watch: {
    majorType: {
      handler: function (newVal, oldVal) {
        this.queryProfessions();
      },
      immediate: true,
    },
  },
  mounted() {
    this.queryProfessions();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhuanye/assets/zhuanyetypezhiye.scss";
.text_50 {
  display: block;
}
</style>
