<template>
  <div ref="chart" :style="{ width: width, height: height }"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "ECharts",
  props: {
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "400px",
    },
    // ECharts 配置
    option: {
      type: Object,
      required: true,
    },
    // 是否显示加载动画
    loading: {
      type: Boolean,
      default: false,
    },
    // 主题
    theme: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      chartInstance: null, // ECharts 实例
    };
  },
  watch: {
    // 监听 option 变化，更新图表
    option: {
      handler(newOption) {
        if (this.chartInstance) {
          this.chartInstance.setOption(newOption);
        }
      },
      deep: true,
    },
    // 监听 loading 变化，显示/隐藏加载动画
    loading(newLoading) {
      if (this.chartInstance) {
        if (newLoading) {
          this.chartInstance.showLoading();
        } else {
          this.chartInstance.hideLoading();
        }
      }
    },
  },
  mounted() {
    this.initChart(); // 初始化图表
    window.addEventListener("resize", this.resizeChart); // 监听窗口变化
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose(); // 销毁图表实例
    }
    window.removeEventListener("resize", this.resizeChart); // 移除窗口变化监听
  },
  methods: {
    // 初始化图表
    initChart() {
      if (this.chartInstance) {
        this.chartInstance.dispose(); // 销毁旧实例
      }
      this.chartInstance = echarts.init(this.$refs.chart, this.theme); // 初始化新实例
      this.chartInstance.setOption(this.option); // 设置配置
      if (this.loading) {
        this.chartInstance.showLoading(); // 显示加载动画
      } else {
        this.chartInstance.hideLoading(); // 隐藏加载动画
      }
    },
    // 调整图表大小
    resizeChart() {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    },
  },
};
</script>

<style scoped>
/* 样式可以根据需要自定义 */
</style>
