/*
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-12-10 16:07:49
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-12-18 20:34:29
 * @FilePath: \ok-college-entrance-examination-choices\src\apis\preferences.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";
//志愿表接口
export default {
  // 一键填报
  oneKeyFill(data) {
    return request.post("/api/preferences/recommend/easy", data);
  },
  // 获取志愿成绩修改剩余次数
  getModifyCount() {
    return request.get("/api/preferences/scores/remainTime");
  },
  // 获取招生类型
  getEnrollType() {
    return request.get("/api/school/list-enrollment-type");
  },
  queryPreferences(id, data) {
    return request.get("/api/preferences/query/student/" + id, {
      params: data,
    });
  },
  // queryRecommend(data) {
  //     return request.get("/api/preferences/recommend/"+data);
  // },
  // 获取志愿表详情
  getPerferenceDetail(preferencesId, type) {
    return request.get("/api/preferences/college-admission-detail", {
      params: {
        preferencesId,
        type,
      },
    });
  },
  // 获取推荐学校
  queryRecommend(data) {
    // return request.post("/api/preferences/recommend", data);
    return request({
      url: "/api/preferences/recommend",
      method: "POST",
      data,
      isSearchRequest: true, // 特殊标记
    });
  },
  queryRecommendList(data) {
    // return request.post("/api/preferences/recommend", data);
    return request({
      url: "/api/preferences/recommend-list",
      method: "POST",
      data,
      isSearchRequest: true, // 特殊标记
    });
  },
  queryRecommendOpt(data) {
    return request.post("/api/preferences/recommend-opt", data);
  },
  queryMajors(data) {
    return request.get("/api/preferences/majors/" + data);
  },
  deletePreferences(data) {
    return request.delete("/api/preferences", { data: data });
  },
  savePreferences(data) {
    return request.post("/api/preferences", data);
  },
  savePreferencesC(data) {
    return request.post("/api/preferences/save/C", data);
  },
  updatePreferencesC(data) {
    return request.post("/api/preferences/update/C", data);
  },
  // 更新沟通既要
  updateCommunication(data) {
    return request.put("/api/preferences", data);
  },
  queryPreferencesById(id) {
    return request.get("/api/preferences/" + id);
  },
  queryScore(data) {
    return request.get("/api/preferences/scores", { params: data });
  },
  queryScoreByStudentId(id) {
    return request.get("/api/preferences/scores/" + id);
  },
  queryLastScoreByStudentId(id, createRoleType = 2) {
    return request.get(
      `/api/preferences/scores/latest/${id}?createRoleType=${createRoleType}`
    );
  },
  queryScoreByStudentIdAndBatch(data) {
    return request.get("/api/preferences/scores/batch", {
      params: {
        ...data,
        createRoleType: data.createRoleType || 2,
      },
    });
  },
  queryPreferencesStyle(data) {
    return request.get("/api/preferences/style", { params: data });
  },
  savePreferencesScore(data) {
    return request.post("/api/preferences/scores", data);
  },
  downloadPreferences(data) {
    return request({
      url: "/api/preferences/generate-excel/" + data,
      method: "get",
      //   responseType: "blob",
    });
  },
  //根据志愿表ID生成excel
  getPerferences(data) {
    return request.get("/api/preferences/generateCollegeAdmissionInfo/" + data);
  },
  //获取录取规则信息
  getAdmissionRule(data) {
    return request.get(
      `/api/preferences/admission-rule/${data.schoolId}?province=${data.province}`
    );
  },
};
