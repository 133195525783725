<template>
  <div class="page flex-col gaokaozixunDetail">
    <div class="group_1 flex-row justify-end">
      <div class="box_7 flex-col" style="margin-top: 15px">
        <!-- <div class="section_8 flex-col">
          <div class="bread-crumb">
            <span class="text_46">
              <span class="href" @click="goHome('/welcome')">首页</span>
              &gt;<span class="href" @click="goHome('/gaokaozixun')"
                >高考资讯</span
              >&gt;</span
            >
            <span class="text_47">招生简章</span>
          </div>
        </div> -->
        <span class="text_34">{{ admissionGuide.title }}</span>
        <div class="text-wrapper_19 flex-row justify-between">
          <span class="text_35">{{ admissionGuide.createTime }}</span>
        </div>
        <div class="box_9 flex-row justify-between">
          <div
            class="text-wrapper_8 flex-col"
            v-for="(item, index) in admissionGuide.tags"
            :key="index + ''"
          >
            <span class="text_37">{{ item }}</span>
          </div>
        </div>
        <span class="paragraph_1" ref="paragraph"> </span>
      </div>
    </div>
  </div>
</template>
<script>
import school from "@/apis/school";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      admissionGuide: {},
    };
  },
  methods: {
    getNewsDetail(id) {
      school.queryAdmissionGuideById(id).then((res) => {
        this.admissionGuide = res.message;
        this.$refs.paragraph.innerHTML = res.message.content;
      });
    },
  },
  mounted() {
    this.getNewsDetail(this.$route.query.id || this.id);
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/common.scss";
@import "../gaokaozixun/assets/detail.scss";
.href {
  cursor: pointer;
  &:hover {
    color: var(--main-thema-color);
  }
}
</style>
