<template>
  <div class="footer">
    <span>{{company}}</span>
    <span>{{companyBA}}</span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
        company: 'Copyright © 2025-2025 北京点金未来科技有限公司 版权所有'
        ,
        companyBA: '备案号:京ICP备2025103342号-4'
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.footer {
  height: 100%;
  width: 100%;
  line-height: 100%;
  background: #f5f5f5;
  text-align: center;
    span {
      color: #999999;
      font-size: 12px;
      display: block;
    }
}
</style>