<template>
  <div class="page flex-col">
    <div class="block_1 flex-row">
      <div class="group_1 flex-col">
        <div class="box_5 flex-col">
          <div class="box_7 flex-row">
            <span class="text_20">排名类型：</span>
            <el-checkbox-group
              class="selectList"
              v-model="filterForm.rankingType"
              size="mini"
            >
              <el-checkbox-button
                v-for="item in options.rankingTypes"
                :key="item"
                :label="item"
                @change="filterFormChange('rankingType', item)"
                >{{ item }}</el-checkbox-button
              >
            </el-checkbox-group>
          </div>
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="https://lanhu-oss.lanhuapp.com/SketchPng0c07c5e1c5ad3391a2a50827e9d559923192ea96e866b97aabb83ebfb23db3f7"
          />
          <div class="box_8 flex-row">
            <span class="text_24">排名年份：</span>
            <el-checkbox-group
              class="selectList"
              v-model="filterForm.rankingYear"
              size="mini"
            >
              <el-checkbox-button
                v-for="item in years"
                :key="item"
                :label="item"
                @change="filterFormChange('rankingYear', item)"
                >{{ item }}</el-checkbox-button
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="box_10 flex-col">
          <div v-for="(item, index) in schoolList" :key="index + ''">
            <div
              :key="index + ''"
              class="box_11 flex-row"
              style="gap: 8px"
              @click="goDetail(item.id)"
            >
              <div class="text-wrapper_15 flex-col">
                <span class="text_33">{{
                  String(item.schoolRank).padStart(2, "0")
                }}</span>
              </div>
              <div class="block_6 flex-col">
                <img :src="item.schoolCrestUrl" width="100%" height="auto" />
              </div>
              <div class="block_7 flex-col justify-between">
                <span class="text_34">{{ item.schoolName }}</span>
                <div class="group_3 flex-row" style="gap: 8px">
                  <div
                    class="text-wrapper_16 flex-col"
                    v-for="(feature, index) in item.schoolFeatures"
                    :key="index + ''"
                  >
                    {{ feature }}
                  </div>
                </div>
                <span class="text_39"
                  >{{ item.provinceName }}/{{ item.cityName }}/{{
                    item.schoolType.join("/")
                  }}/{{ item.schoolInstitution }}/{{ item.belongs }}</span
                >
              </div>
            </div>
            <span
              style="
                background-color: #f0f0f0;
                height: 1px;
                width: 1152px;
                display: block;
                margin-left: 24px;
              "
            ></span>
          </div>
        </div>
        <div class="box_24 flex-row">
          <el-pagination
            @size-change="rankSearchSchool"
            @current-change="rankSearchSchool"
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            layout="prev, pager, jumper,next"
            :total="total"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import options from "@/utils/options";
import moment from "moment";
import school from "@/apis/school";
export default {
  mixins: [options],
  data() {
    return {
      constants: {},
      filterForm: {
        school: "",
        rankingType: ["软科排名"],
        rankingYear: [moment().subtract(1, "year").format("YYYY")],
      },
      total: 2,
      currentPage: 1,
      pageSize: 6,
      schoolList: [],
    };
  },
  methods: {
    goDetail(id) {
      this.$emit("getDetail", id);
    },
    rankSearchSchool() {
      let data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        query: this.filterForm.school,
        rankingType: this.filterForm.rankingType[0],
        rankingYear: this.filterForm.rankingYear[0],
      };
      school.queryScoolRanking(data).then((res) => {
        this.schoolList = res.message.records;
        this.total = res.message.total;
        this.currentPage = res.message.current;
      });
    },
    filterFormChange(name, value) {
      this.filterForm[name] = [value];
      this.$nextTick(() => {
        this.rankSearchSchool();
      });
    },
  },

  computed: {
    years() {
      return ["2024", "2023", "2022", "2021"];
    },
  },
  created() {
    this.rankSearchSchool();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/chadaxue/assets/rank.scss";
@import "@/assets/scss/common.scss";
.page {
  width: 100%;
  .box_5,
  .box_10,
  .box_24 {
    margin: 15px 0 !important;
  }
}
::v-deep .el-checkbox-button {
  .el-checkbox-button__inner {
    padding: 0 14px !important;
    height: 30px;
    line-height: 30px;
    margin-left: 8px;
    font-size: 14px !important;
  }
  &.is-checked {
    .el-checkbox-button__inner {
      // background-color: rgba(255, 244, 243, 1) !important;
    }
  }
}
</style>
