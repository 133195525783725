/*
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-12-26 19:59:13
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-12-27 21:33:17
 * @FilePath: \ok-college-entrance-examination-choices\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Vuex from "vuex";
// import { getLocalIP } from '@/utils/httpUtils'
import { getDeviceUUID } from "@/utils/httpUtils";
import province from "@/apis/province";
import school from "@/apis/school";
import moment from "moment";
Vue.use(Vuex);

// 批量导入modules下的模块
const modulesFiles = require.context("./modules", true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  state: {
    ip: "",
    deviceUUID: getDeviceUUID(),
    provinces: [],
    schoolTypes: [],
    schoolFeatures: [],
    schoolInstitutions: [],
    globalLocation:
      JSON.parse(localStorage.getItem("globalLocation")) || "北京",
    gaokaoYears: Array.from({ length: 7 }, (v, i) =>
      moment().subtract(-i, "year").format("YYYY")
    ),
    rankingYears: Array.from({ length: 8 }, (v, i) =>
      moment()
        .subtract(i + 1, "year")
        .format("YYYY")
    ),
    scoreYears: Array.from({ length: 8 }, (v, i) =>
      moment()
        .subtract(i + 1, "year")
        .format("YYYY")
    ),
    gaokaoYearGrades: {},
  },
  getters: {
    getIp: (state) => state.ip,
    userInfo: (state) => state.user.userInfo,
    isLogin: (state) => state.user.isLogin,
    isVip: (state) => state.user.userInfo.auth,
  },
  mutations: {
    setIp(state, ip) {
      state.ip = ip;
    },
    setProvinces(state, provinces) {
      state.provinces = provinces;
    },
    setSchoolTypes(state, schoolTypes) {
      state.schoolTypes = schoolTypes;
    },
    setSchoolFeatures(state, schoolFeatures) {
      state.schoolFeatures = schoolFeatures;
    },
    setSchoolInstitutions(state, schoolInstitutions) {
      state.schoolInstitutions = schoolInstitutions;
    },
    setGlobalLocation(state, location) {
      state.globalLocation = location;
      localStorage.setItem("globalLocation", JSON.stringify(location));
      window.location.reload();
    },
  },
  actions: {
    setIp({ commit }, ip) {
      commit("setIp", ip);
    },
    getIp({ state }) {
      return state.ip;
    },
    initData({ commit }) {
      // province.queryProvinceList().then(res=>{
      //   commit('setProvinces', res.message)
      // })
      // school.querySchoolType().then(res=>{
      //   commit('setSchoolTypes', res.message)
      // })
      // school.querySchoolInstitution().then(res=>{
      //   commit('setSchoolInstitutions', res.message)
      // })
      // school.querySchoolListFeature().then(res=>{
      //   commit('setSchoolFeatures', res.message)
      // })
      return new Promise((resolve, reject) => {
        Promise.all([
          province.queryProvinceList(),
          school.querySchoolType(),
          school.querySchoolInstitution(),
          school.querySchoolListFeature(),
        ])
          .then((res) => {
            // console.log(res);
            commit("setProvinces", res[0].message);
            commit("setSchoolTypes", res[1].message);
            commit("setSchoolInstitutions", res[2].message);
            commit("setSchoolFeatures", res[3].message);
          })
          .then((res) => {
            resolve(res);
          });
      }).catch((error) => {
        reject(error);
      });
    },
    logout() {
      localStorage.clear();
      if (
        window.location.port === "81" ||
        window.location.host === "www.zaiwangzy.com"
      ) {
        window.location.href = "/welcome";
      } else {
        window.location.href = "/sso";
      }
    },
  },
  modules,
});
