/*
 * @Author: leeyishion 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2023-01-06 11:35:16
 * @LastEditors: leeyishion 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2023-01-09 17:05:29
 * @FilePath: \oad_-gepd\src\utils\rem.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// rem等比适配配置文件
// 基准大小
const baseSize = 16;
document.documentElement.style.fontSize = "16px";
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 1920;
  // console.log(scale);
  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 5) + "px";
}

// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
