<template>
  <div class="group_8 flex-col">
    <div class="box_9 flex-col"></div>
    <div class="box_10 flex-col">
      <div class="text-wrapper_8 flex-row">
        <span class="text_39">排位</span>
        <span class="text_40">招聘岗位</span>
        <span class="text_41">薪资范围</span>
        <span class="text_42">招聘企业数</span>
        <span class="text_43">招聘人数</span>
      </div>
      <div
        class="text-wrapper_9 flex-row"
        v-for="(item, index) in jobList"
        :key="index + ''"
      >
        <span class="text_44">{{ index + 1 }}</span>
        <span class="text_45">{{ item.jobTitle }}</span>
        <span class="text_46">{{ item.salaryRange }}/月</span>
        <span class="text_47">{{ item.companyNum }}</span>
        <span class="text_48">{{ item.hiringNum }}</span>
      </div>
      <vip-page v-if="!$store.getters.userInfo.auth" />
      <div class="box_11 flex-col"></div>
    </div>
  </div>
</template>
<script>
import profession from "@/apis/profession";
export default {
  data() {
    return {
      constants: {},
      jobList: [],
    };
  },
  props: {
    typeCode: {
      type: String,
      default: "",
    },
  },
  methods: {
    queryJobList() {
      const typeCode = this.typeCode || this.$route.params.typeCode;
      profession.queryProfessionsJobPositionsByType(typeCode).then((res) => {
        this.jobList = this.$store.getters.userInfo.auth
          ? res.message
          : res.message.slice(0, 3);
      });
    },
  },
  mounted() {
    this.queryJobList();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./assets/jiuye.scss";
</style>
