import request from "@/utils/request";
export default {
  // 获取筛选条件
  getFilter(province) {
    return request.get(
      "/api/batch-lines/batch-line-select?province=" + province
    );
  },
  // 获取填报批次
  getBatchLine(province, subjects) {
    return request.get(
      `api/province/smart-recommend-config?province=${province}&subjects=${subjects}`
    );
  },
  queryLines(data) {
    return request.post("/api/batch-lines/batch-line-segment-default", data);
  },
  // 查询批次线一分一段信息
  queryBatchLinesSegment(data) {
    return request.post("/api/batch-lines/batch-line-segment", data);
  },
  // 查询历年等效分信息
  queryHistoricalEquivalentScore(data) {
    return request.post("/api/batch-lines/historical-equivalent-score", data);
  },
  // 获取分数
  queryScore(data) {
    return request.post("/api/batch-lines/get-score", data);
  },
  // 根据省份获取批次线信息，并按年份分组
  queryBatchLinesByProvince(data) {
    return request.get("/api/batch-lines/by-province", { params: data });
  },
  // 获取批次线信息
  queryBatchLines(data) {
    return request.post("/api/batch-lines/by-province-and-year", data);
  },
  // 获取排名信息
  queryBatchLinesScoreRanks(data) {
    return request.get("/api/batch-lines/same-score-info", { params: data });
  },
  // 获取配置
  queryBatchSegmentConfig(provinceName) {
    return request.get(
      "api/province/batchSegmentConfig?provinceName=" + provinceName
    );
  },
};
