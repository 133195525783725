<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="box_42 flex-row justify-between">
        <div class="box_5 flex-col">
          <div class="box_6 flex-row">
            <div
              class="text-wrapper_2 flex-col"
              :style="
                batch == '本科'
                  ? { 'background-color': 'rgba(245, 245, 245, 1)' }
                  : {}
              "
              @click="batchClick('本科')"
            >
              <span :class="batch == '本科' ? 'text_18' : 'text_19'"
                >本科专业</span
              >
            </div>
            <div
              class="text-wrapper_2 flex-col"
              :style="
                batch == '专科'
                  ? { 'background-color': 'rgba(245, 245, 245, 1)' }
                  : {}
              "
              @click="batchClick('专科')"
            >
              <span :class="batch == '专科' ? 'text_18' : 'text_19'"
                >专科专业</span
              >
            </div>
            <div
              class="text-wrapper_2 flex-col"
              :style="
                batch == '职高'
                  ? { 'background-color': 'rgba(245, 245, 245, 1)' }
                  : {}
              "
              @click="batchClick('职高')"
            >
              <span :class="batch == '职高' ? 'text_18' : 'text_19'"
                >高职本科</span
              >
            </div>
          </div>
          <div class="menu-wrap">
            <el-tree
              ref="tree"
              :data="treeMenuData"
              node-key="code"
              highlight-current
              @node-click="handleNodeClick"
              :default-expanded-keys="defaultExpandedKeys"
              :current-node-key="currentNodeKey"
              accordion
              :expand-on-click-node="false"
            ></el-tree>
          </div>
        </div>
        <div class="section_8 flex-col">
          <div class="text-wrapper_3 flex-col">
            <span class="text_44">{{ name }}</span>
            <span class="text_45">国标代码{{ code || "-" }}(不可用于填报)</span>
          </div>
          <div class="block_2 flex-col">
            <!-- <div class="group_26 flex-row content" style="gap: 16px"></div> -->
            <template v-if="isSecondMajor">
              <div class="tabs">
                <span
                  v-for="item in major2List"
                  :class="{ active: tab === item }"
                  :key="item"
                  @click="tab = item"
                >
                  {{ item }}
                </span>
              </div>
              <main>
                <component
                  :is="tab === '专业概况' ? 'Professional' : 'Direction'"
                  :major-type="name"
                  :batch="batch"
                ></component>
              </main>
            </template>
            <template v-else>
              <div class="tabs">
                <span
                  v-for="item in major3List"
                  :class="{ active: activeTab === item }"
                  :key="item"
                  @click="activeTab = item"
                >
                  {{ item }}
                </span>
              </div>
              <main>
                <component
                  :is="
                    activeTab === '专业概况'
                      ? 'Detail'
                      : activeTab === '就业前景'
                      ? 'Job'
                      : 'School'
                  "
                  :id="majorId"
                  v-bind="$attrs"
                  v-on="$listeners"
                ></component>
              </main>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import major from "@/apis/major";
import options from "@/utils/options";
import Detail from "./Detail.vue";
import Job from "./Job.vue";
import School from "./School.vue";
import Professional from "./Professional.vue";
import Direction from "./Direction.vue";
export default {
  name: "MajorDetail",
  mixins: [options],
  props: {
    majorBatch: {
      type: String,
      default: "本科",
    },
    majorCode: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 二级职业
    major2List() {
      return ["专业概况", "职业方向"];
    },
  },
  components: {
    Detail,
    Job,
    School,
    Professional,
    Direction,
  },
  data() {
    return {
      treeMenuData: [],
      name: null,
      id: null,
      code: null,
      activeTab: "专业概况",
      tab: "专业概况",
      active: "",
      batch: "本科",
      defaultExpandedKeys: [],
      majorId: "",
      isSecondMajor: false,
      currentNodeKey: "",
      major3List: ["专业概况", "就业前景", "开设院校"],
    };
  },
  watch: {
    // batch() {
    //   this.queryTree();
    // },
  },
  methods: {
    batchClick(item) {
      this.batch = item;
      this.init();
    },
    getDetail(id) {
      this.$emit("getDetail", id);
    },
    handleNodeClick(item) {
      // 二级专业
      if (item.typeCode) {
        this.name = item.type;
        this.code = item.typeCode;
        this.isSecondMajor = true;
      }
      // 三级专业
      if (item.majorCode) {
        major.queryMajorProfessionTypes(item.id).then(({ data }) => {
          if (data.length) {
            this.major3List = ["专业概况", "就业前景", "开设院校"];
          } else {
            this.major3List = ["专业概况", "开设院校"];
          }
          this.majorId = item.id;
          this.name = item.majorName;
          this.code = item.majorCode;
          this.isSecondMajor = false;
          this.activeTab = "专业概况";
        });
      }
    },

    async init() {
      await this.queryTree();
      // 默认展开的节点数组
      this.defaultExpandedKeys = [this.majorCode || this.$route.query.code];
      // 默认选中的节点
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.defaultExpandedKeys[0]);
        this.currentNodeKey = this.defaultExpandedKeys[0];
      });
      this.active = this.$route.params.id
        ? this.$route.params.id
        : this.$route.params.type;
      // 二级职业
      if (this.isSecondMajor) {
        for (let i = 0; i < this.treeMenuData.length; i++) {
          for (let j = 0; j < this.treeMenuData[i].majorTypes.length; j++) {
            if (this.treeMenuData[i].majorTypes[j].typeCode == this.majorCode) {
              this.name = this.treeMenuData[i].majorTypes[j].type;
              this.code = this.treeMenuData[i].majorTypes[j].typeCode;
              console.log(this.name, this.code);
              break;
            }
          }
        }
      } else {
        // 三级
        this.id = this.$route.params.id;
        for (let i = 0; i < this.treeMenuData.length; i++) {
          for (let j = 0; j < this.treeMenuData[i].majorTypes.length; j++) {
            for (
              let k = 0;
              k < this.treeMenuData[i].majorTypes[j].majors.length;
              k++
            ) {
              if (
                this.treeMenuData[i].majorTypes[j].majors[k].id ===
                  this.$route.params.id ||
                this.treeMenuData[i].majorTypes[j].majors[k].majorCode ===
                  this.majorCode
              ) {
                this.name =
                  this.treeMenuData[i].majorTypes[j].majors[k].majorName;
                this.code =
                  this.treeMenuData[i].majorTypes[j].majors[k].majorCode;
                this.majorId = this.treeMenuData[i].majorTypes[j].majors[k].id;
                major
                  .queryMajorProfessionTypes(this.majorId)
                  .then(({ data }) => {
                    if (data.length) {
                      this.major3List = ["专业概况", "就业前景", "开设院校"];
                    } else {
                      this.major3List = ["专业概况", "开设院校"];
                    }
                  });

                break;
              }
            }
          }
        }
      }
    },
    async queryTree() {
      const res = await major.queryMajorByLevel(
        this.options.majorLevels[this.batch]
      );
      this.treeMenuData = res.message.map((item) => {
        return {
          ...item,
          label: item.majorCategory,
          code: item.majorCategoryCode,
          children: item.majorTypes.map((m) => {
            return {
              ...m,
              label: m.type,
              code: m.typeCode,
              children: m.majors.map((major) => {
                return {
                  ...major,
                  code: major.majorCode,
                  label: major.majorName,
                };
              }),
            };
          }),
        };
      });
    },
  },
  mounted() {
    this.batch = this.majorBatch;
    this.isSecondMajor = this.majorCode.length === 4;
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhuanye/assets/layout.scss";
.menu {
  text-align: left;
}
::v-deep .el-menu {
  border-right: none;
}
::v-deep .el-submenu__icon-arrow {
  visibility: hidden;
}
.menu-wrap {
  margin-top: 23px;
  padding: 0 18px;
}
.block_2 {
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  main {
    overflow: auto;
    flex: 1;
  }
}
</style>
