<template>
  <div class="header-nav" :class="[className, needBd ? 'bd' : '']">
    <span
      class="text"
      :class="{ active: $route.path === '/welcome' }"
      @click="$router.push('/welcome')"
    >
      首页</span
    >
    <el-popover
      placement="top-start"
      width="177"
      :visible-arrow="false"
      trigger="hover"
      popper-class="fillin-popover"
    >
      <div class="fillin">
        <span @click="$router.push('/fillIn')"
          >智能填报
          <i class="el-icon-arrow-right"></i>
        </span>
        <div class="line"></div>
        <span @click="$router.push('/fillIn')"
          >一键填报 <i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <span
        slot="reference"
        class="text"
        :class="{ active: $route.path === '/fillIn' }"
        @mouseenter="group20Vilible = true"
        >志愿填报
        <i class="el-icon-arrow-down"></i>
      </span>
    </el-popover>
    <span
      class="text"
      :class="{ active: $route.path.includes('gaokaozixun') }"
      @click="$router.push('/gaokaozixun')"
      >高考资讯</span
    >
    <span
      class="text"
      :class="{ active: $route.path === '/chadaxue' }"
      @click="$router.push('/chadaxue')"
      >查大学</span
    >
    <span
      class="text"
      :class="{ active: $route.path === '/chazhuanye' }"
      @click="$router.push('/chazhuanye')"
      >查专业</span
    >
    <span
      class="text"
      :class="{ active: $route.path === '/chazhiye' }"
      @click="$router.push('/chazhiye')"
      >查职业</span
    >
    <span
      class="text"
      :class="{ active: $route.path === '/pici' }"
      @click="$router.push('/pici')"
      >批次线</span
    >
    <span
      class="text"
      :class="{ active: $route.path === '/weici' }"
      @click="$router.push('/weici')"
      >位次线</span
    >
    <span
      class="text"
      :class="{ active: $route.path === '/tiqianpi' }"
      @click="$router.push('/tiqianpi')"
      >提前批</span
    >
    <span
      class="text"
      :class="{ active: $route.path === '/ceping' }"
      @click="$router.push('/ceping')"
      >测评</span
    >
    <el-popover
      placement="top-start"
      width="177"
      :visible-arrow="false"
      trigger="hover"
      popper-class="fillin-popover"
    >
      <div class="fillin">
        <span @click="$router.push('/mingshi')"
          >名师团
          <i class="el-icon-arrow-right"></i>
        </span>
        <div class="line"></div>
        <span @click="$router.push('/guihuashi')"
          >规划师 <i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <span
        class="text"
        :class="{ active: ['/mingshi', '/guihuashi'].includes($route.path) }"
        slot="reference"
        >名师天团
        <i class="el-icon-arrow-down"></i>
      </span>
    </el-popover>
    <span
      class="text"
      :class="{ active: $route.path === '/myvolunteer' }"
      @click="$router.push('/myvolunteer')"
    >
      <span class="badge" v-if="!!volunteerLen">{{ volunteerLen }}</span>
      我的志愿表</span
    >
    <span
      class="text"
      :class="{ active: $route.path.includes('/userinfo') }"
      @click="$router.push('/userinfo')"
      >个人资料</span
    >
  </div>
</template>

<script>
import preferences from "@/apis/preferences";
import { mapGetters } from "vuex";
export default {
  name: "HeaderNav",
  props: {
    className: {
      type: String,
      default: "",
    },
    showBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      group20Vilible: false,
      volunteerLen: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "isLogin"]),
    needBd() {
      return (
        this.showBorder &&
        !["/chadaxue", "/chazhuanye", "/chazhiye"].includes(this.$route.path)
      );
    },
  },
  methods: {},
  created() {
    if (this.isLogin) {
      preferences
        .queryPreferences(this.userInfo.id, {})
        .then(({ data: { records } }) => {
          this.volunteerLen = records.filter(
            (item) => item.createdType === 1
          ).length;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
.bd {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}
.header-nav {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  span.text {
    position: relative;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    margin-left: 40px;
    font-size: 16px;
    color: #ffffff;
    font-style: normal;
    cursor: pointer;
    &.active {
      &::before {
        content: "";
        position: absolute;
        top: 26px;
        width: 25px;
        height: 4px;
        left: 50%;
        transform: translateX(-50%);
        background: #ffffff;
        border-radius: 2px;
      }
    }
  }
  & > span:first-child {
    margin-left: 0;
  }
  .badge {
    position: absolute;
    top: -18px;
    right: 0px;
    padding: 0 8px;
    height: 19px;
    line-height: 19px;
    background: #ffffff;
    border-radius: 10px 10px 10px 0px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #e9302d;
    font-style: normal;
  }
}
</style>
