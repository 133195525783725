<template>
  <div class="block_16 flex-col justify-between">
    <div class="block_17 flex-col">
      <div class="box_3 flex-row justify-between">
        <div class="block_20 flex-col"></div>
        <span class="text_49">就业方向</span>
      </div>
      <span class="text_50">
        {{ jobDirection }}
      </span>
      <div class="box_4 flex-row justify-between">
        <div class="box_5 flex-col"></div>
        <span class="text_51">就业占比</span>
      </div>
      <div class="box_6 flex-col">
        <div
          v-for="item in accountFor"
          :key="item.typeCode"
          class="block_21 flex-row justify-between"
          @click="handleClick(item)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.typeName"
            placement="top"
          >
            <span class="text_52">{{ item.typeName }}</span>
          </el-tooltip>
          <div class="group_14 flex-row">
            <div
              class="box_7 flex-col"
              :style="{ width: item.employmentPercentage + '%' }"
            ></div>
            <span class="text_53">{{ item.employmentPercentage }}%</span>
          </div>
        </div>
      </div>
      <div class="box_14 flex-row justify-between">
        <div class="section_5 flex-col"></div>
        <span class="text_74">人才需求</span>
      </div>
      <div class="box_15 flex-row justify-between">
        <div class="text-wrapper_7">
          <span class="text_75">招聘人数约</span>
          <span class="text_76">
            {{ number || "-" }}
          </span>
          <span class="text_77">人</span>
        </div>
        <div class="text-wrapper_8">
          <span class="text_78">招聘薪资范围</span>
          <span class="text_79">{{ salary || "—" }}</span>
          <span class="text_80">元/月</span>
        </div>
      </div>
      <div class="box_16 flex-row justify-between">
        <div class="block_33 flex-col">
          <Echarts
            width="370px"
            height="20rem"
            :option="chartOption.numberOption"
          />
        </div>
        <div class="block_34 flex-col">
          <Echarts
            height="20rem"
            width="370px"
            :option="chartOption.salaryOption"
          />
        </div>
      </div>
      <div class="box_28 flex-row justify-between">
        <div class="group_34 flex-col"></div>
        <span class="text_120">学历要求</span>
      </div>
      <div class="box_29 flex-row justify-between">
        <div class="block_35 flex-col">
          <Echarts
            height="21.5rem"
            width="370px"
            :option="chartOption.qualificationsOption"
          />
        </div>
        <div class="block_39 flex-col">
          <Echarts
            height="21.5rem"
            width="370px"
            :option="chartOption.hireOption"
          />
        </div>
      </div>
      <!-- <div class="box_42 flex-row">
        <span class="text_150" @click="more">查看更多</span>
        <div class="block_47 flex-col">
          <img
            class="image_25"
            referrerpolicy="no-referrer"
            src="https://lanhu-oss.lanhuapp.com/MasterDDSSlicePNGb5edf37b6c6e10900e7dab95aa23ddf4.png"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import major from "@/apis/major";
import professions from "@/apis/professions";
import Echarts from "@/components/Echarts";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      constants: {},
      // 就业方向
      jobDirection: "",
      // 就业占比
      accountFor: [],
      curItem: {},
      chartOption: {
        // 学历要求
        qualificationsOption: {},
        // 招聘要求
        hireOption: {},
        // 人数要求
        numberOption: {},
        // 薪资范围
        salaryOption: {},
      },
      talent: [],
      qualification: {},
      number: 0,
      salary: 0,
    };
  },
  components: {
    Echarts,
  },
  methods: {
    handleClick(item) {
      if (item.typeCode === "Z0000") {
        return;
      }
      this.curItem = item;
      this.getQualification();
      this.getTalent();
    },
    more() {
      this.$router.push(`/chazhiyedetail/${this.curItem.typeCode}`);
    },
    async getDetail() {
      // 获取就业方向
      major.queryJob(this.id).then(({ message }) => {
        this.jobDirection = message;
      });
      // 获取就业占比
      major.queryMajorProfessionTypes(this.id).then(({ data }) => {
        this.accountFor = data;
        this.curItem = data[0];
        this.getQualification();
        this.getTalent();
      });
    },
    // 获取人才需求
    async getTalent() {
      const { data } = await professions.queryProfessionsStatsCode(
        this.curItem.typeCode
      );
      this.talent = data;
      const average =
        data.hiring[0]?.hiringMonth
          .map((item) => item.hiringNum)
          .reduce((prev, next) => prev + next, 0) / 12;
      // 招聘人数
      this.number = (average / 10000).toFixed(2) + "万";
      // 薪资范围
      // this.salary = data.salary.map(item => item.)
      const { hiring, salary } = data;
      // 人才需求
      this.chartOption.numberOption = {
        legend: {
          data: hiring.map((item) => item.year),
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: hiring[0].hiringMonth.map((item) => item.month),
          format: "{value}月",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: hiring[0].year,
            type: "line",
            stack: "Total",
            data: hiring[0].hiringMonth.map((item) => item.hiringNum),
            symbol: "none",
            smooth: true, // 启用平滑曲线
            areaStyle: {
              color: "rgba(70, 209, 169, 1)", // 背景色
            },
          },
          {
            name: hiring[1].year,
            type: "line",
            symbol: "none",
            stack: "Total",
            data: hiring[1].hiringMonth.map((item) => item.hiringNum),
            smooth: true, // 启用平滑曲线
            areaStyle: {
              color: "rgba(81, 149, 224, 1)", // 背景色
            },
          },
        ],
      };
      // 薪资范围
      this.chartOption.salaryOption = {
        xAxis: {
          data: salary.map((item) => item.month),
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        yAxis: {},
        series: [
          {
            type: "candlestick",
            data: salary.map((item) => [
              item.salaryMax,
              item.salaryMedianH,
              item.salaryMedianL,
              item.salaryMin,
            ]),
            itemStyle: {
              color: "rgba(252, 156, 29, 1)", // 上涨颜色（收盘价 >= 开盘价）
              color0: "rgba(252, 156, 29, 1)", // 下跌颜色（收盘价 < 开盘价）
              borderColor: "rgba(252, 156, 29, 1)", // 上涨边框颜色
              borderColor0: "rgba(252, 156, 29, 1)", // 下跌边框颜色
            },
          },
        ],
      };
    },
    // 获取学历要求
    async getQualification() {
      // 学历要求
      const { data } =
        await professions.queryProfessionsQualificationsTrendType(
          this.curItem.typeName
        );
      // 学历要求图表
      this.chartOption.qualificationsOption = {
        title: {
          text: "招聘学历要求",
        },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: [50, 100],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: data.eduForFan.map((item) => {
              return {
                value: item.ratio,
                name: item.edu,
              };
            }),
          },
        ],
      };
      // 学历招聘趋势
      const legend = data.edu[0].ratios.map((item) => item.edu);
      this.chartOption.hireOption = {
        title: {
          text: "近12月学历招聘趋势",
        },
        legend: {
          data: legend,
          bottom: 10,
        },
        grid: {
          // left: "3%",
          // right: "4%",
          // // bottom: "3%",
          // containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.edu.map((item) => item.month),
          format: "{value}月",
        },
        yAxis: {
          type: "value",
        },
        series: legend.map((item) => {
          return {
            name: item,
            symbol: "none",
            type: "line",
            stack: "Total",
            areaStyle: {},
            data: data.edu.map(
              (it) => it.ratios.find((ratio) => ratio.edu === item).ratio
            ),
          };
        }),
      };
    },
  },
  created() {
    this.getDetail();
  },
  watch: {
    id: {
      handler() {
        this.getDetail();
      },
    },
  },
  // computed: {
  //   // id() {
  //   //   return this.$route.params.id;
  //   // },
  // },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhuanye/assets/detailjiuye.scss";
</style>
