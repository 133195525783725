<template>
  <!-- 书籍封面和名称 -->
  <el-main>
    <h1 style="margin-bottom: 15px">原始书籍</h1>
    <el-form :model="form">
      <el-col :span="7">
        <el-form-item label="省份" prop="province" label-width="100px">
          <el-select
            v-model="form.province"
            placeholder="请选择省份"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in options.provinces"
              :label="item"
              :value="item"
              :key="index + ''"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="科目" prop="subject" label-width="100px">
          <el-select v-model="form.subject" clearable placeholder="请选择学科">
            <el-option
              v-for="(item, index) in subjects"
              :label="item"
              :value="item"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="年份" prop="year" label-width="100px">
          <el-select v-model="form.year" clearable placeholder="请选择年份">
            <el-option
              v-for="(item, index) in yearsList"
              :label="item"
              :value="item"
              :key="index + ''"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-form-item label="">
          <el-button
            type="primary"
            @click="
              () => {
                currentPage = 1;
                queryBooks();
              }
            "
            >查询</el-button
          >
        </el-form-item>
      </el-col>
    </el-form>
    <div>
      <div class="empty" v-if="!books.length">
        <el-empty description="暂无数据"></el-empty>
      </div>
      <template v-else>
        <div class="books">
          <div v-for="book in books" :key="book.name" class="book-item">
            <img
              :src="book.coverUrl"
              class="book-img"
              width="200"
              height="200"
              @click="toBookDetail(book)"
            />
            <el-tooltip
              class="item"
              effect="dark"
              :content="book.title"
              placement="bottom"
            >
              <div>{{ book.title }}</div>
            </el-tooltip>
          </div>
        </div>
      </template>
    </div>
    <BookDetail
      :bookDetailVisible.sync="bookDetailVisible"
      :curBook="curBook"
      v-bind="$attrs"
    />
    <el-pagination
      style="margin-top: 20px"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      :total="total"
    >
    </el-pagination>
  </el-main>
</template>

<script>
import provinceApi from "@/apis/province";
import book from "@/apis/book";
import options from "@/utils/options";
import BookDetail from "./bookDetail.vue";

export default {
  name: "BookPage",
  mixins: [options],
  components: {
    BookDetail,
  },
  props: {
    province: {
      type: String,
      default: "",
    },
  },
  computed: {
    yearsList() {
      return Array.from({ length: 6 }, (_, i) => 2025 - i);
    },
  },
  data() {
    return {
      bookDetailVisible: false,
      form: {
        year: "",
        province: "",
        subject: "",
      },
      subjects: [],
      books: [
        // { coverObjectName: "path/to/cover1.png", title: "书籍名称1" }
        // ... 其他书籍数据
      ],
      currentPage: 1,
      pageSize: 12,
      total: 0,
      curBook: null,
    };
  },
  watch: {
    province: {
      handler(newVal) {
        this.form.province = newVal;
        this.queryBooks();
      },
      immediate: true,
    },
  },
  created() {
    // this.queryBooks();
    provinceApi.querySubjectRuleList().then(({ data }) => {
      this.subjects = [
        ...new Set(
          data
            .map((item) => item.prioritySubjects)
            .flat(Infinity)
            .filter(Boolean)
        ),
      ];
    });
  },
  methods: {
    toBookDetail(book) {
      this.curBook = book;
      this.bookDetailVisible = true;
    },
    queryBooks() {
      book
        .queryBookCover(this.currentPage, this.pageSize, {
          province: this.form.province || undefined,
          subject: this.form.subject || undefined,
          year: this.form.year || undefined,
        })
        .then((res) => {
          this.books = res.message.records;
          this.total = res.message.total;
        });
    },
    handleCurrentChange() {
      this.queryBooks();
    },
  },
};
</script>

<style lang="scss" scoped>
.books {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  .book-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .book-img {
      width: 170px;
      max-height: 100%;
      margin: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    div {
      text-align: center;
      width: 150px;
      color: #333;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
