import request from "@/utils/request";
export default {
  //获取职业类型概览
  queryProfessionsTypeOverview(data) {
    return request.get("/api/professions/type-overview?professionType=" + data);
  },
  // 搜索职业
  queryProfessionsSearch(data) {
    return request.get("/api/professions/search", { params: data });
  },
  queryProfessionsMajorRequirementsType(data) {
    return request.get("/api/professions/major-requirements-type/" + data);
  },
  queryProfessionsJobPositionsByProfession(data) {
    return request.get("/api/professions/job-positions/by-profession/" + data);
  },
  queryProfessionsQualificationsTrendType(data) {
    return request.get(
      "/api/professions/qualifications-trend-type?professionType=" + data
    );
  },
  queryProfessionsQualificationsTrendName(data) {
    return request.get("/api/professions/qualifications-trend-name/" + data);
  },
  queryProfessionsStatsCode(data) {
    return request.get("/api/professions/stats-code/" + data);
  },
  queryProfessionsJobDetailsByType(data) {
    return request.get("/api/professions/jobDetails/byType/" + data);
  },
  queryProfessionsJobPositionsByType(data) {
    return request.get("/api/professions/job-positions/by-type/" + data);
  },
  queryProfessionsByMajorType(data) {
    return request.get("/api/professions/by-major-type/" + data);
  },
  queryProfessionsByCategory(data) {
    return request.get("/api/professions/by-category/" + data);
  },
};
