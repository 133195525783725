export default [
  {
    path: "/",
    redirect: "/welcome",
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("@/views/student/wellcome/index.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/about-c",
    name: "About",
    component: () => import("@/views/student/about.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/recommendation/:studentId/:preferenceScoreId/:preferenceId/:batch",
    name: "recommendation",
    component: () => import("@/views/student/fillIn/recommendation.vue"),
    meta: {
      title: "志愿填报",
    },
  },
  {
    path: "/student",
    name: "student",
    component: () => import("@/views/student/layout/Index.vue"),
    children: [
      {
        path: "/volunteerDetail/:studentId/:preferenceScoreId/:preferenceId",
        name: "volunteerDetail",
        component: () => import("@/views/student/fillIn/volunteerDetail.vue"),
        meta: {
          title: "志愿详情",
        },
      },
      {
        path: "/fillIn",
        name: "FillIn",
        component: () => import("@/views/student/fillIn"),
        meta: { requireAuth: true, title: "志愿填报" },
      },
      {
        path: "/chadaxue",
        name: "chadaxue",
        component: () => import("@/views/student/chadaxue/Index.vue"),
        meta: { requireAuth: true, title: "查大学" },
      },
      {
        path: "/chadaxuerank",
        name: "chadaxuerank",
        component: () => import("@/views/student/chadaxue/Rank.vue"),
        meta: { title: "大学排名" },
      },
      {
        path: "/chadaxuedetail/:id",
        name: "chadaxuedetail",
        redirect: "/chadaxueoverview/:id",
        component: () => import("@/views/student/chadaxue/Detail.vue"),
        children: [
          // 提前批招生计划
          {
            path: "/preFraction/:id",
            name: "PreFraction",
            component: () => import("@/views/student/chadaxue/preFraction.vue"),
            meta: { title: "提前批招生计划" },
          },
          // 提前批分数线
          {
            path: "/preFractionScoreline/:id",
            name: "PreFractionScoreline",
            component: () =>
              import("@/views/student/chadaxue/preFractionScoreline.vue"),
            meta: { title: "提前批分数线" },
          },
          {
            path: "/chadaxueluqu/:id",
            name: "chadaxueluqu",
            component: () => import("@/views/student/chadaxue/Luqu.vue"),
            meta: { title: "录取分数线" },
          },
          {
            path: "/chadaxueoverview/:id",
            name: "chadaxueoverview",
            component: () => import("@/views/student/chadaxue/Overview.vue"),
            meta: { title: "院校概况" },
          },
          {
            path: "/chadaxuezhaosheng/:id",
            name: "chadaxuezhaosheng",
            component: () => import("@/views/student/chadaxue/Zhaosheng.vue"),
            meta: { title: "招生简章" },
            children: [
              {
                path: "admission",
                name: "Admission",
                component: () =>
                  import("@/views/student/chadaxue/ZhaoshengDetail.vue"),
                meta: { title: "招生简章" },
              },
            ],
          },
          {
            path: "/chadaxuejihua/:id",
            name: "chadaxuejihua",
            component: () => import("@/views/student/chadaxue/Jihua.vue"),
            meta: { title: "招生计划" },
          },
          {
            path: "/chadaxuezhuanye/:id",
            name: "chadaxuezhuanye",
            component: () => import("@/views/student/chadaxue/Zhuanye.vue"),
            meta: { title: "专业院系" },
          },
        ],
      },
      {
        path: "/ZhaoshengDetail/:id",
        name: "ZhaoshengDetail",
        component: () => import("@/views/student/chadaxue/ZhaoshengDetail.vue"),
        meta: { title: "招生简章" },
      },
      {
        path: "/chazhuanye",
        name: "chazhuanye",
        component: () => import("@/views/student/chazhuanye/Index.vue"),
        meta: { title: "查专业" },
      },
      {
        path: "/chazhuanyelayout",
        name: "chazhuanyelayout",
        component: () => import("@/views/student/chazhuanye/Layout.vue"),
        children: [
          {
            path: "/chazhuanyedetail/:id",
            name: "chazhuanyedetail",
            component: () => import("@/views/student/chazhuanye/Detail.vue"),
            meta: { title: "专业概况" },
          },
          {
            path: "/chazhuanyedetailjiuye/:id",
            name: "chazhuanyedetailjiuye",
            component: () =>
              import("@/views/student/chazhuanye/Detailjiuye.vue"),
            meta: { title: "就业前景" },
          },
          {
            path: "/chazhuanyedetailyuanxiao/:id",
            name: "chazhuanyedetailyuanxiao",
            component: () =>
              import("@/views/student/chazhuanye/Detailyuanxiao.vue"),
            meta: { title: "开设院校" },
          },
          {
            path: "/chazhuanyetype/:type",
            name: "chazhuanyetype",
            component: () =>
              import("@/views/student/chazhuanye/Zhuanyetype.vue"),
            meta: { title: "专业概况" },
          },
          {
            path: "/chazhuanyetypezhiye/:type",
            name: "chazhuanyetypezhiye",
            component: () =>
              import("@/views/student/chazhuanye/Zhuanyetypezhiye.vue"),
            meta: { title: "职业方向" },
          },
        ],
      },
      {
        path: "/chazhiye",
        name: "chazhiye",
        component: () => import("@/views/student/chazhiye/Index.vue"),
        meta: { title: "查职业" },
      },
      {
        path: "/chazhiyelayout",
        name: "chazhiyelayout",
        component: () => import("@/views/student/chazhiye/Layout.vue"),
        children: [
          {
            path: "/chazhiyeoverview/:typeCode",
            name: "chazhiyeoverview",
            component: () => import("@/views/student/chazhiye/Overview.vue"),
            meta: { title: "职业概况" },
          },
          {
            path: "/chazhiyejiuye/:typeCode",
            name: "chazhiyejiuye",
            component: () => import("@/views/student/chazhiye/Jiuye.vue"),
            meta: { title: "就业岗位" },
          },
          {
            path: "/chazhiyezhaopin/:typeCode",
            name: "chazhiyezhaopin",
            component: () => import("@/views/student/chazhiye/Zhaopin.vue"),
            meta: { title: "企业招聘" },
          },
          {
            path: "/chazhiyerencai/:typeCode",
            name: "chazhiyerencai",
            component: () => import("@/views/student/chazhiye/Rencai.vue"),
            meta: { title: "人才培养高校" },
          },
          {
            path: "/chazhiyedetail/:professionCode",
            name: "chazhiyedetail",
            component: () => import("@/views/student/chazhiye/Zhiyedetail.vue"),
            meta: { title: "岗位情况" },
          },
          {
            path: "/chazhiyedetailzhaopin/:professionCode",
            name: "chazhiyedetailzhaopin",
            component: () =>
              import("@/views/student/chazhiye/Zhaopindetail.vue"),
            meta: { title: "企业招聘" },
          },
        ],
      },
      {
        path: "/ceping",
        name: "ceping",
        component: () => import("@/views/student/ceping/Index.vue"),
        meta: { requireAuth: true, title: "测评" },
      },
      {
        path: "/cepingdetail/:id",
        name: "cepingdetail",
        component: () => import("@/views/student/ceping/Detail.vue"),
        meta: { title: "测评详情" },
      },
      {
        path: "/ceping-result/:id",
        name: "TestResult",
        component: () => import("@/views/student/ceping/result.vue"),
        meta: { title: "测评结果" },
      },
      {
        path: "/cepingtest/:id",
        name: "cepingtest",
        component: () => import("@/views/student/ceping/Test.vue"),
      },
      {
        path: "/gaokaozixun",
        name: "gaokaozixun",
        component: () => import("@/views/student/gaokaozixun/Index.vue"),
        meta: { title: "高考资讯" },
      },
      {
        path: "/gaokaozixunDetail/:id",
        name: "gaokaozixunDetail",
        component: () => import("@/views/student/gaokaozixun/Detail.vue"),
        meta: { title: "资讯详情" },
      },
      {
        path: "/mingshi",
        name: "mingshi",
        component: () => import("@/views/student/mingshi/Index.vue"),
        meta: { title: "名师团" },
      },
      {
        path: "/guihuashi",
        name: "guihuashi",
        component: () => import("@/views/student/mingshi/Guihuashi.vue"),
        meta: { title: "规划师" },
      },
      {
        path: "/pici",
        name: "pici",
        component: () => import("@/views/student/pici/Index.vue"),
        meta: { title: "批次线" },
      },
      {
        path: "/weici",
        name: "weici",
        component: () => import("@/views/student/weici/Index.vue"),
        meta: { title: "位次线" },
      },
      {
        path: "/tiqianpi",
        name: "tiqianpi",
        component: () => import("@/views/student/tiqianpi/Index.vue"),
        meta: { requireAuth: true, title: "提前批" },
      },
      {
        path: "/myvolunteer",
        name: "myvolunteer",
        component: () => import("@/views/student/myvolunteer/Index.vue"),
        meta: { requireAuth: true, title: "我的志愿表" },
      },
      {
        path: "/myvolunteerdesign",
        name: "myvolunteerdesign",
        component: () => import("@/views/student/myvolunteer/Design.vue"),
        meta: { title: "智能填报" },
      },
      {
        path: "/userinfo",
        name: "userinfo",
        component: () => import("@/views/student/userinfo/Index.vue"),
        redirect: "/userinfo/basicInfo",
        children: [
          {
            path: "volunteerInfo",
            name: "volunteerInfo",
            meta: {
              title: "志愿表",
              icon: "el-icon-tickets",
              permission: [0, 1, 2, 5],
            },
            component: () =>
              import("@/views/student/userinfo/components/volunteerInfo.vue"),
          },
          {
            path: "basicInfo",
            name: "basicInfo",
            meta: {
              title: "重要信息",
              icon: "el-icon-warning-outline",
              permission: [0, 1, 2, 5],
              requireAuth: true,
            },
            component: () =>
              import("@/views/student/userinfo/components/basicInfo.vue"),
          },
          {
            path: "physicalTest",
            name: "physicalTest",
            meta: {
              title: "体检信息",
              icon: "el-icon-document",
              permission: [1, 2, 5],
            },
            component: () =>
              import("@/views/student/userinfo/components/physicalTest.vue"),
          },
          {
            path: "enrollmentIntention",
            name: "enrollmentIntention",
            meta: {
              title: "报名意向",
              icon: "el-icon-document-checked",
              permission: [1, 2, 5],
            },
            component: () =>
              import(
                "@/views/student/userinfo/components/enrollmentIntention.vue"
              ),
          },
          {
            path: "scoreSheet",
            name: "scoreSheet",
            meta: {
              title: "成绩册",
              icon: "el-icon-medal",
              permission: [1, 2, 5],
            },
            component: () =>
              import("@/views/student/userinfo/components/scoreSheet.vue"),
          },
          {
            path: "evaluationInfo",
            name: "evaluationInfo",
            meta: {
              title: "测评信息",
              icon: "el-icon-s-data",
              permission: [0, 1, 2, 5],
            },
            component: () =>
              import("@/views/student/userinfo/components/evaluationInfo.vue"),
          },
          {
            path: "otherInfo",
            name: "otherInfo",
            meta: {
              title: "其他信息",
              icon: "el-icon-menu",
              permission: [1, 2, 5],
            },
            component: () =>
              import("@/views/student/userinfo/components/otherInfo.vue"),
          },
        ],
      },
      {
        path: "/search",
        name: "Search",
        redirect: "/search/all",
        component: () => import("@/views/student/search/Layout.vue"),
        children: [
          {
            path: "all",
            name: "All",
            component: () => import("@/views/student/search/All.vue"),
            meta: { title: "全部结果" },
          },
          {
            path: "searchSchool",
            name: "searchSchool",
            component: () => import("@/views/student/search/School"),
            meta: { title: "大学结果" },
          },
          {
            path: "searchZhuanye",
            name: "searchZhuanye",
            component: () => import("@/views/student/search/Zhuanye"),
            meta: { title: "专业结果" },
          },
          {
            path: "searchZhiye",
            name: "searchZhiye",
            component: () => import("@/views/student/search/Zhiye"),
            meta: { title: "职业结果" },
          },
          {
            path: "searchZixun",
            name: "searchZixun",
            component: () => import("@/views/student/search/Zixun"),
            meta: { title: "资讯结果" },
          },
        ],
      },
    ],
  },
];
