import request from "@/utils/request";
export default {
  sms(data) {
    return request.get("/api/sms/code/" + data);
  },
  login(data) {
    return request.post("/tel/code/login", data);
  },
  getPlannerType(tel) {
    return request.get("/api/planner/query/simple-info?plannerTel=" + tel);
  },
};
