<template>
  <div class="container" v-if="$store.getters.userInfo.auth || schoolId">
    <p>
      <el-select
        v-model="year"
        @change="changeYear"
        style="width: 100px; margin-right: 10px"
      >
        <el-option
          v-for="item in yearList"
          :key="item"
          :value="item"
          :label="item"
        ></el-option>
      </el-select>
      <el-select
        v-model="subject"
        style="width: 100px; margin-right: 10px"
        @change="subjectChange"
      >
        <el-option
          v-for="item in subjectList"
          :key="item.subject"
          :value="item.subject"
          :label="item.subject"
        ></el-option>
      </el-select>
      <!-- <el-select v-model="batch" style="width: 150px; margin-right: 10px">
        <el-option
          v-for="item in batchList"
          :key="item.batch"
          :value="item.batch"
          :label="item.batch"
        ></el-option>
      </el-select> -->
      <!-- <el-select v-model="dataKey" style="margin-right: 10px" @change="getData">
        <el-option
          v-for="item in schoolList"
          :key="item.dataKey"
          :value="item.dataKey"
          :label="item.enrollDirection"
        ></el-option>
      </el-select> -->
    </p>
    <main>
      <section v-for="(item, index) in tableList" :key="index">
        <div class="breadcrumb">{{ item.title }}</div>
        <el-table
          :data="item.list"
          border
          style="width: 100%"
          default-expand-all
          :span-method="arraySpanMethod"
          :row-class-name="tableRowClassName"
          row-key="index"
          :header-cell-style="{
            'background-color': '#f5f5f5',
            color: 'rgba(113, 114, 116, 1)',
            'text-align': 'center',
          }"
          :cell-style="{
            color: 'rgba(113, 114, 116, 1)',
            'text-align': 'center',
          }"
        >
          <el-table-column label="招生代码" min-width="10%">
            <template slot-scope="scope">
              <div v-if="scope.row.children" class="title">
                {{ scope.row.majorGroup }}
              </div>
              <!-- 判断是否是第一行 -->
              <div v-else-if="+scope.row.first">
                {{ scope.row.schoolEnrollCode }}
              </div>
              <div v-else>
                {{ scope.row.majorEnrollCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="院校/专业名称"
            class="school-column"
            min-width="50%"
          >
            <template slot-scope="scope">
              <!-- 判断是否是第一行 -->
              <template v-if="scope.row.first">
                <div class="school" @click="handleSchool(scope.row)">
                  {{ scope.row.schoolName || "-" }}
                </div>
                <div class="note" v-if="scope.row.note">
                  （注：{{ scope.row.note }}）
                </div>
              </template>
              <template v-else>
                <div class="major-row">
                  <div class="major-name" @click="handleMajor(scope.row)">
                    {{ scope.row.majorName || "-" }}
                  </div>
                  <div class="note" v-if="scope.row.note">
                    <div>（注：{{ scope.row.note }}）</div>
                  </div>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="选科" min-width="10%">
            <template slot-scope="scope">
              {{ scope.row.chooseSubject || "不限" }}
            </template>
          </el-table-column>
          <el-table-column label="录取人数" min-width="10%">
            <template slot-scope="scope">
              {{ scope.row.enterNum || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="最高分" prop="maxScore" min-width="10%">
            <template slot-scope="scope">
              {{ scope.row.maxScore || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="最低分" prop="minScore" min-width="10%">
            <template slot-scope="scope">
              {{ scope.row.minScore || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="位次" prop="lowestRank" min-width="10%">
            <template slot-scope="scope">
              {{ scope.row.lowestRank || "-" }}
            </template>
          </el-table-column>
        </el-table>
      </section>

      <div class="tip">
        <div class="title">
          <i>!</i>
          <span>重要提示</span>
        </div>
        <div class="content">
          提前批录取分数数据源来源于各省考试院、院校官网及第三方公布的信息，部分院校存在未完整公布的情况，数据仅作参考。
        </div>
      </div>
    </main>
  </div>
  <div v-else>
    <vip-page />
  </div>
</template>

<script>
import options from "@/utils/options";
import school from "@/apis/school";
import { mapGetters, mapState } from "vuex";
export default {
  mixins: [options],
  name: "preFractionScoreline",
  props: {
    student: {
      type: Object,
      default: () => ({}),
    },
    schoolId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      year: "",
      tableList: [],
      preConfig: [],
      subject: "",
      batch: "",
      dataKey: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapState(["globalLocation"]),
    subjectList() {
      if (!this.preConfig.length) {
        return [];
      }
      return this.preConfig.find((item) => item.year === +this.year)?.subjects;
    },
    yearList() {
      if (!this.preConfig.length) {
        return [];
      }
      return this.preConfig.map((item) => item.year);
    },
    // batchList() {
    //   if (!this.subjectList.length) {
    //     return [];
    //   }
    //   return this.subjectList.find((item) => item.subject === this.subject)
    //     ?.batches;
    // },
    // schoolList() {
    //   if (!this.batchList.length) {
    //     return [];
    //   }
    //   return this.batchList.find((item) => item.batch === this.batch)
    //     ?.enrollDirections;
    // },
  },
  mounted() {},
  methods: {
    changeYear() {
      const data = this.preConfig.find((item) => item.year === +this.year);
      this.subject = data.subjects[0].subject;
      this.dataKey = data.subjects[0].dataKey;
      this.getData();
    },
    handleSchool(row) {
      if (this.schoolId) {
        this.$emit("getSchoolDetail");
      } else {
        const href = this.$router.resolve({
          path: `/chadaxueoverview/${row.schoolId}`,
        });
        window.open(href.href, "_blank");
      }
    },
    handleMajor(major) {
      if (!major.majorLevel) {
        this.$message.warning("该专业暂无介绍！");
        return;
      }
      if (this.schoolId) {
        const obj = {
          1: "本科",
          2: "专科",
          3: "职高",
        };
        this.$emit("getDetail", major.majorCode, obj[major.majorLevel]);
        return;
      }
      let href;
      const batchMap = {
        1: "本科",
        2: "专科",
        3: "职高",
      };
      if (major.majorCode.length === 6) {
        href = this.$router.resolve(
          `/chazhuanyedetail/${major.majorId}?code=${major.majorCode}&batch=${
            batchMap[major.level]
          }`
        );
      } else {
        href = this.$router.resolve(
          `/chazhuanyetype/${major.majorName}?code=${major.majorCode}&batch=${
            batchMap[major.level]
          }`
        );
      }
      window.open(href.href, "_blank");
    },
    tableRowClassName({ row }) {
      if (row.first) {
        return "warning-row";
      }
      return "";
    },
    arraySpanMethod({ row }) {
      if (row.children) {
        return [1, 7];
      }
    },
    subjectChange() {
      this.dataKey = this.subjectList.find(
        (item) => item.subject === this.subject
      ).dataKey;
      console.log(this.dataKey);

      this.getData();
    },
    async getConfig() {
      const params = {
        provinceName: this.userInfo.province || this.globalLocation,
        schoolId: this.$route.params.id,
      };
      if (this.schoolId) {
        params.schoolId = this.schoolId;
        params.provinceName = this.student.province;
      }
      const { data } = await school.getEarlyBatchConfig(params);
      this.preConfig = data;
      this.year = data[0].year;
      this.subject = data[0].subjects[0].subject;
      // this.batch = data[0].subjects[0].batches[0].batch;
      this.dataKey = data[0].subjects[0].dataKey;
      this.getData();
    },
    async getData() {
      this.tableList = [];
      school.queryScoreLine(this.dataKey).then(({ data }) => {
        let currentList = null;
        data.forEach((item) => {
          if (item.first) {
            const newObj = {
              breadcrumb: item.breadcrumbs.join(" > "),
              title: item.title,
              list: [item],
            };
            this.tableList.push(newObj);
            currentList = newObj.list;
          } else {
            if (currentList) {
              currentList.push(item);
            }
          }
        });
      });
    },
  },
  created() {
    this.getConfig();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
  p {
    margin: 20px 0;
    text-align: left;
  }
}
::v-deep .el-table__expand-icon {
  display: none !important;
}
.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.school {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: rgba(50, 64, 75, 1);
  text-align: left;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
  &:hover {
    color: var(--main-thema-color);
  }
}
.note {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
::v-deep .warning-row {
  background: #f5f5f5;
}
::v-deep .el-table_1_column_2 {
  width: 600px !important;
}
.tip {
  background: rgba(233, 48, 45, 0.0805);
  border-radius: 8px 8px 8px 8px;
  border: 1px dashed #e9302d;
  padding: 16px;
  color: #000;
  font-weight: 400;
  text-align: left;
  margin-top: 32px;
  .title {
    text-align: left;
  }
  i {
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    background: rgb(204, 59, 76);
    color: #fff;
    font-style: normal;
  }
  span {
    font-size: 16px;
    margin-left: 8px;
  }
  .content {
    font-size: 14px;
    margin-left: 32px;
  }
}
.breadcrumb {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin: 15px 0;
}
.major-row {
  text-align-last: left;
  .major-name {
    color: #121212;
    cursor: pointer;
    &:hover {
      color: var(--main-thema-color);
    }
  }
}
</style>
