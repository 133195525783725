<template>
  <div class="container">
    <div class="title">
      <span></span>
      <div class="batch">{{ year }}批次线</div>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#f5f5f5',
        height: '82px',
        color: '#2D2E42',
        'text-align': 'center',
      }"
      :cell-style="{ height: '82px', color: '#2D2E42', 'text-align': 'center' }"
    >
      <el-table-column label="省市" prop="province" min-width="16%">
      </el-table-column>
      <el-table-column prop="batch" label="批次/段" min-width="29%">
      </el-table-column>
      <el-table-column prop="subject" label="科目" min-width="14%">
      </el-table-column>
      <el-table-column prop="controlScore" label="控制分数线" min-width="14%">
      </el-table-column>
      <el-table-column label="压线分区间" min-width="26%">
        <template slot-scope="scope">
          <span v-if="scope.row.lowestScore && scope.row.highestScore"
            >{{ scope.row.lowestScore }}-{{ scope.row.highestScore }}</span
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "batchTable",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    year: {
      type: String,
      default: "2022",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1198px;
  margin: 0 auto;
  .title {
    display: flex;
    margin: 26px 0;
    align-items: center;
    span {
      width: 7px;
      height: 24px;
      background: #e9302d;
      border-radius: 4px 4px 4px 4px;
      margin-right: 16px;
    }
    .batch {
      font-size: 24px;
      color: #15171d;
      font-weight: 600;
    }
  }
  .tableHead {
    background-color: red;
  }
}
</style>
