var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-table"},[_c('el-table',_vm._g(_vm._b({ref:"tableRef",attrs:{"data":_vm.tableData,"row-key":_vm.rowKey,"border":"","header-cell-style":{
      'background-color': '#f5f5f5',
      color: '#2D2E42',
      'text-align': 'center',
    },"cell-style":{
      color: '#2D2E42',
      'text-align': 'center',
    }},on:{"sort-change":_vm.handleSortChange}},'el-table',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.columns),function(col){return [(!col.slot)?_c('el-table-column',_vm._b({key:col.prop},'el-table-column',col,false)):_c('el-table-column',{key:col.prop,attrs:{"label":col.label,"width":col.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t(col.slot,null,{"row":scope.row,"$index":scope.$index})]}}],null,true)})]})],2),(_vm.showPagination)?_c('div',{staticClass:"pagination-container"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-sizes":_vm.pageSizes,"page-size":_vm.pageSize,"layout":_vm.paginationLayout,"total":_vm.total},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }