<template>
  <div class="page flex-col">
    <div class="section_1 flex-row justify-end">
      <div class="section_41 flex-col">
        <div class="section_49 flex-row">
          <div class="image-text_74 flex-row justify-between">
            <div class="group_10 flex-col"></div>
            <span class="text-group_12">院校分数线</span>
          </div>
          <div class="section_9 flex-row">
            <div class="image-text_75 flex-row justify-between">
              <el-select
                v-model="filterFormYx.province"
                @change="querySubjectsYx"
                style="width: 7.5rem"
              >
                <el-option
                  v-for="item in options.provinces"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="section_10 flex-row">
            <div class="image-text_76 flex-row justify-between">
              <el-select
                v-model="filterFormYx.year"
                style="width: 7.5rem"
                @change="yearChangeYx"
              >
                <el-option
                  v-for="item in options.scoreYears"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="section_11 flex-row">
            <div class="image-text_77 flex-row justify-between">
              <el-select
                v-model="filterFormYx.subjectSelection"
                style="width: 7.5rem"
                @change="queryScoreLineYx"
              >
                <el-option
                  v-for="item in courseList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <zw-table :columns="schoolColumns" :data="scoreLineListYx">
          <template #lowestScore="{ row }">
            {{ row.lowestScore }}
            <span v-if="row.zj" class="zj">征集</span>
          </template>
        </zw-table>
        <div class="section_51 flex-row">
          <div class="image-text_78 flex-row justify-between">
            <div class="section_13 flex-col"></div>
            <span class="text-group_16">专业分数线</span>
          </div>
          <div class="block_8 flex-row">
            <div class="image-text_79 flex-row justify-between">
              <el-select
                v-model="filterFormZy.province"
                @change="querySubjectsZy"
                style="width: 7.5rem"
              >
                <el-option
                  v-for="item in options.provinces"
                  :key="item"
                  :value="item"
                  >{{ item }}</el-option
                >
              </el-select>
            </div>
          </div>
          <div class="block_9 flex-row">
            <div class="image-text_80 flex-row justify-between">
              <el-select
                v-model="filterFormZy.year"
                style="width: 7.5rem"
                @change="yearChangeZy"
              >
                <el-option
                  v-for="item in options.scoreYears"
                  :key="item"
                  :value="item"
                  >{{ item }}</el-option
                >
              </el-select>
            </div>
          </div>
          <div class="block_10 flex-row justify-between">
            <div class="text_127 flex-row justify-between">
              <el-select
                v-model="filterFormZy.subjectSelection"
                style="width: 7.5rem"
                @change="queryScoreLineZy"
              >
                <el-option
                  v-for="item in courseMajorList"
                  :key="item"
                  :value="item"
                  >{{ item }}</el-option
                >
              </el-select>
            </div>
          </div>
          <div class="block_12 flex-row">
            <div class="image-text_82 flex-row justify-between">
              <el-select
                v-model="filterFormZy.batch"
                style="width: 7.5rem"
                @change="queryScoreLineZy"
              >
                <el-option
                  v-for="item in batchList"
                  :key="item"
                  :value="item"
                  >{{ item }}</el-option
                >
              </el-select>
            </div>
          </div>
          <!-- <div class="image-text_83 flex-row justify-between">
            <el-checkbox v-model="filterFormZy.isOnlyMatch"></el-checkbox>
            <span class="text-group_21">仅看符合</span>
          </div> -->
          <el-tooltip
            v-if="filterFormZy.subjectSelection === '综合'"
            effect="light"
            placement="bottom"
          >
            <div slot="content">
              <el-checkbox-group
                v-model="filterFormZy.subSubjects"
                :max="3"
                :min="1"
              >
                <el-checkbox-button label="物">物理</el-checkbox-button>
                <el-checkbox-button label="化">化学</el-checkbox-button>
                <el-checkbox-button label="生">生物</el-checkbox-button>
                <el-checkbox-button label="政">政治</el-checkbox-button>
                <el-checkbox-button label="史">历史</el-checkbox-button>
                <el-checkbox-button label="地">地理</el-checkbox-button>
                <el-checkbox-button label="技">技术</el-checkbox-button>
              </el-checkbox-group>
            </div>
            <!-- <div class="block_13 flex-row">
              <span class="text_128">{{
                filterFormZy.subSubjects.join("")
              }}</span>
              <img
                class="label_6"
                referrerpolicy="no-referrer"
                src="https://lanhu-oss.lanhuapp.com/SketchPng115f5fcb8350e6ac02902a70d19f9f1e6b9dda1956a9507c6344f5e293cfd586"
              />
              <span class="text_129">选科专业</span>
            </div> -->
          </el-tooltip>
        </div>
        <div class="section_52 flex-row">
          <span class="text_130">专业组</span>

          <div
            :class="majorGroup == '' ? 'text-wrapper_42' : 'text-wrapper_43'"
          >
            <span
              :class="majorGroup == '' ? 'text_131' : 'text_132'"
              @click="majorGroup = ''"
              style="cursor: pointer"
              >全部</span
            >
          </div>
          <template v-for="(item, index) in scoreLineListZyClone">
            <div
              v-if="item.majorGroupCode"
              :class="
                majorGroup == item.majorGroupCode
                  ? 'text-wrapper_42'
                  : 'text-wrapper_43'
              "
              :key="index"
            >
              <span
                :class="
                  majorGroup == item.majorGroupCode ? 'text_131' : 'text_132'
                "
                @click="majorGroup = item.majorGroupCode"
                style="cursor: pointer"
                >{{ item.majorGroupCode }}</span
              >
            </div>
          </template>
        </div>
        <div class="section_53 flex-col">
          <div
            class="block_44 flex-col"
            v-for="(item, index) in scoreLineListZy"
            :key="index + ''"
          >
            <div class="box_25 flex-row">
              <div class="text-wrapper_162 flex-col justify-between">
                <span class="text_134"
                  >招生方向：{{ item.admissionDirection || "-" }}</span
                >
                <span class="text_135"
                  >选科要求：{{ item.subjectRequirement || "-" }}</span
                >
              </div>
            </div>
            <zw-table :columns="columns" :data="item.details">
              <template #majorName="{ row }">
                <div class="major-name">
                  {{ row.majorName }}
                  <div class="note" v-if="row.note">
                    {{ row.note }}
                  </div>
                </div>
              </template>
              <template #lowestScore="{ row }">
                {{ row.lowestScore }}
                <span v-if="row.zj" class="zj">征集</span>
              </template>
            </zw-table>
          </div>
        </div>
        <div class="box_41 flex-col">
          <div class="image-text_84 flex-row justify-between">
            <img
              class="label_7"
              referrerpolicy="no-referrer"
              src="@/views/student/icons/school/prompt@2x.png"
            />
            <span class="text-group_22">请注意</span>
          </div>
          <span class="paragraph_4"
            >1、本平台历年录取数据，均参考各省市出版的填报指南以及各本专科院校的官网历年录取数据。<br />2、本平台数据与各省教育考试院发布的数据一致，由于各省市加分政策、院校官网对征集志愿数据的显示等原因，会与院校官网存在数据不一致的情况，请以各省教育考仅供参考。</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import school from "@/apis/school";
import province from "@/apis/province";
import moment from "moment";
import options from "@/utils/options";
export default {
  mixins: [options],
  name: "Admission",
  props: {
    student: {
      type: Object,
      default: () => ({}),
    },
    schoolId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filterFormYx: {
        province: this.student.province,
        year: moment().subtract(1, "year").format("YYYY"),
        subjectSelection: "",
      },
      filterFormZy: {
        province: this.student.province,
        year: moment().subtract(1, "year").format("YYYY"),
        subjectSelection: "",
        subSubjects: [],
        isOnlyMatch: false,
        batch: "",
      },
      majorGroup: "", //专业组
      subjectRule: "",
      scoreLineListYx: [
        {
          year: 2021, //年份
          admissionBatch: "", //录取批次
          admissionType: "", //招生类型
          lowestScore: 0, //最低分
          lowestRank: 0, //最低位次
          provincialControlLine: 0, //省控线
          majorGroup: "", //专业组
          subjectRequirement: "", //选科要求
          zj: false, //是否有征集
          zjDetail: "", //征集详细信息
        },
      ],
      scoreLineListZy: [
        {
          majorGroupCode: 1, //专业组代码
          admissionDirection: "", //招生方向
          subjectRequirement: "", //选课要求
          details: [
            {
              subject: "", //选科
              batch: "本科", //批次
              majorName: "", //院校/专业名称
              majorEnrollCode: "", //专业登记代码
              schoolName: "", //学校名称
              note: "", //备注
              highestScore: 0, //最高分
              planNum: 0, //计划数
              admittedCount: 0, //录取数
              lowestScore: 0, //最低分
              lowestRank: 0, //最低位次
              averageScore: 0, //平均分
              majorGroup: "", //专业组
              schoolSystem: "", //学制
              fee: "", //学费
            },
          ],
        },
      ],
      scoreLineListZyClone: [],
      schoolConfig: [],
      majorConfig: [],
      courseList: [],
      courseMajorList: [],
      batchList: [],
    };
  },
  methods: {
    init() {
      Promise.all([this.querySubjectsYx(), this.querySubjectsZy()]).then(() => {
        this.queryScoreLineYx();
      });
    },
    yearChangeYx() {
      this.courseList = this.schoolConfig.find(
        (item) => item.year === +this.filterFormYx.year
      ).course;
      this.filterFormYx.subjectSelection = this.courseList[0];
      this.queryScoreLineYx();
    },
    yearChangeZy() {
      this.courseMajorList = this.majorConfig.find(
        (item) => item.year === +this.filterFormZy.year
      ).course;
      this.batchList = this.majorConfig.find(
        (item) => item.year === +this.filterFormZy.year
      ).batches;
      // this.subjectRule = res.message.remainingSubjectRule;
      this.filterFormZy.subjectSelection = this.courseMajorList[0];
      this.filterFormZy.batch = this.batchList[0];
      this.queryScoreLineZy();
    },
    async querySubjectsYx() {
      let data = {
        provinceName: this.filterFormYx.province,
        year: this.filterFormYx.year,
      };
      await province.queryProvinceRule(data).then((res) => {
        this.schoolConfig = res.data;
        this.courseList = res.data.find(
          (item) => item.year === +this.filterFormYx.year
        ).course;
        // this.batchList = res.data.find(
        //   (item) => item.year === +this.filterFormYx.year
        // ).batches;
        // this.subjectRule = res.message.remainingSubjectRule;
        this.filterFormYx.subjectSelection = this.courseList[0];
        this.queryScoreLineYx();
      });
    },
    async querySubjectsZy() {
      let data = {
        provinceName: this.filterFormZy.province,
        year: this.filterFormZy.year,
      };
      await province.queryProvinceRule(data).then((res) => {
        this.majorConfig = res.data;
        this.courseMajorList = res.data.find(
          (item) => item.year === +this.filterFormZy.year
        ).course;
        this.batchList = res.data.find(
          (item) => item.year === +this.filterFormZy.year
        ).batches;
        // this.subjectRule = res.message.remainingSubjectRule;
        this.filterFormZy.subjectSelection = this.courseMajorList[0];
        this.filterFormZy.batch = this.batchList[0];
        this.queryScoreLineZy();
      });
    },
    queryScoreLineYx() {
      let data = {
        schoolId: this.schoolId,
        province: this.filterFormYx.province,
        year: this.filterFormYx.year,
        subjectSelection: this.filterFormYx.subjectSelection,
      };
      school.queryScoreLineSchool(data).then((res) => {
        this.scoreLineListYx = res.message.map((item) => {
          const newItem = {};
          for (const key in item) {
            if (item[key] === null) {
              if (key === "subjectRequirement") {
                newItem[key] = "不限";
              } else {
                newItem[key] = "-";
              }
            } else {
              newItem[key] = item[key];
            }
          }
          return newItem;
        });
      });
    },
    queryScoreLineZy() {
      let data = {
        page: 1,
        pageSize: 1000,
        schoolId: this.schoolId,
        province: this.filterFormZy.province,
        year: this.filterFormZy.year,
        subject: this.filterFormZy.subjectSelection,
        subSubject: this.filterFormZy.subSubjects,
        batch: this.filterFormZy.batch,
        majorGroup: null,
      };
      school.queryScoreLineMajor(data).then((res) => {
        this.scoreLineListZy = res.message
          .map((item) => {
            item.details = item.details
              .map((child) => {
                const newItem = {};
                for (const key in child) {
                  if (child[key] === null) {
                    if (key === "subjectRequirement") {
                      newItem[key] = "不限";
                    } else {
                      newItem[key] = "-";
                    }
                  } else {
                    newItem[key] = child[key];
                  }
                }
                return newItem;
              })
              .filter((item) => !item.first);
            return item;
          })
          .sort((a, b) => a.majorGroupCode - b.majorGroupCode);
        this.scoreLineListZyClone = JSON.parse(
          JSON.stringify(this.scoreLineListZy)
        );
        this.subjectGroup = "";
      });
    },
  },
  computed: {
    columns() {
      return [
        { label: "代码", prop: "majorEnrollCode" },
        {
          label: "院校/专业名称",
          prop: "majorName",
          slot: "majorName",
          width: 250,
        },
        { label: "批次", prop: "batch" },
        { label: "学制", prop: "schoolSystem" },
        { label: "学费", prop: "fee" },
        { label: "选科", prop: "subjectRequirement" },
        { label: "计划数", prop: "planNum" },
        { label: "录取数", prop: "admittedCount" },
        { label: "最高分", prop: "highestScore" },
        { label: "最低分", slot: "lowestScore" },
        { label: "平均分", prop: "averageScore" },
        { label: "最低位次", prop: "lowestRank" },
      ];
    },
    schoolColumns() {
      return [
        { label: "年份", prop: "year" },
        {
          label: "院校(招生方向)",
          prop: "admissionDirection",
          width: 250,
        },
        { label: "批次/段", prop: "batch" },
        { label: "选科要求", prop: "subjectRequirement" },
        { label: "录取数", prop: "admittedCount" },
        { label: "最高分", prop: "highestScore" },
        { label: "最低分", prop: "lowestScore", slot: "lowestScore" },
        { label: "平均分", prop: "averageScore" },
        { label: "最低位次", prop: "lowestRank" },
      ];
    },
  },
  watch: {
    // filterFormYx: {
    //   handler() {
    //     this.queryScoreLineYx();
    //   },
    //   deep: true,
    // },
    // filterFormZy: {
    //   handler() {
    //     this.majorGroup = "";
    //     this.queryScoreLineZy();
    //   },
    //   deep: true,
    // },
    majorGroup: {
      handler(newVal) {
        if (newVal === "") {
          this.scoreLineListZy = JSON.parse(
            JSON.stringify(this.scoreLineListZyClone)
          );
        } else {
          this.scoreLineListZy = JSON.parse(
            JSON.stringify(
              this.scoreLineListZyClone.filter(
                (item) => item.majorGroupCode === newVal
              )
            )
          );
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/chadaxue/assets/luqu.scss";
@import "@/assets/scss/common.scss";
.zj {
  margin-left: 5px;
  background: #ffece8;
  display: inline-block;
  cursor: pointer;
  padding: 0 4px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  color: #f53f3f;
}
.major-name {
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  .note {
    color: #999;
    font-size: 12px;
    font-weight: normal;
  }
}
.text-wrapper_42,
.text-wrapper_43 {
  text-align: center;
}
</style>
