<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="group_2 flex-col">
        <div class="section_2 flex-row justify-between">
          <div class="group_5 flex-col">
            <div class="text-wrapper_2 flex-col">
              <span class="text_19">全部职业</span>
            </div>
            <div class="block_2 flex-col"></div>
            <el-tree
              ref="tree"
              :data="treeMenuData"
              node-key="code"
              highlight-current
              @node-click="handleNodeClick"
              :default-expanded-keys="defaultExpandedKeys"
              accordion
            ></el-tree>
          </div>
          <div class="group_9 flex-col">
            <div class="text-wrapper_3 flex-col">
              <span class="text_33">{{ active }}</span>
              <span class="text_34">{{ activeBelong }}</span>
            </div>
            <div class="group_10 flex-row">
              <template v-if="isSecond">
                <div class="tabs">
                  <span
                    v-for="item in major2List"
                    :class="{ active: tab === item }"
                    :key="item"
                    @click="tab = item"
                  >
                    {{ item }}
                  </span>
                </div>
                <main>
                  <component
                    :is="componentType[tab]"
                    :type-code="pCode"
                    v-bind="$attrs"
                    v-on="$listeners"
                  ></component>
                </main>
              </template>
              <template v-else>
                <div class="tabs">
                  <span
                    v-for="item in major3List"
                    :class="{ active: activeTab === item }"
                    :key="item"
                    @click="activeTab = item"
                  >
                    {{ item }}
                  </span>
                </div>
                <main>
                  <component
                    :is="componentType[activeTab]"
                    :code="pCode"
                    v-bind="$attrs"
                    v-on="$listeners"
                  ></component>
                </main>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import profession from "@/apis/profession";
import Overview from "./Overview.vue";
import Jiuye from "@/views/student/chazhiye/Jiuye.vue";
import Zhaopin from "@/views/student/chazhiye/Zhaopin.vue";
import School from "../../Major/MajorDetail/School.vue";
import Station from "@/views/student/chazhiye/Zhiyedetail.vue";
import Job from "@/views/student/chazhiye/Zhaopindetail.vue";
export default {
  data() {
    return {
      active: "",
      typeCode: null,
      professionCode: null,
      treeMenuData: [],
      tab: "职业概况",
      activeTab: "",
      activeBelong: "",
      type: "岗位情况",
      defaultExpandedKeys: [],
      currentNodeKey: "",
      pCode: "",
      isSecond: false,
    };
  },
  components: {
    Overview,
    Jiuye,
    Zhaopin,
    School,
    Station,
    Job,
  },
  computed: {
    componentType() {
      return {
        职业概况: "Overview",
        就业岗位: "Jiuye",
        企业招聘: "Zhaopin",
        人才培养高校: "School",
        岗位情况: "Station",
        招聘企业: "Job",
      };
    },
    // 二级职业
    major2List() {
      return ["职业概况", "就业岗位", "企业招聘", "人才培养高校"];
    },
    major3List() {
      return ["岗位情况", "招聘企业"];
    },
  },
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleNodeClick(obj) {
      // 二级职业
      if (obj.typeCode) {
        // this.$router.push("/chazhiyeoverview/" + obj.typeCode);
        this.pCode = obj.typeCode;
        this.getDetail();
      }
      // 三级职业
      if (obj.professionCode) {
        // this.$router.push("/chazhiyedetail/" + obj.professionCode);
        this.pCode = obj.professionCode;
        this.getDetail();
      }
    },

    async init() {
      this.pCode = this.code;
      await this.queryTreeData();
      // 默认展开的节点数组
      this.defaultExpandedKeys = [this.pCode];
      // 默认选中的节点
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.defaultExpandedKeys[0]);
      });
      this.getDetail();
    },

    getDetail() {
      if (this.pCode.length === 5) {
        this.isSecond = true;
        for (let i = 0; i < this.treeMenuData.length; i++) {
          for (
            let j = 0;
            j < this.treeMenuData[i].professionTypes.length;
            j++
          ) {
            if (
              this.treeMenuData[i].professionTypes[j].typeCode == this.pCode
            ) {
              this.active = this.treeMenuData[i].professionTypes[j].typeName;
              this.activeBelong =
                this.treeMenuData[i].categoryName +
                "->" +
                this.treeMenuData[i].professionTypes[j].typeName;
              this.typeCode = this.treeMenuData[i].professionTypes[j].typeCode;
              this.type = "type";
              this.activeTab = "职业概况";
              break;
            }
          }
        }
      } else {
        this.isSecond = false;
        for (let i = 0; i < this.treeMenuData.length; i++) {
          for (
            let j = 0;
            j < this.treeMenuData[i].professionTypes.length;
            j++
          ) {
            for (
              let k = 0;
              k < this.treeMenuData[i].professionTypes[j].professionVos.length;
              k++
            ) {
              if (
                this.treeMenuData[i].professionTypes[j].professionVos[k]
                  .professionCode == this.pCode
              ) {
                this.active =
                  this.treeMenuData[i].professionTypes[j].professionVos[
                    k
                  ].professionName;
                this.professionCode =
                  this.treeMenuData[i].professionTypes[j].professionVos[
                    k
                  ].professionCode;
                this.activeBelong =
                  this.treeMenuData[i].categoryName +
                  "->" +
                  this.treeMenuData[i].professionTypes[j].typeName +
                  "->" +
                  this.treeMenuData[i].professionTypes[j].professionVos[k]
                    .professionName;
                this.type = "profession";
                this.activeTab = "岗位情况";
                break;
              }
            }
          }
        }
      }
    },

    async queryTreeData() {
      await profession.queryProfessionsSearch({}).then((res) => {
        this.treeMenuData = res.message.map((item) => {
          return {
            ...item,
            label: item.categoryName,
            code: item.categoryCode,
            children: item.professionTypes.map((item2) => {
              return {
                ...item2,
                code: item2.typeCode,
                label: item2.typeName,
                children: item2.professionVos.map((item3) => {
                  return {
                    ...item3,
                    label: item3.professionName,
                    code: item3.professionCode,
                  };
                }),
              };
            }),
          };
        });
      });
    },
  },
  watch: {},
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/views/student/chazhiye/assets/layout.scss";
.menu {
  text-align: left;
}
::v-deep .el-submenu__icon-arrow {
  visibility: hidden;
}
.el-tree {
  margin-top: 23px;
  padding: 0 16px;
}
.group_10 {
  height: 100% !important;
  flex-direction: column;
  main {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
