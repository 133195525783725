import loginApi from "@/apis/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import router, { resetRouter } from "@/router";
import studentdetail from "@/apis/studentdetail";
const KEY = "autherization";

const state = {
  token: getToken(),
  userInfo: JSON.parse(localStorage.getItem(KEY)) || {},
  isLogin: !!getToken(),
  // 0 - 无,  1 -- 志愿卡, 2 -- 升学卡, 5 -- 1对1订单
  // auth: 0,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    state.isLogin = !!token;
  },
  SET_INFO: (state, userInfo) => {
    localStorage.setItem(KEY, JSON.stringify(userInfo));
    state.userInfo = userInfo;
  },
};

const actions = {
  // user login
  login({ commit }, form) {
    return new Promise((resolve, reject) => {
      loginApi
        .login(form)
        .then(({ message: data }) => {
          commit("SET_TOKEN", data.Authorization);
          setToken(data.Authorization);
          commit("SET_INFO", data);
          localStorage.setItem("loginTel", data.tel); // 保存登录手机号
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      studentdetail
        .queryStudentDetailByStudentId(state.userInfo.roleId)
        .then((response) => {
          const { data } = response;
          commit("SET_INFO", { ...state.userInfo, ...data });
          // if (!data) {
          //   reject("Verification failed, please Login again.");
          // }

          // const { roles, name, avatar, introduction } = data;

          // // roles must be a non-empty array
          // if (!roles || roles.length <= 0) {
          //   reject("getInfo: roles must be a non-null array!");
          // }

          // commit("SET_ROLES", roles);
          // commit("SET_NAME", name);
          // commit("SET_AVATAR", avatar);
          // commit("SET_INTRODUCTION", introduction);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("SET_TOKEN", "");
      commit("SET_INFO", {});
      removeToken();
      resetRouter();
      // localStorage.removeItem("rankScore");

      // reset visited views and cached views
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      // dispatch("tagsView/delAllViews", null, { root: true });

      resolve();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_INFO", {});
      removeToken();
      resolve();
    });
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + "-token";

    commit("SET_TOKEN", token);
    setToken(token);

    const { roles } = await dispatch("getInfo");

    resetRouter();

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch("permission/generateRoutes", roles, {
      root: true,
    });
    // dynamically add accessible routes
    router.addRoutes(accessRoutes);

    // reset visited views and cached views
    dispatch("tagsView/delAllViews", null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
