/*
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-11-06 09:33:46
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-12-27 10:58:14
 * @FilePath: \ok-college-entrance-examination-choices\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import studentRoutes from "./studentRoute";
Vue.use(VueRouter);

setAuth(studentRoutes);
const routes = [
  {
    path: "/",
    redirect: "/sso",
    // redirect: "/wellcome",
  },
  {
    path: "/sso",
    name: "sso",
    component: () => import("../views/login/SSO.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    redirect: "/departStudentList",
    children: [
      {
        path: "/departStudentList",
        name: "departStudentList",
        component: () => import("../views/depart/student/list/Index.vue"),
        mate: { title: "学员列表" },
      },
      {
        path: "/departStudentDetail",
        name: "departStudentDetail",
        component: () => import("../views/depart/student/detail/Index.vue"),
        mate: { title: "学员详情" },
      },
      {
        path: "/departStudentDetail/:id",
        name: "departStudentDetailById",
        component: () => import("../views/depart/student/detail/Index.vue"),
        mate: { title: "学员详情" },
      },
      {
        path: "/myStudentList",
        name: "myStudentList",
        component: () => import("../views/depart/mystudent/list/Index.vue"),
        mate: { title: "我的学员" },
      },
      {
        path: "/myStudentDetail",
        name: "myStudentDetail",
        component: () => import("../views/depart/mystudent/detail/Index.vue"),
        mate: { title: "学员详情" },
      },
      {
        path: "/myStudentDetail/:id",
        name: "myStudentDetailById",
        component: () => import("../views/depart/mystudent/detail/Index.vue"),
        mate: { title: "学员详情" },
      },
      {
        path: "/masterList",
        name: "masterList",
        component: () => import("../views/depart/master/list/Index.vue"),
        mate: { title: "规划师列表" },
      },
      {
        path: "/websiteOpeartion",
        name: "websiteOpeartion",
        component: () => import("../views/depart/website/Index.vue"),
        mate: { title: "网站操作" },
      },
      {
        path: "/coreData",
        name: "coreData",
        component: () => import("../views/depart/coredata/Index.vue"),
        mate: { title: "核心数据" },
      },
    ],
  },
  {
    path: "/design/:studentId/:preferenceScoreId/:preferenceId/:batch",
    name: "design",
    component: () => import("../views/common/design/Layout.vue"),
    mate: { title: "智能填报" },
  },
  {
    path: "/perferencesDetail/:studentId/:preferenceScoreId/:preferenceId",
    name: "perferencesDetail",
    component: () => import("../views/common/perferences/detail/Index.vue"),
    mate: { title: "志愿表详情" },
  },
  {
    path: "/schoolexpend",
    name: "schoolexpend",
    component: () => import("../views/common/zhiyuanDetail/SchoolExpend.vue"),
    mate: { title: "专业组" },
  },
  {
    path: "/majorexpend",
    name: "majorexpend",
    component: () => import("../views/common/zhiyuanDetail/MajorExpend.vue"),
    mate: { title: "专业+院校" },
  },
];
export const routerSystem = [
  {
    path: "/*",
    name: "404",
    component: () => import("../views/404.vue"),
    mate: { title: "找不到页面" },
  },
];
const generateRoutes = (process.env.VUE_APP_B_MODE === "true" ? routes : [])
  .concat(process.env.VUE_APP_C_MODE === "true" ? studentRoutes : [])
  .concat(routerSystem);
const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: generateRoutes,
    scrollBehavior(to, from, savedPosition) {
      // 如果有保存的滚动位置，则恢复到该位置
      if (savedPosition) {
        return savedPosition;
      } else {
        // 否则滚动到页面顶部
        return { x: 0, y: 0 };
      }
    },
  });
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

function setAuth(routes) {
  routes.forEach((route) => {
    if (!route.meta) {
      route.meta = {
        system: "C",
      };
    } else {
      route.meta.system = "C";
    }
    localStorage.setItem("system", "C");
    if (route.children) {
      setAuth(route.children);
    }
  });
}

export default router;
