<template>
  <div class="page flex-col">
    <div class="section_1 flex-row">
      <div class="block_1 flex-col">
        <div class="group_14 flex-row justify-between">
          <div class="image-text_9 flex-row justify-between">
            <div class="box_3 flex-col"></div>
            <span class="text-group_9">学科评估</span>
          </div>
          <div class="block_10 flex-row">
            <div class="image-text_10 flex-row justify-between">
              <el-select v-model="subjectAssessmentRound" style="width: 136px">
                <el-option
                  v-for="item in subjectAssessmentRounds"
                  :label="`第${item}轮`"
                  :value="item"
                  :key="item"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="content">
          <el-table
            :data="subjectAssessmentList"
            v-loading="loading"
            border
            style="width: 100%"
            header-row-class-name="header-row"
            cell-class-name="cell-class"
          >
            <el-table-column
              label="数量"
              align="center"
              class-name="level"
              prop="total"
              min-width="14%"
            >
            </el-table-column>
            <el-table-column label="专业评估" class-name="major">
              <template slot-scope="scope">
                <span
                  v-for="major in scope.row.detail"
                  style="margin: 0 20px 30px 0"
                  :key="major"
                >
                  {{ major }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="image-text_8 flex-row justify-between">
          <div class="section_6 flex-col"></div>
          <span class="text-group_8">特色专业</span>
        </div>
        <div class="content">
          <el-table
            :data="schoolSpecilMajorList"
            border
            style="width: 100%"
            header-row-class-name="header-row"
            :cell-class-name="
              ({ row, column, columnIndex }) => `special-cell-${columnIndex}`
            "
          >
            <el-table-column
              label="专业层次"
              prop="level"
              min-width="14%"
              align="center"
              class-name="level"
            >
            </el-table-column>
            <el-table-column
              prop="count"
              label="数量"
              min-width="14%"
              align="center"
              class-name="level"
            >
            </el-table-column>
            <el-table-column label="专业" align="left" class-name="major">
              <template slot-scope="scope">
                <span
                  v-for="(major, index) in scope.row.majors"
                  class="major-item"
                  :key="index"
                  @click="handleMajorClick(major)"
                >
                  {{ major.majorName }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="image-text_11 flex-row justify-between">
          <div class="box_4 flex-col"></div>
          <span class="text-group_11">院系设置</span>
        </div>
        <div class="content">
          <el-table
            :data="schoolDepartmentList"
            border
            style="width: 100%"
            header-row-class-name="header-row"
            cell-class-name="cell-class"
          >
            <el-table-column
              label="学员"
              class-name="level"
              prop="departmentName"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column label="专业" class-name="major">
              <template slot-scope="scope">
                <span
                  v-for="(major, index) in scope.row.majors"
                  class="major-item"
                  :key="index"
                  @click="handleMajorClick(major)"
                >
                  {{ major.majorName }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import school from "@/apis/school";
export default {
  data() {
    return {
      loading: false,
      schoolSpecilMajorList: [],
      subjectAssessmentRounds: [],
      subjectAssessmentRound: null,
      subjectAssessmentList: [],
      schoolDepartmentList: [],
    };
  },
  props: {
    schoolId: {
      type: String,
      default: "",
    },
  },
  watch: {
    subjectAssessmentRound: {
      handler(newVal) {
        if (newVal === "") {
          this.subjectAssessmentList = [];
        } else {
          // 这个页面会被填报页面做为抽屉使用 在填报页面会传入id并存入sessionStorage
          let id = this.schoolId;
          school.querySchoolSubjectAssessmentGrades(id).then(async (res) => {
            let subjectAssessmentGrades = res.message;
            this.loading = true;
            for (
              let index = 0;
              index < subjectAssessmentGrades.length;
              index++
            ) {
              const subjectAssessmentGrade = subjectAssessmentGrades[index];
              const { data } = await school.querySchoolSubjectAssessmentList({
                schoolId: id,
                round: newVal,
                grade: subjectAssessmentGrade,
              });
              if (data.totalSubjects) {
                this.subjectAssessmentList.push({
                  total: data.totalSubjects,
                  detail: data.subjectDetails.map(
                    (item) => item.subject + item.grade
                  ),
                });
              }
            }
            this.loading = false;
          });
        }
      },
    },
  },
  methods: {
    handleMajorClick(item) {
      if (!item.code) {
        this.$message.warning("该专业暂无介绍！");
        return;
      }
      const obj = {
        1: "本科",
        2: "专科",
        3: "职高",
      };
      this.$emit("getDetail", item.code, obj[item.level]);
      // const href = this.$router.resolve(`/chazhuanyedetail/${id}`);
      // window.open(href.href, "_blank");
    },
    querySchoolSpecilMajor() {
      // 这个页面会被填报页面做为抽屉使用 在填报页面会传入id并存入sessionStorage
      let id = this.schoolId;
      school.querySchoolSpecialMajors(id).then((res) => {
        this.schoolSpecilMajorList = res.message;
      });
      school.querySchoolSubjectAssessmentRounds(id).then((res) => {
        this.subjectAssessmentRounds = res.message;
        this.subjectAssessmentRound = res.message[res.message.length - 1];
        // this.subjectAssessmentRound =
        //   this.subjectAssessmentRounds.length > 0
        //     ? this.subjectAssessmentRounds.pop()
        //     : null;
      });
      school.querySchoolDepartments(id).then((res) => {
        this.schoolDepartmentList = res.message;
      });
    },
  },
  mounted() {
    this.querySchoolSpecilMajor();
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/student/chadaxue/assets/zhuanye.scss";
@import "@/assets/scss/common.scss";
.content {
  width: 1200px;
  margin: 30px auto;
}
::v-deep .header-row {
  height: 70px;
  th {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #717274;
    text-align: left;
    font-style: normal;
    background-color: #f5f5f5;
  }
}
::v-deep .el-table__body-wrapper .level {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #15171d;
  line-height: 28px;
  font-style: normal;
}
::v-deep .el-table__body-wrapper .major {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #32404b;
  line-height: 56px;
  text-align: left;
  font-style: normal;
  padding-top: 30px;
}
.major-item {
  margin: 0 20px 30px 0;
  cursor: pointer;
  &:hover {
    color: rgb(233, 48, 45);
  }
}
</style>
