/*
 * @Author: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @Date: 2024-11-25 16:26:38
 * @LastEditors: liyanxin_v 11210319+liyanxin-v@user.noreply.gitee.com
 * @LastEditTime: 2024-12-14 20:49:40
 * @FilePath: \ok-school-entrance-examination-choices\src\utils\options.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import province from "@/apis/province";
import moment from "moment";
export default {
  data() {
    return {
      options: {
        subjectTypes: ["文科（政史地）", "理科（物化生）"],
        provinces: this.$store.state.provinces,
        citys: [],
        districts: [],
        // gaokaoYears: [],
        // subjects: [],
        picis: ["本科", "专科"],
        ethnicitys: [
          "汉族",
          "蒙古族",
          "回族",
          "藏族",
          "维吾尔族",
          "苗族",
          "彝族",
          "壮族",
          "布依族",
          "朝鲜族",
          "满族",
          "侗族",
          "瑶族",
          "白族",
          "土家族",
          "哈尼族",
          "哈萨克族",
          "傣族",
          "黎族",
          "傈僳族",
          "佤族",
          "畲族",
          "高山族",
          "拉祜族",
          "水族",
          "东乡族",
          "纳西族",
          "景颇族",
          "柯尔克孜族",
          "土族",
          "达斡尔族",
          "仫佬族",
          "羌族",
          "布朗族",
          "撒拉族",
          "毛南族",
          "仡佬族",
          "锡伯族",
          "阿昌族",
          "普米族",
          "塔吉克族",
          "怒族",
          "乌孜别克族",
          "俄罗斯族",
          "鄂温克族",
          "德昂族",
          "保安族",
          "裕固族",
          "京族",
          "塔塔尔族",
          "独龙族",
          "鄂伦春族",
          "赫哲族",
          "门巴族",
          "珞巴族",
        ],
        filterTypes: ["手机号", "规划师姓名"],
        statuss: [
          // { id: 1, value: "待分配规划师" },
          { id: 1, value: "待分配" },
          { id: 2, value: "待处理" },
          { id: 3, value: "服务中" },
          { id: 4, value: "已完成" },
          { id: 6, value: "已暂停" },
          { id: 7, value: "已退费" },
        ],
        statussMy: [
          { id: 2, value: "待处理" },
          { id: 3, value: "服务中" },
          { id: 4, value: "已完成" },
          { id: 6, value: "已暂停" },
          { id: 7, value: "已退费" },
        ],
        masterTypes: [
          { id: 1, value: "主管" },
          { id: 2, value: "全职" },
          { id: 3, value: "兼职" },
          // { id: 4, value: "管理员" }
        ],
        examTypes: [
          { id: 1, value: "高考" },
          { id: 2, value: "模考" },
        ],
        masterOwners: ["规划师一", "规划师二", "规划师三"],
        masterTeams: ["团队一", "团队二", "团队三"],
        logics: ["=", ">=", ">", "<=", "<"],
        subjects: [
          { value: "语文", id: 1, scoreName: "chineseScore" },
          { value: "数学", id: 2, scoreName: "mathScore" },
          { value: "英语", id: 3, scoreName: "foreignLanguageScore" },
          { value: "物理", id: 4, scoreName: "physicsScore" },
          { value: "化学", id: 5, scoreName: "chemistryScore" },
          { value: "生物", id: 6, scoreName: "biologyScore", exclude: "上海" },
          // { value: "生命科学", id: 6, scoreName: "biologyScore"},
          { value: "历史", id: 7, scoreName: "historyScore" },
          { value: "地理", id: 8, scoreName: "geographyScore" },
          { value: "政治", id: 9, scoreName: "politicsScore" },
          { value: "技术", id: 10, scoreName: "technologyScore" },
        ],
        firstSubjects: [
          { value: "物理", id: 4 },
          { value: "历史", id: 7 },
        ],
        secendSubjects: [
          { value: "化学", id: 5 },
          { value: "生物", id: 6 },
          { value: "地理", id: 8 },
          { value: "政治", id: 9 },
        ],
        sexs: [
          {
            label: "男",
            value: 1,
          },
          {
            label: "女",
            value: 2,
          },
        ],

        chooseSubjects: [
          { label: "综合", value: "6选3" },
          { label: "综合", value: "7选3" },
          {
            label: [
              { value: "物理", label: "物理" },
              { value: "历史", label: "历史" },
            ],
            value: "4选2",
          },
          {
            label: [
              { value: "历史", label: "文科" },
              { value: "物理", label: "理科" },
            ],
            value: "2选1",
          },
        ],
        subjectsOldToNew: [
          { label: "物理", value: "理科" },
          { label: "历史", value: "文科" },
        ],
        provinceOldToNew: ["江苏", "广东", "山西"],
        batchOldToNew: [
          {
            province: "河南",
            value: { 本一: "本科", 本二: "本科", 专科: "专科" },
          },
          {
            province: "陕西",
            value: { 本一: "本科", 本二: "本科", 专科: "专科" },
          },
          {
            province: "四川",
            value: { 本一: "本科A", 本二: "本科B", 专科: "专科" },
          },
          {
            province: "云南",
            value: { 本一: "本科A", 本二: "本科B", 专科: "专科" },
          },
          {
            province: "宁夏",
            value: { 本一: "本科", 本二: "本科", 专科: "专科" },
          },
          {
            province: "青海",
            value: { 本一: "本科", 本二: "本科", 专科: "专科" },
          },
          {
            province: "山西",
            value: {
              本一A: "本科",
              本一A1: "本科",
              本一B: "本科",
              本二A: "本科",
              本二B: "本科",
              本二C: "本科",
              专科: "专科",
            },
          },
          {
            province: "内蒙古",
            value: {
              本一A: "本科",
              本一A1: "本科",
              本一B: "本科",
              本二A: "本科",
              本二B: "本科",
              本二C: "本科",
              专科: "专科",
            },
          },
        ],
        schoolLevels: [
          { label: "普通高中", value: "普通高中" },
          { label: "普通中专", value: "普通中专" },
          { label: "普通大专", value: "普通大专" },
          { label: "普通本科", value: "普通本科" },
          { label: "普通硕士", value: "普通硕士" },
        ],
        orderStatuss: [
          {
            label: "待分配",
            value: 1,
          },
          {
            label: "待处理",
            value: 2,
          },
          {
            label: "服务中",
            value: 3,
          },
          {
            label: "已完成",
            value: 4,
          },
        ],
        schoolNames: [],
        rankingTypes: ["软科排名", "QS"],
        // rankingYears: [],
        subjectSelections: {
          "6选3": ["综合"],
          "7选3": ["综合"],
          老高考: ["理科", "文科"],
          新高考: ["物理类", "历史类"],
          "4选2": ["物理", "历史"],
          "2选1": ["物理", "历史"],
        },
        // scoreYears: [],
        majorLevels: { 本科: 1, 专科: 2, 职高: 3 },
        preferenceRisks: {
          1: { risk: "较高风险", icon: "冲" },
          2: { risk: "中等风险", icon: "稳" },
          3: { risk: "较低风险", icon: "保" },
        },
        roleTypes: [
          { id: 1, value: "学生" },
          { id: 2, value: "规划师" },
          { id: 3, value: "管理员" },
        ],
        schoolTypes: this.$store.state.schoolTypes,
        schoolFeatures: this.$store.state.schoolFeatures,
        schoolInstitutions: this.$store.state.schoolInstitutions,
        gaokaoYears: this.$store.state.gaokaoYears,
        gaokaoYearGrades: this.$store.state.gaokaoYearGrades,
        rankingYears: this.$store.state.rankingYears,
        scoreYears: this.$store.state.scoreYears,
      },
    };
  },
  methods: {
    getCityList(provinceChoosed) {
      province
        .queryCitiesByProvince({ province: provinceChoosed })
        .then((res) => {
          this.options.citys = res.message;
        });
    },
    getDistrictList(provinceChoosed, cityChoosed) {
      province
        .queryDistrictsByProvinceAndCity({
          province: provinceChoosed,
          city: cityChoosed,
        })
        .then((res) => {
          this.options.districts = res.message;
        });
    },
    getSchoolList(provinceChoosed, cityChoosed, districtChoosed) {
      province
        .querySchoolByProvinceAndCityAndDistricts({
          province: provinceChoosed,
          city: cityChoosed,
          district: districtChoosed,
        })
        .then((res) => {
          this.options.schoolNames = res.message;
        });
    },
    transformSubject(subjects, province) {
      if (Object.prototype.toString.call(subjects) === "[object Array]") {
        return subjects
          .map((i) => this.options.subjects.find((o) => o.id == i).value)
          .join(",");
      } else {
        return this.options.subjects.find((o) => o.id == subjects)?.value;
      }
    },
    getGrade(year) {
      if (year) {
        //根据高考年份与当前时间判断现在是高1高2还是高3
        return moment(year + "-06-30").diff(moment(), "months") > 36
          ? ""
          : moment(year + "-06-30").diff(moment(), "months") > 24
          ? "高一"
          : moment(year + "-06-30").diff(moment(), "months") > 12
          ? "高二"
          : "高三";
      } else {
        return "";
      }
    },
  },
};
